export enum ReimbursementRequestType {
  DIRECT_ORDER = 'DIRECT_ORDER',
  REIMBURSEMENT = 'REIMBURSEMENT',
  DIRECT_DEDUCTION = 'DIRECT_DEDUCTION',
}

export enum ReimbursementType {
  REIMBURSEMENT = 0,
  DIRECT_ORDER,
  DIRECT_DEDUCTION,
}

export enum ReimbursementTypeLabel {
  REIMBURSEMENT = 'Reimbursement',
  DIRECT_ORDER = 'Direct Order',
  DIRECT_DEDUCTION = 'Direct Deduction',
}

export enum ReimbursementRequestTypeAbr {
  DIRECT_ORDER = 'DO',
  REIMBURSEMENT = 'RB',
  DIRECT_DEDUCTION = 'DD',
}

export enum ReimbursementCustomBuiltType {
  SINGLE_PERIOD = 'SINGLE',
  MERGED_PERIOD = 'MERGED',
}

export enum ReceiptApplicable {
  APPLICABLE = 1,
  NOT_APPLICABLE = 0,
}
