// Delete on 5209 FF removal
export const PROVIDERS = ['Alex Math', 'BYU', 'IXL', 'MTH ']
// Rename on 5209 FF removal
export const PROVIDERS_REBRANDING = ['Alex Math', 'BYU', 'IXL', 'OpenEd']

export const SOE = ['School of Enrollment', 'SPED', 'Provider']

export const SOE_OPTIONS = ['Nebo', 'GPA', 'Tooele', 'ALC']

export const SPED = ['No', 'IEP', '504', 'Exit']

export const OPTIONS_TO_REMOVE = ['embedded', 'image']

export const ADMIN_OPTIONS = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'list',
  'textAlign',
  'colorPicker',
  'link',
  'embedded',
  'image',
  'remove',
  'history',
]
