import { gql } from '@apollo/client'

export const getAnnouncementPopupsQuery = gql`
  query getAnnouncementPopupsQuery($options: GetAnnouncementPopupsInput!) {
    getAnnouncementPopups(options: $options) {
      results {
        body
        displayTo {
          admin
          parentsObservers
          students
          teachersAssistants
        }
        endDate
        id
        postedBy
        startDate
        status
        title
      }
      total
    }
  }
`

export const getCurrentAnnouncementPopupByRegion = gql`
  query GetCurrentAnnouncementPopupByRegion($regionId: Float!, $rol: Float) {
    getCurrentAnnouncementPopupByRegion(regionId: $regionId, rol: $rol) {
      body
      endDate
      id
      postedBy
      startDate
      status
      title
      displayTo {
        admin
        parentsObservers
        students
        teachersAssistants
      }
    }
  }
`
