export enum FileKind {
  STUDENT_PACKET = 'Student Packet',
  WITHDRAWAL_FORM = 'Withdrawal Form',
  OPT_OUT_FORM_ELEMENTARY = 'Opt-out Form Elementary',
  OPT_OUT_FORM_SECONDARY = 'Opt-out Form Secondary',
  OPT_OUT_FORM_OREGON = 'Opt-out Form Oregon',
  USIRS = 'USIR',
  SCHEDULE = 'Schedule',
  WEEKLY_LEARNING_LOG = 'Weekly Learning Log',
  AFFIDAVIT = 'Affidavit',
}
