import { DropDownItem } from '@mth/core/components/shared/DropDown/types'
import { CourseType, MthTitle } from '@mth/enums'

export const COURSE_TYPE_ITEMS: DropDownItem[] = [
  { label: MthTitle.CUSTOM_BUILT, value: CourseType.CUSTOM_BUILT },
  { label: MthTitle.MY_TECH_HIGH_DIRECT, value: CourseType.MTH_DIRECT },
  { label: MthTitle.THIRD_PARTY_PROVIDER, value: CourseType.THIRD_PARTY_PROVIDER },
]

export const COURSE_TYPE_LABEL_MAP = {
  [CourseType.CUSTOM_BUILT]: MthTitle.CUSTOM_BUILT,
  [CourseType.MTH_DIRECT]: MthTitle.MY_TECH_HIGH_DIRECT,
  [CourseType.THIRD_PARTY_PROVIDER]: MthTitle.THIRD_PARTY_PROVIDER,
}
