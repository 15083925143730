import React from 'react'

export interface PhoneInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string
  inputRef?: React.Ref<HTMLInputElement>
}

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>((props, ref) => {
  const { value, ...otherProps } = props
  let formattedValue = value ? value : ''

  const rawValue = formattedValue.replace(/\D+/g, '').slice(0, 10)

  if (rawValue.length > 6) {
    formattedValue = rawValue.replace(/(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3')
  } else if (rawValue.length > 3) {
    formattedValue = rawValue.replace(/(\d{3})(\d{1,3})/, '$1-$2')
  } else {
    formattedValue = rawValue
  }

  return <input ref={ref} value={formattedValue} {...otherProps} />
})
