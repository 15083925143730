import React, { useState } from 'react'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { WarningModal } from '@mth/components/WarningModal/Warning'
import {
  SoETransferOption,
  SoETransferOptionText,
  TEXT_SIZE,
  WarningMessagesButtons,
  WarningMessagesSubtitles,
  WarningMessagesTitles,
} from '@mth/enums'
import { testIDs } from './__tests__/StudentSoeTransferModal.testids'
import { Paragraph } from '../../shared/Paragraph'

type StudentSoeTransferModalProps = {
  handleClose: () => void
  handleSubmit: (option: SoETransferOption) => void
  unassign?: boolean
}

export const StudentSoeTransferModal: React.FC<StudentSoeTransferModalProps> = ({
  unassign = false,
  handleSubmit,
  handleClose,
}) => {
  const [transferOption, setTransferOption] = useState<SoETransferOption | undefined>(
    unassign ? SoETransferOption.UNASSSIGN : undefined,
  )
  const [error, setError] = useState<boolean>(false)

  const internalHandleSubmit = () => {
    if (transferOption) handleSubmit(transferOption)
    else setError(true)
  }

  const handleOptionSelected = (option: number) => {
    setError(false)
    setTransferOption(option as SoETransferOption)
  }

  return (
    <WarningModal
      title={WarningMessagesTitles.SCHOOL_OF_ENROLLMENT}
      subtitle={WarningMessagesSubtitles.SOE_TRANSFER}
      btntitle={WarningMessagesButtons.ASSIGN}
      canceltitle={WarningMessagesButtons.CANCEL}
      handleModem={handleClose}
      handleSubmit={internalHandleSubmit}
      textCenter
      modalWidth='500px'
      upperCase={false}
      error={!transferOption && error}
    >
      {unassign ? (
        <Typography
          sx={{
            marginBottom: '10px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {SoETransferOptionText.UNASSSIGN}
        </Typography>
      ) : (
        <Box sx={{ marginLeft: '40px', marginTop: '30px', overflow: 'auto', textAlign: 'start' }}>
          <FormControl>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={transferOption}
              onChange={(e) => handleOptionSelected(Number(e.target.value))}
            >
              <FormControlLabel
                value={SoETransferOption.ASSIGN}
                data-testid={testIDs.optionAssign}
                control={<Radio sx={{ marginRight: '20px' }} />}
                label={
                  <Paragraph size={TEXT_SIZE.LARGE} fontWeight='600'>
                    {SoETransferOptionText.ASSIGN}
                  </Paragraph>
                }
              />
              <FormControlLabel
                value={SoETransferOption.ASSIGN_AND_FORM}
                data-testid={testIDs.optionAssignAndForm}
                control={<Radio sx={{ marginRight: '20px' }} />}
                label={
                  <Paragraph size={TEXT_SIZE.LARGE} fontWeight='600'>
                    {SoETransferOptionText.ASSIGN_AND_FORM}
                  </Paragraph>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )}
    </WarningModal>
  )
}
