import { createContext } from 'react'

type JSONData = string | number | boolean | null | JSONData[] | { [key: string]: JSONData }

export type ProfileContextType = {
  showModal: (data: JSONData, refetch?: () => void) => void
  hideModal: React.Dispatch<React.SetStateAction<boolean>>
  store: boolean
  setStore: React.Dispatch<React.SetStateAction<boolean>>
}

const profileContext: ProfileContextType = {
  showModal: () => {},
  hideModal: () => {},
  store: false,
  setStore: () => {},
}

export const ProfileContext = createContext<ProfileContextType>(profileContext)
