import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Avatar, Button, Grid, TextField, Select, MenuItem, Typography, SelectChangeEvent } from '@mui/material'
import { Box } from '@mui/system'
import { useFlag } from '@unleash/proxy-client-react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { isEmpty, isNil } from 'lodash'
import { WarningModal } from '@mth/components/WarningModal/Warning'
import {
  EMPTY_STRING,
  EPIC_3537_BUG_4794,
  GRADES,
  STATES_WITH_ABBREVIATION,
  USER_ID,
  US_STATES,
  EPIC_275_STORY_5395,
  EPIC_3346_BUG_5434,
  EPIC_2209_STORY_5188,
  EPIC_3862_STORY_5640,
} from '@mth/constants'
import { StudentSoeTransferModal } from '@mth/core/components/SchoolOfEnrollment'
import { DropDown } from '@mth/core/components/shared/DropDown/DropDown'
import { DropDownItem } from '@mth/core/components/shared/DropDown/types'
import { Paragraph } from '@mth/core/components/shared/Paragraph/Paragraph'
import { Subtitle } from '@mth/core/components/shared/Subtitle/Subtitle'
import { Title } from '@mth/core/components/shared/Title/Title'
import {
  MthColor,
  MthRoute,
  MthTitle,
  PacketStatus,
  ScheduleStatus,
  Separator,
  SoETransferOption,
  StudentStatus,
  TEXT_SIZE,
  datePatterns,
  soeLowStatus,
  soeUppStatus,
} from '@mth/enums'
import { updateStudentAssessmentMutation } from '@mth/graphql/mutation/assessment'
import { getAssessmentsBySchoolYearId, getStudentAssessmentsByStudentId } from '@mth/graphql/queries/assessment'
import { getWithdrawalByStudentId } from '@mth/graphql/queries/withdrawal'
import { useSoeOperations } from '@mth/hooks'
import { Packet, Person, Schedule, School, SchoolDistrict } from '@mth/models'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { ReimbursementModal } from '@mth/screens/Admin/Reimbursements/ReimbursementModal'
import { AssessmentType } from '@mth/screens/Admin/SiteManagement/EnrollmentSetting/TestingPreference/types'
import {
  expandAbbreviationSingleGrade,
  formatDateByPattern,
  getStringInitialLetter,
  getTimezone,
  isInEligibleStudentStatus,
  utcToTimezoned,
} from '@mth/utils'
import { testIDs } from './__tests__/StudentProfile.testIds'
import { StudentFilters } from './components/StudentFilters'
import { ScheduleInfo, StudentAssessment, StudentProfileProps } from './types'
import { ProfilePacketModal } from '../../EnrollmentPackets/EnrollmentPacketModal/ProfilePacketModal'
import { GetSchoolsPartner } from '../../SchoolOfEnrollment/services'
import { HomeroomResourceModal } from '../components/NewUserModal/HomeroomResourceModal'
import { FamilyNotesAndStudentNotes } from '../components/Share/FamilyNotesAndStudentNotes'
import {
  getSchoolYearsByRegionId,
  getCountiesByRegionId,
  getSchoolDistrictsByRegionId,
  getScheduleByStudentId,
} from '../services'
import { useStyles } from '../styles'
import { WithdrawalByStudent } from '../types'

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('MST')

export const StudentProfile: React.FC<StudentProfileProps> = ({
  setLoading,
  studentId,
  setStudentPerson,
  setStudentStatus,
  studentStatus,
  applicationState,
  setIsChanged,
  phoneInfo,
  addressInfo,
  setPhoneInfo,
  notes,
  setNotes,
  studentNotes,
  setStudentNotes,
  student,
  studentSchoolYears,
  refetchStudent,
  currentSchoolYear,
  nextSchoolYear,
  familyStudentIds,
  setActualSchedule,
  actualSchedule,
  isEditedByExternal,
}) => {
  const epic3537bug4794 = useFlag(EPIC_3537_BUG_4794)
  const epic275story5395 = useFlag(EPIC_275_STORY_5395)
  const epic3346bug5434 = useFlag(EPIC_3346_BUG_5434)
  const epic2209Story5188 = useFlag(EPIC_2209_STORY_5188)
  const epic3862story5640 = useFlag(EPIC_3862_STORY_5640)
  const { me } = useContext(UserContext)
  const timeZone = getTimezone(me!)
  const DISABLE_STUDENT_ADDRESS = true
  const classes = useStyles
  const [originStudentStatus, setOriginStudentStatus] = useState<StudentStatus>()
  const [studentAssessments, setStudentAssessments] = useState<StudentAssessment[]>([])
  const [assessmentItems, setAssessmentItems] = useState<AssessmentType[]>([])
  const [specialEdOptions, setSpecialEdOptions] = useState<string[]>([])
  const [showReimbursementModal, setShowReimbursementModal] = useState<boolean>(false)
  const { transferStudentsSOE, assignStudentsSOE } = useSoeOperations()

  const selectedYearId = useMemo(() => {
    return currentSchoolYear?.school_year_id || nextSchoolYear?.school_year_id
  }, [currentSchoolYear, nextSchoolYear])

  const selectedSchoolYear = useMemo(() => {
    return currentSchoolYear || nextSchoolYear
  }, [currentSchoolYear, nextSchoolYear])

  const activeSchoolYear =
    studentSchoolYears.find((item) => item.school_year_id === Number(currentSchoolYear?.school_year_id)) ||
    studentSchoolYears.find((item) => item.school_year_id === Number(nextSchoolYear?.school_year_id))

  const schoolYearToShow = activeSchoolYear ? activeSchoolYear : currentSchoolYear

  const { data: regionData } = useQuery(getSchoolYearsByRegionId, {
    variables: {
      regionId: me?.selectedRegionId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const { data: scheduleByStudent } = useQuery(getScheduleByStudentId, {
    variables: {
      studentId: Number(studentId),
    },
    skip: !studentId,
    fetchPolicy: 'cache-and-network',
  })

  const { data: schoolPartnerData } = useQuery(GetSchoolsPartner, {
    variables: {
      schoolPartnerArgs: {
        region_id: me?.selectedRegionId,
        school_year_id: selectedYearId,
        sort: {
          column: 'name',
          direction: 'ASC',
        },
      },
    },
    skip: !selectedYearId,
    fetchPolicy: 'network-only',
  })

  const { data: assessments, loading: assessmentsLoading } = useQuery(getAssessmentsBySchoolYearId, {
    variables: {
      schoolYearId: selectedYearId,
    },
    skip: !selectedYearId,
    fetchPolicy: 'network-only',
  })

  const { loading: studentAssessmentLoading, data: studentAssessmentsData } = useQuery(
    getStudentAssessmentsByStudentId,
    {
      variables: {
        studentId: Number(studentId),
      },
      skip: Number(studentId) ? false : true,
      fetchPolicy: 'network-only',
    },
  )

  //  Load withdrawal status from database
  const { data: withdrawalStatusData } = useQuery(getWithdrawalByStudentId, {
    variables: {
      studentId: Number(studentId),
    },
    fetchPolicy: 'network-only',
    skip: isNil(studentId),
  })

  const SoEitems = useMemo(() => {
    if (schoolPartnerData?.getSchoolsOfEnrollmentByRegion?.length) {
      const partnerList = schoolPartnerData.getSchoolsOfEnrollmentByRegion
        .filter((el: School) => el.schoolEnrollmentYear.some((year) => year.active))
        .map((item: School) => ({
          value: String(item.id || soeLowStatus.UNASSIGNED),
          label: String(item.name),
          abb: String(item.abbreviation),
        }))

      partnerList.push({
        value: soeLowStatus.UNASSIGNED,
        label: soeUppStatus.UNASSIGNED,
        abb: soeUppStatus.UNASSIGNED,
      })

      return partnerList
    } else {
      return []
    }
  }, [schoolPartnerData])

  const [withdrawalStatus, setWithdrawalStatus] = useState<WithdrawalByStudent | undefined>()
  const [userInfo, setUserInfo] = useState<Person>()
  const [preferedFirstName, setPreferredFirstName] = useState(EMPTY_STRING)
  const [preferedLastName, setPreferredLastName] = useState(EMPTY_STRING)
  const [legalFirstName, setLegalFirstName] = useState(EMPTY_STRING)
  const [legalMiddleName, setLegalMiddleName] = useState(EMPTY_STRING)
  const [legalLastName, setLegalLastName] = useState(EMPTY_STRING)
  const [email, setEmail] = useState(EMPTY_STRING)
  const [parentEmail, setParentEmail] = useState<string>(EMPTY_STRING)
  const [gender, setGender] = useState(EMPTY_STRING)
  const [city, setCity] = useState(EMPTY_STRING)
  const [zip, setZip] = useState(EMPTY_STRING)
  const [street1, setStreet1] = useState(EMPTY_STRING)
  const [street2, setStreet2] = useState(EMPTY_STRING)
  const [state, setState] = useState<string>(
    userInfo?.address?.state ? EMPTY_STRING : STATES_WITH_ABBREVIATION[applicationState],
  )
  const [gradeLevel, setGradeLevel] = useState(EMPTY_STRING)
  const [packets, setPackets] = useState<Packet[]>([])
  const [openNotes, setOpenNotes] = useState(false)
  const [showPacketModal, setShowPacketModal] = useState(false)
  const [packetID, setPacketID] = useState(0)
  const [tempSoE, setTempSoE] = useState(soeLowStatus.UNASSIGNED.toString())
  const [modalAssign, setModalAssign] = useState(false)
  const [schoolPartnerId, setSchoolPartnerId] = useState(soeLowStatus.UNASSIGNED.toString())
  const [ableToEdit, setAbleToEdit] = useState<boolean>(false)
  const [clickedEvent, setClickedEvent] = useState<React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>>()
  const [counties, setCounties] = useState<Array<DropDownItem>>([])
  const [schoolDistricts, setSchoolDistricts] = useState<Array<DropDownItem>>([])
  const [county, setCounty] = useState<number>(0)
  const [school_district, setSchoolDistrict] = useState<string>(EMPTY_STRING)
  const [firstSemesterSchedule, setFirstSemesterSchedule] = useState<ScheduleInfo>()

  const [submitTestingPref] = useMutation(updateStudentAssessmentMutation)
  const [showHomeroomResourceModal, setShowHomeroomResourceModal] = useState(false)
  const handlePacket = () => {
    if (packets.length <= 0) return

    setPacketID(packets[packets.length - 1].packet_id)
    setShowPacketModal(true)
  }

  const genderItems: DropDownItem[] = [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
  ]

  useEffect(() => {
    const soe = student?.currentSoe?.find((e) => String(e.school_year_id) === String(selectedYearId))
    setSchoolPartnerId(String(soe?.school_partner_id || soeLowStatus.UNASSIGNED))
  }, [selectedYearId, student?.currentSoe])

  useEffect(() => {
    const schoolYears = regionData?.region?.SchoolYears || []
    let special_ed_options = EMPTY_STRING
    schoolYears
      .filter(
        (item: { date_begin: string; date_end: string }) =>
          new Date(item.date_begin) <= new Date() && new Date(item.date_end) >= new Date(),
      )
      .map((item: { special_ed_options: string; special_ed: boolean }): void => {
        if (
          item.special_ed === true &&
          item.special_ed_options !== EMPTY_STRING &&
          item.special_ed_options !== null &&
          special_ed_options === EMPTY_STRING
        )
          special_ed_options = item.special_ed_options
      })
    if (special_ed_options === EMPTY_STRING) setSpecialEdOptions([])
    else setSpecialEdOptions(special_ed_options.split(Separator.COMMA))
  }, [regionData, selectedYearId])

  const setSOE = useCallback(
    async (newSchoolPartnerId: string, isTransfer = false, generateTransferForm = false) => {
      setLoading(true)
      try {
        if (!isNil(newSchoolPartnerId) && newSchoolPartnerId !== schoolPartnerId) {
          const studentData = {
            studentId: studentStatus.student_id,
            name: `${student?.person.last_name}, ${student?.person.first_name}`,
          }
          if (isTransfer) {
            await transferStudentsSOE(
              newSchoolPartnerId,
              studentStatus.school_year_id,
              [String(studentStatus.student_id)],
              [studentData],
              generateTransferForm,
            )
          } else {
            await assignStudentsSOE(
              newSchoolPartnerId,
              studentStatus.school_year_id,
              [String(studentStatus.student_id)],
              [studentData],
            )
          }
        }
      } catch (error) {
        console.error('Error assigning an SOE to student')
        console.error(error)
      }
      setSchoolPartnerId(newSchoolPartnerId)
      setLoading(false)
    },
    [schoolPartnerId, studentStatus, student, assignStudentsSOE, transferStudentsSOE],
  )

  const handleChangeSoE = useCallback(
    (e: SelectChangeEvent) => {
      if (schoolPartnerId === soeLowStatus.UNASSIGNED || e.target.value === schoolPartnerId) {
        setSOE(e.target.value)
      } else {
        setTempSoE(e.target.value)
        setModalAssign(true)
      }
    },
    [schoolPartnerId, setSOE],
  )

  const handleAssignOrTransfer = async (option: SoETransferOption) => {
    const generateTransferForm = option === SoETransferOption.ASSIGN_AND_FORM
    const isTransfer = option !== SoETransferOption.UNASSSIGN
    setSOE(tempSoE, isTransfer, generateTransferForm)
    setModalAssign(false)
  }

  const studentAseessOption = (assessment_id: number) => {
    const studentAsses = studentAssessments.find((ass) => ass.assessmentId === assessment_id)
    if (!studentAsses) {
      return EMPTY_STRING
    }
    return studentAsses.optionId
  }

  const handleTestPreferenceChange = async (assessId: number, optionId: number) => {
    let updatedValue: StudentAssessment[] = [...studentAssessments]
    if (studentAssessments.find((assement) => assement.assessmentId === assessId)) {
      updatedValue = studentAssessments.map((item) => {
        if (item.assessmentId === assessId) {
          return {
            ...item,
            optionId: optionId,
          }
        } else {
          return item
        }
      })
    } else {
      if (epic3346bug5434) {
        studentAssessments.push({
          assessmentId: assessId,
          optionId: optionId,
          assessmentOptionOutText: '',
        } as StudentAssessment)
        updatedValue = [...studentAssessments]
      } else {
        // if student never answered the testing preference
        const response = await submitTestingPref({
          variables: {
            studentAssessmentInput: {
              AssessmentId: Number(assessId),
              OptionId: Number(optionId),
              StudentId: Number(studentId),
              out_text: '',
            },
          },
        })
        if (response && response.data.createOrUpdateStudentAssessment) {
          const studentAssessment = response.data.createOrUpdateStudentAssessment
          const newAssesment = {
            optionId: optionId,
            assessmentId: assessId,
            assessmentOptionOutText: EMPTY_STRING,
            assessmentOptionId: studentAssessment?.assessment_option_id,
          }
          updatedValue.push(newAssesment)
        }
      }
    }
    setIsChanged(true)
    setStudentAssessments(updatedValue)
    const testString = updatedValue.map((i) => {
      return {
        assessmentId: i.assessmentId,
        assessmentOptionId: i.assessmentOptionId,
        optionId: i.optionId,
      }
    })
    setStudentStatus({ ...studentStatus, ...{ testing_preference: JSON.stringify(testString) } })
  }

  const isVisibleTestPreference = (grades: string) => {
    if (!gradeLevel) {
      return false
    }
    const availableGrades = grades.split(Separator.COMMA)
    return availableGrades.indexOf(gradeLevel) !== -1
  }

  const visibleAssessmentItems = (list: AssessmentType[]) => {
    const result: AssessmentType[] = []
    list.map((item: AssessmentType) => {
      if (isVisibleTestPreference(item?.grades)) {
        result.push(item)
      }
    })
    return result
  }

  const { loading: countyLoading, data: countyData } = useQuery(getCountiesByRegionId, {
    variables: { regionId: Number(me?.selectedRegionId) },
    fetchPolicy: 'network-only',
  })

  const { loading: schoolDistrictsDataLoading, data: schoolDistrictsData } = useQuery(getSchoolDistrictsByRegionId, {
    variables: {
      regionId: Number(me?.selectedRegionId),
    },
    skip: Number(me?.selectedRegionId) ? false : true,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!studentAssessmentLoading && studentAssessmentsData?.getStudentAssessmentsByStudentId) {
      setStudentAssessments(
        studentAssessmentsData?.getStudentAssessmentsByStudentId?.map(
          (assessment: { AssessmentId: number; assessment_option_id: number; OptionId: number; out_text: string }) => ({
            assessmentId: assessment?.AssessmentId,
            assessmentOptionId: assessment?.assessment_option_id,
            optionId: assessment?.OptionId,
            assessmentOptionOutText: assessment?.out_text,
          }),
        ),
      )
    }
  }, [studentAssessmentLoading, studentAssessmentsData])

  useEffect(() => {
    if (withdrawalStatusData?.getWithdrawalByStudentId) {
      setWithdrawalStatus(withdrawalStatusData.getWithdrawalByStudentId)
    } else {
      setWithdrawalStatus(undefined)
    }
  }, [withdrawalStatusData])

  useEffect(() => {
    if (!assessmentsLoading && assessments?.getAssessmentsBySchoolYearId) {
      const items = assessments?.getAssessmentsBySchoolYearId
      setAssessmentItems(items.map((item: AssessmentType) => ({ ...item, assessment_id: Number(item.assessment_id) })))
    } else {
      setAssessmentItems([])
    }
  }, [assessments, assessmentsLoading])

  useEffect(() => {
    if (userInfo) setStudentPerson(userInfo)
  }, [userInfo])

  useEffect(() => {
    if (student) {
      const stateSelected = student.person.address.state || applicationState
      const activeStatus =
        student?.status.find(
          (studentStatusItem) => studentStatusItem.school_year_id === Number(currentSchoolYear?.school_year_id),
        ) ||
        student?.status.find(
          (studentStatusItem) => studentStatusItem.school_year_id === Number(nextSchoolYear?.school_year_id),
        )
      const activeGradeLevel = student.grade_levels.find(
        (gradeLevel) => Number(gradeLevel.school_year_id) === Number(activeStatus?.school_year_id),
      )

      setEmail(student.person.email ?? EMPTY_STRING)
      setParentEmail(student?.parent?.person?.email)
      setPreferredFirstName(student.person.preferred_first_name)
      setPreferredLastName(student.person.preferred_last_name)
      setLegalFirstName(student.person.first_name)
      setLegalLastName(student.person.last_name)
      setLegalMiddleName(student.person.middle_name ?? EMPTY_STRING)
      setGender(student.person.gender)
      setState(STATES_WITH_ABBREVIATION[stateSelected] || stateSelected)

      if (epic3537bug4794) {
        setCity(addressInfo?.city)
        setStreet1(addressInfo?.street)
        setStreet2(addressInfo?.street2)
        setZip(addressInfo?.zip)
        setCounty(addressInfo?.county_id || 0)
        setSchoolDistrict(addressInfo?.school_district || EMPTY_STRING)
      } else {
        setCity(student.person.address.city)
        setStreet1(student.person.address.street)
        setStreet2(student.person.address.street2)
        setZip(student.person.address.zip)
        setCounty(student.person.address.county_id || 0)
        setSchoolDistrict(student.person.address.school_district || EMPTY_STRING)
      }
      setUserInfo(student.person)
      setPackets(student.packets ?? [])
      if (student.grade_levels && activeGradeLevel) {
        setGradeLevel(activeGradeLevel.grade_level)
      }
      setStudentStatus({
        student_id: Number(studentId ?? 0),
        special_ed: selectedSchoolYear?.special_ed ? student.special_ed : undefined,
        diploma_seeking: selectedSchoolYear?.diploma_seeking ? student.diploma_seeking : undefined,
        testing_preference: student.testing_preference,
        status: activeStatus?.status ?? StudentStatus.INACTIVE,
        date: activeStatus?.date_updated || EMPTY_STRING,
        school_year_id: activeStatus?.school_year_id ?? 0,
        birth: student?.person.date_of_birth,
      })
      setOriginStudentStatus(activeStatus?.status)
    }
  }, [student, currentSchoolYear, nextSchoolYear, addressInfo])

  useEffect(() => {
    if (!countyLoading && countyData?.getCounties) {
      setCounties(
        countyData.getCounties.map((v: { county_name: string; id: number }) => {
          return { label: v.county_name, value: Number(v.id) }
        }),
      )
    }
  }, [countyData])

  useEffect(() => {
    if (!schoolDistrictsDataLoading && schoolDistrictsData?.schoolDistrict.length > 0) {
      setSchoolDistricts(
        schoolDistrictsData?.schoolDistrict.map((d: SchoolDistrict) => {
          return { label: d.school_district_name, value: d.school_district_name }
        }),
      )
    }
  }, [schoolDistrictsDataLoading])

  useEffect(() => {
    if (ableToEdit === true) clickedEvent!.target.focus()
  }, [ableToEdit])

  const setFocused = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (!ableToEdit || clickedEvent!.target !== event.target) {
      event.preventDefault()
      event.target.blur()
      setClickedEvent(event)
    }
  }

  const setBlured = () => {
    setAbleToEdit(false)
  }

  const closeReimbursementModal = () => setShowReimbursementModal(false)
  const toggleHomeroomResourceModal = () => setShowHomeroomResourceModal(!showHomeroomResourceModal)

  // @ts-ignore
  const transformFields = (data): ScheduleInfo[] => {
    if (!Array.isArray(data)) {
      return []
    }

    return data.map((item) => {
      return {
        status: item.status,
        scheduleId: item.schedule_id,
        lastModified: item.last_modified,
        isSecondSemester: item.is_second_semester,
        dateSubmitted: item.date_submitted,
        dateAccepted: item.date_accepted,
        currentSubmission: item.current_submission,
        studentId: item.StudentId,
      }
    })
  }

  useEffect(() => {
    if (
      scheduleByStudent &&
      scheduleByStudent.scheduleByStudentId.length &&
      (!epic3862story5640 ||
        (currentSchoolYear &&
          scheduleByStudent.scheduleByStudentId.find(
            (schedule: Schedule) => schedule.SchoolYearId === currentSchoolYear.school_year_id,
          )))
    ) {
      const transformedData = transformFields([scheduleByStudent.scheduleByStudentId.at(-1)])
      const secondSemesterSchedules =
        transformedData.filter((schedule: ScheduleInfo) => schedule.isSecondSemester) || []

      // If there is a second semester schedule, use that one; otherwise, use the first schedule
      const selectedSchedule = secondSemesterSchedules.length > 0 ? secondSemesterSchedules[0] : transformedData[0]

      setActualSchedule(selectedSchedule)

      const firstSchedule = transformedData.filter((schedule: ScheduleInfo) => !schedule.isSecondSemester)
      setFirstSemesterSchedule(firstSchedule.length > 0 ? firstSchedule[0] : transformedData[0])
    } else if (scheduleByStudent && !scheduleByStudent.scheduleByStudentId.length) {
      setActualSchedule({})
    }
  }, [scheduleByStudent, currentSchoolYear])

  const shouldHaveWidth =
    scheduleByStudent &&
    scheduleByStudent?.scheduleByStudentId.length > 0 &&
    (scheduleByStudent.scheduleByStudentId.at(-1).status === ScheduleStatus.UPDATES_REQUESTED ||
      scheduleByStudent.scheduleByStudentId.at(-1).status === ScheduleStatus.UPDATES_REQUIRED)

  return (
    <Box
      sx={{
        marginTop: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <Button
          sx={{
            background: MthColor.BUTTON_LINEAR_GRADIENT,
            textTransform: 'none',
            color: 'white',
            marginRight: 2,
            width: '264px',
            height: '34px',
            borderRadius: 2,
          }}
          onClick={() => setShowReimbursementModal(true)}
        >
          {MthTitle.REIMBURSEMENTS}
        </Button>
        <Button
          onClick={toggleHomeroomResourceModal}
          sx={{
            background: MthColor.BUTTON_LINEAR_GRADIENT,
            textTransform: 'none',
            color: 'white',
            marginRight: 2,
            width: '264px',
            height: '34px',
            borderRadius: 2,
          }}
        >
          Homeroom Resources
        </Button>
        <Button
          sx={{
            background: MthColor.BUTTON_LINEAR_GRADIENT,
            textTransform: 'none',
            color: 'white',
            marginRight: 2,
            width: '264px',
            height: '34px',
            borderRadius: 2,
          }}
          onClick={() => {
            window.open(`${MthRoute.GRADEBOOK}/${studentId}`, '_blank', 'noreferrer')
          }}
        >
          Homeroom (Sample Teacher)
        </Button>
      </Box>
      <Grid container marginTop={4} columnSpacing={4} rowSpacing={3}>
        <Grid item xs={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Avatar
              alt={preferedFirstName ?? legalFirstName}
              src={EMPTY_STRING}
              variant='rounded'
              style={{ height: '127px', width: '127px', marginRight: '12px', fontSize: '3rem' }}
            >
              {getStringInitialLetter(preferedFirstName ?? legalFirstName)}
            </Avatar>
            <Box
              sx={{
                textAlign: 'left',
              }}
            >
              {/* @ts-ignore */}
              <Title textAlign='left' fontWeight='500' color={MthColor.MTHBLUE}>
                {legalFirstName} {legalLastName}
              </Title>

              <div>
                <Select
                  IconComponent={KeyboardArrowDown}
                  value={gradeLevel}
                  sx={{ ...classes.selectSoE, color: MthColor.SYSTEM_01, fontWeight: '600', fontSize: '16px' }}
                  onChange={(e) => {
                    setGradeLevel(e.target.value)
                    setIsChanged(true)
                    setStudentStatus({ ...studentStatus, ...{ grade_level: e.target.value } })
                  }}
                  autoWidth
                >
                  {GRADES.map((grade) => (
                    <MenuItem key={grade} value={grade}>
                      {expandAbbreviationSingleGrade(String(grade))}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <Select
                  IconComponent={KeyboardArrowDown}
                  sx={{ ...classes.selectSoE, color: MthColor.SYSTEM_01, fontWeight: '600', fontSize: '16px' }}
                  value={schoolPartnerId}
                  onChange={handleChangeSoE}
                  autoWidth
                  disabled={
                    epic2209Story5188 &&
                    (isNil(originStudentStatus) ||
                      isInEligibleStudentStatus(
                        schoolYearToShow?.grades,
                        student?.grade_levels?.at(-1),
                        schoolYearToShow?.date_begin,
                      ) ||
                      ![StudentStatus.ACTIVE, StudentStatus.PENDING].includes(originStudentStatus))
                  }
                >
                  {SoEitems.map((el: { value: string; label: string }) => (
                    <MenuItem value={el.value} key={el.value}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid item container xs={4}>
          <Grid item xs={3} sx={{ alignItems: 'center', display: 'flex', fontWeight: '700' }}>
            Packet
          </Grid>
          <Grid item xs={9} sx={{ alignItems: 'center', display: 'flex' }}>
            <Button
              sx={{
                background: MthColor.BUTTON_LINEAR_GRADIENT,
                textTransform: 'none',
                color: 'white',
                marginRight: 2,
                width: shouldHaveWidth ? '215px' : '198px',
                height: '29px',
                borderRadius: 2,
                fontWeight: '500',
              }}
              onClick={handlePacket}
            >
              {packets.length ? `${packets[packets.length - 1].status}` : EMPTY_STRING}
              {packets.length && packets[packets.length - 1].status === PacketStatus.ACCEPTED
                ? ` ${dayjs(packets[packets.length - 1].date_accepted).format('MM/DD/YY')}`
                : EMPTY_STRING}
              {packets.length && packets[packets.length - 1].status === PacketStatus.SUBMITTED
                ? ` ${dayjs(packets[packets.length - 1].date_submitted).format('MM/DD/YY')}`
                : EMPTY_STRING}
              {packets.length && packets[packets.length - 1].status === PacketStatus.RESUBMITTED
                ? ` ${dayjs(packets[packets.length - 1].date_submitted).format('MM/DD/YY')}`
                : EMPTY_STRING}
            </Button>
          </Grid>
          <Grid item xs={3} sx={{ alignItems: 'center', display: 'flex', fontWeight: '700' }}>
            Schedule
          </Grid>
          <Grid item xs={9} sx={{ alignItems: 'center', display: 'flex' }}>
            <div>
              <Button
                sx={{
                  background: MthColor.BUTTON_LINEAR_GRADIENT,
                  textTransform: 'none',
                  color: 'white',
                  marginRight: 2,
                  width: shouldHaveWidth ? '215px' : '198px',
                  height: '29px',
                  borderRadius: 2,
                  fontWeight: '500',
                }}
                onClick={() => {
                  if (epic3862story5640 && (!actualSchedule || isEmpty(actualSchedule))) return
                  window.open(`/enrollment/enrollment-schedule/${studentId}`, '_blank')
                }}
              >
                {actualSchedule?.status ? actualSchedule.status : null}
                {actualSchedule?.status &&
                actualSchedule?.status !== ScheduleStatus.NOT_SUBMITTED &&
                actualSchedule?.lastModified
                  ? ` ${formatDateByPattern(
                      utcToTimezoned(new Date(actualSchedule.lastModified), timeZone),
                      datePatterns.ZMONTH_ZDAY_2DYEAR,
                    )}`
                  : null}
              </Button>
              <br />
              <Paragraph sx={{ color: MthColor.MTHBLUE, textAlign: 'Center' }}>
                {actualSchedule &&
                  actualSchedule.status &&
                  actualSchedule.status !== 'Not Submitted' &&
                  (firstSemesterSchedule?.dateSubmitted
                    ? `${MthTitle.ORIGINALLY_SUBMITTED} ${formatDateByPattern(
                        utcToTimezoned(new Date(firstSemesterSchedule.dateSubmitted), timeZone),
                        datePatterns.ZMONTH_ZDAY_2DYEAR,
                      )}`
                    : EMPTY_STRING)}
              </Paragraph>
            </div>
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid item xs={12} sx={{ alignItems: 'center', display: 'flex' }}>
            {/* @ts-ignore */}
            <Subtitle textAlign='left' fontWeight='700' color={MthColor.GRAY} sx={{ marginLeft: 3 }}>
              {`${MthTitle.FIRST_SEMESTER} # of Zeros`}
            </Subtitle>
          </Grid>
          <Grid item xs={12} sx={{ alignItems: 'center', display: 'flex' }}>
            {/* @ts-ignore */}
            <Subtitle textAlign='left' fontWeight='700' color={MthColor.GRAY} sx={{ marginLeft: 3 }}>
              {`${MthTitle.SECOND_SEMESTER} # of Zeros`}
            </Subtitle>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Grid item container xs={4}>
            <Grid item xs={12} sx={{ alignItems: 'center', display: 'flex' }}>
              <Button
                sx={{
                  background: MthColor.BUTTON_LINEAR_GRADIENT,
                  textTransform: 'none',
                  color: 'white',
                  marginLeft: 5,
                  width: '198px',
                  height: '29px',
                  borderRadius: 2,
                  fontWeight: '500',
                }}
                onClick={() => setOpenNotes(true)}
              >
                Notes and Inventions
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item marginTop={5} xs={12}>
        <StudentFilters
          student={student!}
          setStudentStatusData={setStudentStatus}
          studentStatusData={studentStatus}
          originStudentStatus={originStudentStatus!}
          withdrawalStatus={withdrawalStatus!}
          setWithdrawalStatus={setWithdrawalStatus}
          setIsChanged={setIsChanged}
          specialEdOptions={specialEdOptions}
          schoolYears={studentSchoolYears}
          currentSchoolYear={currentSchoolYear}
          nextSchoolYear={nextSchoolYear}
          schoolYearToShowProp={schoolYearToShow}
        />
      </Grid>
      <Grid container marginTop={1} columnSpacing={4} rowSpacing={3}>
        <Grid item xs={3}>
          <Paragraph size={TEXT_SIZE.LARGE} textAlign='left' sx={classes.label}>
            Preferred First Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={preferedFirstName || legalFirstName}
            onChange={(e) => {
              setPreferredFirstName(e.target.value)
              setUserInfo({ ...userInfo!, ...{ preferred_first_name: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Preferred Last Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={preferedLastName || legalLastName}
            onChange={(e) => {
              setPreferredLastName(e.target.value)
              setUserInfo({ ...userInfo!, ...{ preferred_last_name: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Gender
          </Paragraph>
          <DropDown
            size='medium'
            sx={classes.dropdown}
            dropDownItems={genderItems}
            defaultValue={gender}
            placeholder={gender}
            setParentValue={(e) => {
              setGender(e as string)
              setUserInfo({ ...userInfo!, ...{ gender: e as string } })
            }}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' sx={classes.label}>
            Testing Preference
          </Paragraph>
          {visibleAssessmentItems(assessmentItems).map((assess: AssessmentType) => (
            <Grid item container key={assess.assessment_id}>
              <Grid item xs={5} sx={{ alignItems: 'center', display: 'flex', fontWeight: '700' }}>
                <Subtitle sx={{ fontWeight: '700', fontSize: '12px', color: MthColor.SYSTEM_01 }}>
                  {assess.test_name}
                </Subtitle>
              </Grid>
              <Grid item xs={7} sx={{ alignItems: 'center', display: 'flex' }}>
                <Select
                  value={studentAseessOption(assess.assessment_id)}
                  IconComponent={KeyboardArrowDown}
                  sx={{ ...classes.selectTP, ...classes.label, marginBottom: '0px' }}
                  onChange={(e) => handleTestPreferenceChange(assess.assessment_id, Number(e.target.value))}
                >
                  {assess.Options.map((el) => (
                    <MenuItem value={el.option_id} key={el.option_id}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container marginTop={1} columnSpacing={4} rowSpacing={3}>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Legal First Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={legalFirstName}
            onChange={(e) => {
              setLegalFirstName(e.target.value)
              setUserInfo({ ...userInfo!, ...{ first_name: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Legal Middle Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={legalMiddleName}
            onChange={(e) => {
              setLegalMiddleName(e.target.value)
              setUserInfo({ ...userInfo!, ...{ middle_name: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Legal Last Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={legalLastName}
            onChange={(e) => {
              setLegalLastName(e.target.value)
              setUserInfo({ ...userInfo!, ...{ last_name: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Student Email
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              setUserInfo({ ...userInfo!, ...{ email: e.target.value } })
            }}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={1} columnSpacing={4} rowSpacing={3}>
        <Grid item xs={3}>
          <Paragraph
            size='medium'
            textAlign='left'
            sx={DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label}
          >
            Parent Phone
          </Paragraph>
          <TextField
            disabled={DISABLE_STUDENT_ADDRESS}
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={phoneInfo?.number}
            onChange={(e) => {
              const formattedNumber = e.target.value.replace(/\D+/g, EMPTY_STRING).slice(0, 10)
              setPhoneInfo({ ...phoneInfo, number: formattedNumber })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph
            size='medium'
            textAlign='left'
            sx={DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label}
          >
            Parent Email
          </Paragraph>
          <TextField
            disabled={DISABLE_STUDENT_ADDRESS}
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={parentEmail}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Grid container marginTop={1} columnSpacing={4} rowSpacing={3}>
        <Grid item xs={6}>
          <Paragraph
            size='medium'
            textAlign='left'
            sx={DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label}
          >
            Address Line 1
          </Paragraph>
          <TextField
            disabled={DISABLE_STUDENT_ADDRESS}
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={street1}
            onFocus={(e) => {
              setFocused(e)
            }}
            onBlur={() => {
              setBlured()
            }}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xs={6}>
          <Paragraph
            size='medium'
            textAlign='left'
            sx={DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label}
          >
            Address Line 2
          </Paragraph>
          <TextField
            disabled={DISABLE_STUDENT_ADDRESS}
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={street2}
            onFocus={(e) => {
              setFocused(e)
            }}
            onBlur={() => {
              setBlured()
            }}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xs={6}>
          <Paragraph
            size='medium'
            textAlign='left'
            sx={DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label}
          >
            Citys
          </Paragraph>
          <TextField
            disabled={DISABLE_STUDENT_ADDRESS}
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={city}
            onFocus={(e) => {
              setFocused(e)
            }}
            onBlur={() => {
              setBlured()
            }}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph
            size='medium'
            textAlign='left'
            sx={DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label}
          >
            State
          </Paragraph>
          <DropDown
            disabled={DISABLE_STUDENT_ADDRESS}
            sx={classes.dropdown}
            dropDownItems={US_STATES as DropDownItem[]}
            setParentValue={() => {}}
            alternate={true}
            size='medium'
            defaultValue={state || EMPTY_STRING}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph
            size='medium'
            textAlign='left'
            sx={DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label}
          >
            Zip
          </Paragraph>
          <TextField
            disabled={DISABLE_STUDENT_ADDRESS}
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={zip}
            onFocus={(e) => {
              setFocused(e)
            }}
            onBlur={() => {
              setBlured()
            }}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xs={6}>
          <Paragraph
            size='medium'
            textAlign='left'
            sx={DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label}
          >
            County
          </Paragraph>
          <DropDown
            disabled={DISABLE_STUDENT_ADDRESS}
            size='medium'
            sx={classes.dropdown}
            dropDownItems={counties}
            defaultValue={county}
            placeholder={'Select County'}
            auto={false}
            setParentValue={() => {}}
          />
        </Grid>
        <Grid item xs={6}>
          <Paragraph
            size='medium'
            textAlign='left'
            sx={DISABLE_STUDENT_ADDRESS ? classes.labelDisabled : classes.label}
          >
            School District
          </Paragraph>
          <DropDown
            disabled={DISABLE_STUDENT_ADDRESS}
            size='medium'
            sx={classes.dropdown}
            dropDownItems={schoolDistricts}
            defaultValue={school_district}
            placeholder={'Select School District'}
            auto={false}
            setParentValue={() => {}}
          />
        </Grid>
      </Grid>
      {epic275story5395 ? (
        <>
          <Grid container marginTop={1} columnSpacing={4} rowSpacing={3}>
            <FamilyNotesAndStudentNotes
              subtittleStyle={classes.note}
              textFieldStyle={classes.text}
              notes={notes}
              setNotes={setNotes}
              familyNotesTestId={testIDs.familyNotes}
              studentNotesTestId={testIDs.studentNotes}
              studentNotes={studentNotes}
              setStudentNotes={setStudentNotes}
              isFamilyNotesEditable={false}
              isEditedByExternal={isEditedByExternal}
            ></FamilyNotesAndStudentNotes>
          </Grid>
          <Grid container marginTop={1} columnSpacing={4} rowSpacing={3}>
            <Grid item xs={12} sx={{ marginTop: 'auto', textAlign: 'right' }}>
              <Typography color={MthColor.DISABLED} sx={{ fontWeight: '700' }}>
                {USER_ID} {userInfo?.uniqueId}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container marginTop={1} columnSpacing={4} rowSpacing={3}>
          <Grid item xs={7}>
            <Subtitle fontWeight='700' size={TEXT_SIZE.LARGE} textAlign='left' sx={classes.note}>
              Notes
            </Subtitle>
            <TextField
              disabled={DISABLE_STUDENT_ADDRESS}
              sx={classes.text}
              size='small'
              variant='outlined'
              fullWidth
              value={notes || EMPTY_STRING}
              onChange={(e) => {
                setNotes(e.target.value)
              }}
              multiline
              rows={8}
            />
          </Grid>
          <Grid item xs={5} sx={{ marginTop: 'auto', textAlign: 'right' }}>
            <Typography color={MthColor.DISABLED} sx={{ fontWeight: '700' }}>
              {USER_ID} {userInfo?.uniqueId}
            </Typography>
          </Grid>
        </Grid>
      )}
      {openNotes && (
        <WarningModal
          handleModem={() => setOpenNotes(false)}
          title={MthTitle.NOTES}
          subtitle={EMPTY_STRING}
          btntitle={MthTitle.CLOSE}
          handleSubmit={() => setOpenNotes(false)}
          showIcon={false}
        />
      )}
      {showPacketModal && (
        <ProfilePacketModal
          handleModem={() => setShowPacketModal(false)}
          packet_id={packetID}
          refetch={() => refetchStudent()}
        />
      )}
      {modalAssign && (
        <StudentSoeTransferModal
          unassign={tempSoE === soeLowStatus.UNASSIGNED}
          handleClose={() => setModalAssign(false)}
          handleSubmit={(option: SoETransferOption) => handleAssignOrTransfer(option)}
        />
      )}
      {showHomeroomResourceModal && (
        <HomeroomResourceModal
          toggleHomeroomResourceModal={toggleHomeroomResourceModal}
          showHomeromResourceModal={showHomeroomResourceModal}
          schoolYearIdSelected={selectedYearId}
          studentIds={familyStudentIds}
        />
      )}
      {showReimbursementModal && (
        <ReimbursementModal closeModal={closeReimbursementModal} isFromParent={false} studentId={Number(studentId)} />
      )}
    </Box>
  )
}
