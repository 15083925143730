type GetYearTextParams = {
  dateBegin: string
  dateEnd: string
  midyearApplication: boolean
}

export const getYearText = ({ dateBegin, dateEnd, midyearApplication }: GetYearTextParams) => {
  const yearBegin = new Date(dateBegin).getFullYear().toString()
  const yearEnd = new Date(dateEnd).getFullYear().toString()
  const yearStringStartIndex = 2
  const yearStringEndIndex = 4
  const midYearString = ' Mid-year'

  return midyearApplication
    ? `${yearBegin}-${yearEnd.substring(yearStringStartIndex, yearStringEndIndex)}${midYearString}`
    : `${yearBegin}-${yearEnd.substring(yearStringStartIndex, yearStringEndIndex)}`
}
