export enum QUESTION_TYPE {
  DROPDOWN = 1,
  TEXTFIELD,
  CHECKBOX,
  AGREEMENT,
  MULTIPLECHOICES,
  CALENDAR,
  INFORMATION,
  UPLOAD,
  SIGNATURE,
  TEXTBOX,
  SPECIAL_EDUCATION,
  PROGRAM_STATE,
  PROGRAM_YEAR,
}

export enum EnrollmentQuestionFields {
  ID = 'id',
  GROUP_ID = 'group_id',
  TYPE = 'type',
  QUESTION = 'question',
  DESCRIPTION = 'description',
  OPTIONS = 'options',
  REQUIRED = 'required',
  MISSEDINFO = 'missedInfo',
  ORDER = 'order',
  ADDITIONAL_QUESTION = 'additional_question',
  VALIDATION = 'validation',
  DISPLAY_ADMIN = 'display_admin',
  DEFAULT_QUESTION = 'default_question',
  SLUG = 'slug',
}
