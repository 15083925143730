import { gql } from '@apollo/client'

export const getActivePendingStudentsBySchoolYear = gql`
  query GetActivePendingStudentsBySchoolYear($schoolYearId: ID!, $studentId: ID!) {
    getActivePendingStudentsBySchoolYear(schoolYearId: $schoolYearId, studentId: $studentId) {
      student
      student_id
    }
  }
`
