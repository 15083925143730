export type StandardResponseOptionValues = { title: string; checked: boolean; extraText: string | null; abbr?: string }

export enum StandardResponseOptionType {
  MISSING_INFO = 'MISSING_INFO',
  AGE_ISSUE = 'AGE_ISSUE',
}

export type StandardResponseOption = {
  type: StandardResponseOptionType
  default: string
  values: StandardResponseOptionValues[]
}

export type StandardResponseProps = {
  options: StandardResponseOption
  setTemplate: () => void
}
