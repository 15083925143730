import { US_STATES } from '@mth/constants'
import { RegionDetailProps } from '@mth/models'
import { UserInfo } from '@mth/providers/UserContext/UserProvider'

export const DEMO_REGION_CODE = 'DEMO'

export const sortRegions = (allRegions: RegionDetailProps = []): RegionDetailProps => {
  //  Sort user regions by region name
  const regions: RegionDetailProps = []
  allRegions.forEach((region) => {
    let i
    for (i = 0; i < regions.length; i++) {
      if (regions[i] && regions[i].name !== undefined && regions[i].name > region.name) break
    }
    regions.splice(i, 0, region)
  })

  return regions
}

export const getRegionCode = (regionName: string | undefined): string => {
  return US_STATES.find((item) => item.label === regionName)?.value || ''
}

export const getTimezone = (me: UserInfo): string => {
  if (me.selectedRegionId === undefined) {
    const region = me.userRegion?.at(0)
    return String(region?.regionDetail?.timezone)
  } else {
    const selectedRegionId = me.selectedRegionId
    const region = me.userRegion?.find((region) => Number(region.region_id) === Number(selectedRegionId))
    return String(region?.regionDetail.timezone)
  }
}
