import { datadogLogs } from '@datadog/browser-logs'
import { RumFetchResourceEventDomainContext, datadogRum } from '@datadog/browser-rum'
import { STAGE, DATADOG_CONFIG } from '@mth/constants'
import { SNOWPACK_PUBLIC_APP_VERSION } from '@mth/envs'

const stage = import.meta.env.VITE_PUBLIC_APP_STAGE
if ([STAGE.STAGING, STAGE.DEMO, STAGE.PRODUCTION].includes(stage)) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_PUBLIC_DATADOG_APPLICATION_ID || '',
    clientToken: import.meta.env.VITE_PUBLIC_DATADOG_CLIENT_TOKEN || '',
    site: DATADOG_CONFIG.SITE,
    service: DATADOG_CONFIG.SERVICE,
    env: import.meta.env.VITE_PUBLIC_APP_ENVIRONMENT || '',
    version: SNOWPACK_PUBLIC_APP_VERSION.toLowerCase(),
    sessionSampleRate: 100,
    trackUserInteractions: true,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      /^https:\/\/(?:.*api.*\.)?mytechhigh\.com\/.*/,
      /^https:\/\/(?:.*api.*\.)?infocenter\.tech\/.*/,
    ],
    beforeSend: (event, context) => {
      if (event.type === 'resource' && event.resource.type === 'fetch') {
        event.context = {
          ...event.context,
          requestBody: (context as RumFetchResourceEventDomainContext).requestInit?.body,
        }
      }
    },
  })

  datadogRum.startSessionReplayRecording()

  datadogLogs.init({
    clientToken: import.meta.env.VITE_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: DATADOG_CONFIG.SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })
}
