import React from 'react'
import { Button, CircularProgress, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import {
  MthColor,
  EnrollmentPacketSaveAction,
  PacketStatus,
  EnrollmentPacketFormProperty,
  ImmunizationValue,
} from '@mth/enums'
import { checkImmmValueWithSpacing, isValidDate, isValidVaccInput } from './helpers'
import { EnrollmentPacketFormType } from './types'

interface PacketSaveButtonsProps {
  submitForm: () => void
  loading?: boolean
}

export const PacketSaveButtons: React.FC<PacketSaveButtonsProps> = ({ submitForm, loading }) => {
  const { watch, getValues, setValue, setError } = useFormContext<EnrollmentPacketFormType>()

  const status = watch(EnrollmentPacketFormProperty.STATUS) as PacketStatus
  const [exemptionDate, enableExemptionDate] = watch(['exemptionDate', 'enableExemptionDate'])
  const onlySaveButton = ![PacketStatus.SUBMITTED as string, PacketStatus.RESUBMITTED as string].includes(status)

  const isValidExemptDate = !enableExemptionDate || isValidDate(exemptionDate)

  function onClick(action: EnrollmentPacketSaveAction) {
    const values = getValues()

    setValue(EnrollmentPacketFormProperty.LAST_CLICKED_ACTION, action)

    if (enableExemptionDate && !isValidDate(values.exemptionDate)) {
      setError(EnrollmentPacketFormProperty.EXEMPTION_DATE, { type: 'required', message: 'Please enter a valid date' })
      return
    }

    if (action === EnrollmentPacketSaveAction.MISSING_INFO) {
      setValue(EnrollmentPacketFormProperty.SHOW_MISSING_INFO_MODAL, true)
    } else if (action === EnrollmentPacketSaveAction.AGE_ISSUE) {
      setValue(EnrollmentPacketFormProperty.SHOW_AGE_ISSUE_MODAL, true)
    } else {
      let isValid = true
      for (const e of values.immunizations) {
        if (e?.immunization?.is_deleted) continue
        if (!isValidVaccInput(e?.value, e?.immunization?.immunity_allowed === 1)) {
          isValid = false
          break
        }
        if (e.value === ImmunizationValue.EXEMPT && !isValidDate(values.exemptionDate)) {
          isValid = false
          break
        }
        if (!checkImmmValueWithSpacing(e, values.immunizations)) {
          isValid = false
          break
        }
      }
      if ([EnrollmentPacketSaveAction.ACCEPTED, EnrollmentPacketSaveAction.CONDITIONAL].includes(action)) {
        setValue(EnrollmentPacketFormProperty.PRE_SAVE_STATUS, action)
        if (isValid) {
          submitForm()
          setValue(EnrollmentPacketFormProperty.STATUS, action)
        } else setValue(EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL, true)
      } else {
        if (isValid) submitForm()
        else setValue(EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL, true)
      }
    }
  }

  const buttons = [
    {
      label: EnrollmentPacketSaveAction.SAVE,
      disabled: !isValidExemptDate,
      color: MthColor.BUTTON_LINEAR_GRADIENT,
      hoverColor: undefined,
    },
    {
      label: EnrollmentPacketSaveAction.ACCEPTED,
      disabled: onlySaveButton || !isValidExemptDate,
      color: MthColor.GREEN_GRADIENT,
      hoverColor: MthColor.GREEN,
    },
    {
      label: EnrollmentPacketSaveAction.MISSING_INFO,
      disabled: onlySaveButton || !isValidExemptDate,
      color: MthColor.RED_GRADIENT,
      hoverColor: MthColor.RED,
    },
    {
      label: EnrollmentPacketSaveAction.AGE_ISSUE,
      disabled: onlySaveButton || !isValidExemptDate,
      color: MthColor.YELLOW_GRADIENT,
      hoverColor: MthColor.YELLOW2,
    },
    {
      label: EnrollmentPacketSaveAction.CONDITIONAL,
      disabled: onlySaveButton || !isValidExemptDate,
      color: MthColor.BLACK_GRADIENT,
      hoverColor: MthColor.SYSTEM_02,
    },
  ]

  return (
    <>
      <Grid
        sx={{
          '&.MuiGrid-root': {
            width: '100%',
            // minWidth: '600px',
          },
        }}
        container
      >
        <Grid container>
          {buttons.map((button, index) => (
            <Grid item key={index}>
              <Button
                endIcon={loading && <CircularProgress size={10} />}
                disabled={button.disabled || loading}
                sx={{
                  borderRadius: 2,
                  textTransform: 'none',
                  height: 25,
                  background: button.color,
                  '&:hover': {
                    background: button.hoverColor,
                  },
                  color: 'white',
                  width: '105px',
                  marginRight: '5px',
                }}
                onClick={() => onClick(button.label)}
              >
                {button.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}
