import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { MthModal } from '@mth/components/MthModal/MthModal'
import {
  containerBoxStyles,
  progressCircleContainerStylesCentered,
  progressCircleContainerStylesNoCentered,
  progressCircleStyles,
} from './MthLoadingWrapper.styles'

export type MthLoadingWrapperProps = {
  children: React.ReactNode
  loading: boolean
  minHeight?: string
  modalMode?: boolean
  modalTitle?: string
  hideChildrenWhenLoading?: boolean
  centerVertical?: boolean
}
export const MthLoadingWrapper: React.FC<MthLoadingWrapperProps> = ({
  children,
  loading,
  minHeight = '150px',
  modalMode = false,
  modalTitle,
  hideChildrenWhenLoading = false,
  centerVertical = true,
}) => {
  const shouldShowChildren = !(hideChildrenWhenLoading && loading) || !hideChildrenWhenLoading
  return (
    <Box sx={{ ...containerBoxStyles, minHeight }}>
      {shouldShowChildren && children}
      {!modalMode && loading && (
        <Box sx={centerVertical ? progressCircleContainerStylesCentered : progressCircleContainerStylesNoCentered}>
          <CircularProgress sx={progressCircleStyles} />
        </Box>
      )}
      {modalMode && loading && (
        <MthModal title={modalTitle} open onClose={() => {}} onConfirm={() => {}} loading={loading}></MthModal>
      )}
    </Box>
  )
}
