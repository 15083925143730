import React from 'react'
import { useMutation } from '@apollo/client'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import AddIcon from '@mui/icons-material/Add'
import { Avatar, Button, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { useHistory } from 'react-router-dom'
import { Metadata } from '@mth/components/Metadata/Metadata'
import { EMPTY_STRING } from '@mth/constants'
import { Paragraph } from '@mth/core/components/shared/Paragraph/Paragraph'
import { Subtitle } from '@mth/core/components/shared/Subtitle/Subtitle'
import { MthColor, MthRoute, RoleLabels } from '@mth/enums'
import { becomeUserMutation } from '@mth/graphql/mutation/user'
import { UserInfo } from '@mth/providers/UserContext/UserProvider'
import { getStringInitialLetter } from '@mth/utils'

type UserData = {
  user: {
    user_id: number
  }
  first_name: string
  last_name: string
  preferred_first_name?: string
}

type HeaderProps = {
  userData: UserData
  setOpenObserverModal: (_: boolean) => void
  observers: unknown
  handleChangeParent: () => void
  selectedParent: unknown
  parentId: number
  me: UserInfo
}
export const Header: React.FC<HeaderProps> = ({
  userData,
  setOpenObserverModal,
  observers,
  handleChangeParent,
  selectedParent,
  parentId,
  me,
}) => {
  const handleOpenObserverModal = () => {
    setOpenObserverModal(true)
    // if (selectedParentType == "parent") {
    // }
  }

  const history = useHistory()
  const [becomeUserAction] = useMutation(becomeUserMutation)

  // @ts-ignore
  const becomeUser = (userId: number, parentId: number) => {
    becomeUserAction({
      variables: {
        userId: userId,
      },
    })
      .then((resp) => {
        localStorage.setItem('masquerade', resp.data.masqueradeUser.jwt)
        localStorage.setItem(
          'profileData',
          JSON.stringify({
            role: RoleLabels.PARENT,
            parent_id: parentId,
          }),
        )
        localStorage.setItem('previousPage', location.href.replace(import.meta.env.VITE_PUBLIC_WEB_URL, ''))
      })
      .then(() => {
        history.push(MthRoute.DASHBOARD.toString())
        location.reload()
      })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {/* @ts-ignore */}
      <Box sx={{ cursor: 'pointer' }} onClick={() => handleChangeParent(userData)}>
        <Metadata
          title={
            userData && (
              <Subtitle
                fontWeight='800'
                // @ts-ignore
                color={selectedParent === parseInt(parentId) ? MthColor.MTHBLUE : MthColor.SYSTEM_01}
                size='medium'
              >
                {/* @ts-ignore */}
                {userData.first_name} {userData.last_name}
              </Subtitle>
            )
          }
          subtitle={
            <Box display={'flex'} flexDirection='row' alignItems={'center'} alignContent='center'>
              {/* @ts-ignore */}
              <Paragraph fontWeight='600' color={MthColor.SYSTEM_11} sx={{ fontSize: '16px' }}>
                Parent
              </Paragraph>
              {/* @ts-ignore */}
              {selectedParent === parseInt(parentId) && Boolean(me.masquerade) && (
                // @ts-ignore
                <Tooltip title='Masquerade' onClick={() => becomeUser(Number(userData.user.user_id), Number(parentId))}>
                  <AccountBoxOutlinedIcon
                    sx={{ color: MthColor.MTHBLUE, marginLeft: 1, height: 15, width: 15, marginTop: 0.25 }}
                  />
                </Tooltip>
              )}
            </Box>
          }
          image={
            <Avatar
              // @ts-ignore
              alt={userData && (userData.preferred_first_name ?? userData.first_name)}
              src={EMPTY_STRING}
              variant='rounded'
              style={{ marginRight: 8 }}
            >
              {/* @ts-ignore */}
              {getStringInitialLetter(userData && (userData.preferred_first_name ?? userData.first_name))}
            </Avatar>
          }
        />
      </Box>
      {/* @ts-ignore */}
      {observers.map((item, idx) => (
        <Box
          sx={{
            marginLeft: '12px',
            cursor: 'pointer',
          }}
          key={idx}
          // @ts-ignore
          onClick={() => handleChangeParent(item)}
        >
          <Metadata
            title={
              <Subtitle
                fontWeight='800'
                // @ts-ignore
                color={selectedParent === parseInt(item.observer_id) ? MthColor.MTHBLUE : MthColor.SYSTEM_01}
                size='medium'
              >
                {item.user.first_name} {item.user.last_name}
              </Subtitle>
            }
            subtitle={
              <Box display={'flex'} flexDirection='row' alignItems={'center'} alignContent='center'>
                {/* @ts-ignore */}
                <Paragraph fontWeight='600' color={MthColor.SYSTEM_11} sx={{ fontSize: '16px' }}>
                  Observer
                </Paragraph>
                {/* @ts-ignore */}
                {selectedParent === parseInt(parentId) && Boolean(me.masquerade) && (
                  <Tooltip
                    title='Masquerade'
                    onClick={() => becomeUser(Number(userData.user.user_id), Number(parentId))}
                  >
                    <AccountBoxOutlinedIcon
                      sx={{ color: MthColor.MTHBLUE, marginLeft: 1, height: 15, width: 15, marginTop: 0.25 }}
                    />
                  </Tooltip>
                )}
              </Box>
            }
            image={
              <Avatar
                alt={item.person?.preferred_first_name ?? item.person?.first_name}
                src={EMPTY_STRING}
                variant='rounded'
                style={{ marginRight: 8 }}
              >
                {getStringInitialLetter(item.person?.preferred_first_name ?? item.person?.first_name)}
              </Avatar>
            }
          />
        </Box>
      ))}

      <Button
        onClick={handleOpenObserverModal}
        disableElevation
        variant='contained'
        sx={{
          marginLeft: '12px',
          background: '#FAFAFA',
          color: 'black',
          textTransform: 'none',
          fontSize: '16px',
          '&:hover': {
            background: MthColor.SYSTEM_14,
            color: '#000',
          },
        }}
        startIcon={<AddIcon />}
      >
        Add Observer
      </Button>
    </Box>
  )
}
