import { MthColor } from '@mth/enums'

export const useStyles = {
  homeroomResourceModal: {
    bgcolor: 'background.paper',
    p: 4,
    minHeight: '200px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '1120px',
    backgroundColor: MthColor.WHITE,
    boxShadow: 24,
    borderRadius: 2,
    px: 2,
    pt: 2,
    pb: 4.5,
    maxHeight: '750px',
    overflow: 'auto',
  },
  modalCard: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 754,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  },
  cancelIcon: {
    width: '37px',
    minWidth: '37px',
    height: '35x',
    minHeight: '35px',
    borderRadius: 2,
    background: MthColor.BLACK,
    color: MthColor.WHITE,
    '&:hover': {
      backgroundColor: MthColor.BLACK,
    },
  },
  modalStudentCard: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    minHeight: '200px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: ` 1px solid ${MthColor.SYSTEM_08}`,
    paddingBottom: '10px',
  },
  close: {
    background: 'black',
    borderRadius: 1,
    color: 'white',
    cursor: 'pointer',
    zIndex: 10,
  },
  errorOutline: {
    background: '#FAFAFA',
    borderRadius: 1,
    color: MthColor.BLACK,
    marginBottom: 12,
    height: 42,
    width: 42,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  submitButton: {
    borderRadius: 8,
    width: '200px',
    marginTop: 4,
    height: '24px',
  },
}
