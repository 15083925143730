import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Typography } from '@mui/material'
import parse from 'html-react-parser'
import { MthModal } from '@mth/components/MthModal/MthModal'
import { MthTitle } from '@mth/enums'
import { AnnouncementPopup } from '@mth/types'

interface AnnouncementPopupModalProps {
  announcementPopup: AnnouncementPopup
  onClose: () => void
}

export const AnnouncementPopupModal: React.FC<AnnouncementPopupModalProps> = ({ announcementPopup, onClose }) => {
  return (
    <MthModal
      onConfirm={onClose}
      onClose={onClose}
      open={true}
      width={400}
      confirmStr={MthTitle.CLOSE}
      showBtnCancel={false}
    >
      <Box>
        <Box textAlign='center'>
          <Typography fontSize={20} fontWeight={700}>
            {announcementPopup?.title}
          </Typography>
          <ErrorOutlineIcon />
        </Box>
        <Typography fontSize={14} fontWeight={600}>
          {parse(announcementPopup?.body)}
        </Typography>
      </Box>
    </MthModal>
  )
}
