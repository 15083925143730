import { HeadCell } from '@mth/components/SortableTable/SortableTableHeader/types'
import { EnrollmentHeadCellsId, EnrollmentHeadCellsLabel } from '@mth/enums'

export const DAYS_TO_DISPLAY_DROPDOWN = [
  { label: '90 days', value: 90 },
  { label: '180 days', value: 180 },
  { label: '365 days', value: 365 },
]

export const WITHDRAWAL_HEADCELLS: HeadCell[] = [
  {
    id: 'submitted',
    numeric: false,
    disablePadding: true,
    label: 'Submitted',
    cellSize: '10%',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    cellSize: '10%',
  },
  {
    id: 'effective',
    numeric: false,
    disablePadding: true,
    label: 'Effective',
    cellSize: '10%',
  },
  {
    id: 'student',
    numeric: false,
    disablePadding: true,
    label: 'Student',
  },
  {
    id: 'grade',
    numeric: false,
    disablePadding: true,
    label: 'Grade',
    cellSize: '10%',
  },
  {
    id: 'soe',
    numeric: false,
    disablePadding: true,
    label: 'SoE',
    cellSize: '10%',
  },
  {
    id: 'funding',
    numeric: false,
    disablePadding: true,
    label: 'Funding',
    cellSize: '10%',
  },
  {
    id: 'emailed',
    numeric: false,
    disablePadding: true,
    label: 'Emailed',
    cellSize: '10%',
  },
]

export const ANNOUNCEMENT_HEADCELLS: HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Date',
    paddingRight: '50px',
  },
  {
    id: 'subject',
    numeric: false,
    disablePadding: true,
    label: 'Subject',
  },
  {
    id: 'postedBy',
    numeric: false,
    disablePadding: true,
    label: 'Posted By',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    paddingRight: '50px',
  },
]

export const ANNOUNCEMENT_POPUP_HEADCELLS: HeadCell[] = [
  {
    id: 'startDate',
    numeric: false,
    disablePadding: true,
    label: 'Start Date',
  },
  {
    id: 'endDate',
    numeric: false,
    disablePadding: true,
    label: 'End Date',
  },
  {
    id: 'displayTo',
    numeric: false,
    disablePadding: true,
    label: 'Displayed To',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Popup Title',
  },
  {
    id: 'postedBy',
    numeric: false,
    disablePadding: true,
    label: 'Posted By',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    paddingRight: '50px',
  },
]

export const APPLICATION_HEADCELLS: HeadCell[] = [
  {
    id: 'submitted',
    numeric: false,
    disablePadding: true,
    label: 'Submitted',
  },
  {
    id: 'year',
    numeric: false,
    disablePadding: true,
    label: 'Year',
  },
  {
    id: 'student',
    numeric: false,
    disablePadding: true,
    label: 'Student',
  },
  {
    id: 'grade',
    numeric: false,
    disablePadding: true,
    label: 'Grade',
  },
  {
    id: 'sped',
    numeric: false,
    disablePadding: true,
    label: 'SPED',
  },
  {
    id: 'parent',
    numeric: false,
    disablePadding: true,
    label: 'Parent',
  },
  {
    id: 'relation',
    numeric: false,
    disablePadding: true,
    label: 'Relation',
  },
  {
    id: 'verified',
    numeric: false,
    disablePadding: true,
    label: 'Verified',
  },
  {
    id: 'emailed',
    numeric: false,
    disablePadding: true,
    label: 'Emailed',
  },
  {
    id: 'Actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
  },
]

export const ENROLLMENT_SCHEDULE_HEADCELLS: HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'student',
    numeric: false,
    disablePadding: true,
    label: 'Student',
  },
  {
    id: 'grade',
    numeric: false,
    disablePadding: true,
    label: 'Grade',
  },
  {
    id: 'parent',
    numeric: false,
    disablePadding: true,
    label: 'Parent',
  },
  {
    id: 'diploma',
    numeric: false,
    disablePadding: true,
    label: 'Diploma',
  },
  {
    id: 'emailed',
    numeric: false,
    disablePadding: true,
    label: 'Emailed',
  },
]

export const ENROLLMENT_SCHOOL_HEADCELLS: HeadCell[] = [
  {
    id: 'student',
    numeric: false,
    disablePadding: true,
    label: 'Student',
  },
  {
    id: 'grade',
    numeric: false,
    disablePadding: true,
    label: 'Grade',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: true,
    label: 'City',
  },
  {
    id: 'parent',
    numeric: false,
    disablePadding: true,
    label: 'Parent',
  },
  {
    id: 'currentSOE',
    numeric: false,
    disablePadding: true,
    label: '2021-22 SoE',
  },
  {
    id: 'previousSOE',
    numeric: false,
    disablePadding: true,
    label: '2020-21 SoE',
  },
]

export const EMAIL_RECORDS_HEADCELLS: HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'to',
    numeric: false,
    disablePadding: true,
    label: 'To',
  },
  {
    id: 'email_template',
    numeric: false,
    disablePadding: true,
    label: 'Email Template',
  },
  {
    id: 'subject',
    numeric: false,
    disablePadding: true,
    label: 'Subject',
  },
  {
    id: 'from',
    numeric: false,
    disablePadding: true,
    label: 'From',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
]

//should be deleted when FF is removed release-epic-3733-story-4792
export const ENROLLMENT_PACKET_HEADCELLS: HeadCell[] = [
  {
    id: EnrollmentHeadCellsId.SUBMITTED,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.DATE,
  },
  {
    id: EnrollmentHeadCellsId.STATUS,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.STATUS,
  },
  {
    id: EnrollmentHeadCellsId.DEADLINE,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.DEADLINE,
  },
  {
    id: EnrollmentHeadCellsId.STUDENT,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.STUDENT,
  },
  {
    id: EnrollmentHeadCellsId.GRADE,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.GRADE,
  },
  {
    id: EnrollmentHeadCellsId.PARENT,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.PARENT,
  },
  {
    id: EnrollmentHeadCellsId.STUDENT_STATUS,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.PACKET,
  },
  {
    id: EnrollmentHeadCellsId.EMAILED,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.EMAILED,
  },
]

export const ENROLLMENT_PACKET_HEADCELLS_UPDATED: HeadCell[] = [
  {
    id: EnrollmentHeadCellsId.LAST_UPDATED,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.LAST_UPDATED,
  },
  {
    id: EnrollmentHeadCellsId.STATUS,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.STATUS,
  },
  {
    id: EnrollmentHeadCellsId.LAST_SUBMITTED,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.LAST_SUBMITTED,
  },
  {
    id: EnrollmentHeadCellsId.DEADLINE,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.DEADLINE,
  },
  {
    id: EnrollmentHeadCellsId.STUDENT,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.STUDENT,
  },
  {
    id: EnrollmentHeadCellsId.GRADE,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.GRADE,
  },
  {
    id: EnrollmentHeadCellsId.PARENT,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.PARENT,
  },
  {
    id: EnrollmentHeadCellsId.STUDENT_STATUS,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.PACKET,
  },
  {
    id: EnrollmentHeadCellsId.EMAILED,
    numeric: false,
    disablePadding: true,
    label: EnrollmentHeadCellsLabel.EMAILED,
  },
]

export const SCHOOL_PARTNER_HEADCELLS: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Partner Name',
  },
  {
    id: 'abbreviation',
    numeric: false,
    disablePadding: true,
    label: 'Abbreviation',
  },
]

export const USERS_HEADCELLS: HeadCell[] = [
  {
    id: 'user_id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'first_name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'level',
    numeric: false,
    disablePadding: true,
    label: 'Level',
  },
  {
    id: 'last_login',
    numeric: false,
    disablePadding: true,
    label: 'Last Login',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'can_emulate',
    numeric: false,
    disablePadding: true,
    label: 'Can Emulate',
  },
]
