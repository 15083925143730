export enum QuestionTypeSlug {
  PARENT_PREFERRED_FIRST_NAME = 'parent_preferred_first_name',
  PARENT_PREFERRED_LAST_NAME = 'parent_preferred_last_name',
  PARENT_FIRST_NAME = 'parent_first_name',
  PARENT_LAST_NAME = 'parent_last_name',
  PACKET_SECONDARY_CONTACT_FIRST = 'packet_secondary_contact_first',
  PACKET_SECONDARY_CONTACT_LAST = 'packet_secondary_contact_last',
  PARENT_PHONE_NUMBER = 'parent_phone_number',
  PARENT_RECIEVE_TEXT = 'parent_recieve_text',
  PARENT_EMAIL = 'parent_email',
  STUDENT_FIRST_NAME = 'student_first_name',
  STUDENT_LAST_NAME = 'student_last_name',
  STUDENT_MIDDLE_NAME = 'student_middle_name',
  STUDENT_PREFERRED_FIRST_NAME = 'student_preferred_first_name',
  STUDENT_PREFERRED_LAST_NAME = 'student_preferred_last_name',
  STUDENT_GRADE_LEVEL = 'student_grade_level',
  ADDRESS_STREET = 'address_street',
  ADDRESS_STREET2 = 'address_street2',
  ADDRESS_CITY = 'address_city',
  ADDRESS_STATE = 'address_state',
  ADDRESS_ZIP = 'address_zip',
  STUDENT_DATE_OF_BIRTH = 'student_date_of_birth',
  STUDENT_GENDER = 'student_gender',
  ADDRESS_SCHOOL_DISTRICT = 'address_school_district',
  ADDRESS_COUNTY_ID = 'address_county_id',
  ADDRESS_COUNTRY_ID = 'address_country_id',
  PARENT_EMAILCONFIRM = 'parent_emailConfirm',
  STUDENT_EMAIL = 'student_email',
  STUDENT_EMAILCONFIRM = 'student_emailConfirm',
  PROGRAM_YEAR = 'program_year',
  META_SPECIAL_EDUCATION = 'meta_special_education',
  SCHOOL_OF_ENROLLMENT_PREFERENCE = 'meta_soe_preference',
  STATE = 'state',
  META_PARENT_LEGAL_NAME = 'meta_parentlegalname',
  SECONDARY_EMAIL = 'packet_secondary_email',
  STUDENT = 'student',
}
