import { StudentsGradeLevel } from '../enums/grade-levels.enum'
export const GradeLevels: string[] = [
  // @ts-ignore
  StudentsGradeLevel.ALL,
  // @ts-ignore
  StudentsGradeLevel.PRE_KINDERGARTEN,
  // @ts-ignore
  StudentsGradeLevel.KINDERGARTEN,
  // @ts-ignore
  StudentsGradeLevel.FIRST,
  // @ts-ignore
  StudentsGradeLevel.SECOND,
  // @ts-ignore
  StudentsGradeLevel.THIRD,
  // @ts-ignore
  StudentsGradeLevel.FOURTH,
  // @ts-ignore
  StudentsGradeLevel.FIFTH,
  // @ts-ignore
  StudentsGradeLevel.SIXTH,
  // @ts-ignore
  StudentsGradeLevel.SEVENTH,
  // @ts-ignore
  StudentsGradeLevel.EIGHTH,
  // @ts-ignore
  StudentsGradeLevel.NINTH,
  // @ts-ignore
  StudentsGradeLevel.TENTH,
  // @ts-ignore
  StudentsGradeLevel.ELEVENTH,
  // @ts-ignore
  StudentsGradeLevel.TWELFTH,
]
