import React from 'react'
import { Grid } from '@mui/material'
import { MthBulletEditor } from '@mth/components/MthBulletEditor'
import { EMPTY_STRING } from '@mth/constants'
import { Subtitle } from '@mth/core/components/shared/Subtitle'
import { TEXT_SIZE } from '@mth/enums'
import { subtitle } from './Subtitle.enum'
import { FamilyNotesAndStudentNotesProps, ShareTextAreaProps } from './types'

export const ShareTextArea: React.FC<ShareTextAreaProps> = ({
  subtittleStyle,
  textFieldStyle,
  testId,
  notes,
  setNotes,
  subtitle,
  editable,
  isEditedByExternal,
}) => {
  return (
    <Grid item xs={6}>
      <Subtitle fontWeight='700' size={TEXT_SIZE.LARGE} textAlign='left' sx={subtittleStyle}>
        {subtitle}
      </Subtitle>
      <MthBulletEditor
        testId={testId}
        classStyle={textFieldStyle}
        readOnly={!editable}
        value={notes || EMPTY_STRING}
        maxHeight='145px'
        height='145px'
        setValue={setNotes}
        hideToolbar
        isEditedByExternal={isEditedByExternal}
      />
    </Grid>
  )
}

export const FamilyNotesAndStudentNotes: React.FC<FamilyNotesAndStudentNotesProps> = ({
  subtittleStyle,
  textFieldStyle,
  familyNotesTestId,
  studentNotesTestId,
  notes,
  setNotes,
  studentNotes,
  setStudentNotes,
  isFamilyNotesEditable,
  isEditedByExternal,
}) => {
  return (
    <>
      <ShareTextArea
        subtittleStyle={subtittleStyle}
        textFieldStyle={textFieldStyle}
        testId={familyNotesTestId}
        notes={notes}
        setNotes={setNotes}
        subtitle={subtitle.FAMILY_NOTES}
        editable={isFamilyNotesEditable}
      ></ShareTextArea>
      <ShareTextArea
        subtittleStyle={subtittleStyle}
        textFieldStyle={textFieldStyle}
        testId={studentNotesTestId}
        notes={studentNotes}
        setNotes={setStudentNotes}
        subtitle={subtitle.STUDENT_NOTES}
        editable={!isFamilyNotesEditable}
        isEditedByExternal={isEditedByExternal}
      ></ShareTextArea>
    </>
  )
}
