import { MthColor } from '@mth/enums'
import { BANNER_HEIGHT } from './AutoUpdateBanner'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStyles = (isMobile: boolean) => ({
  container: {
    background: MthColor.TANGERINE,
    marginTop: isMobile ? '80px' : '0px',
    display: 'flex',
    height: isMobile ? '120px' : `${BANNER_HEIGHT}px`,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 5px',
    marginLeft: 2,
    color: MthColor.WHITE,
    fontWeight: isMobile ? null : 700,
  },
  buttonContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 2 },
  button: {
    background: MthColor.WHITE,
    color: 'black',
    borderRadius: '15px',
    '&:hover': {
      background: '#000',
      color: '#fff',
    },
    paddingY: 1,
    paddingX: 4,
  },
})
