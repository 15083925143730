export enum StatusChangeModalTitle {
  WITHDRAW = 'Withdraw',
  ACTIVE = 'Reinstate',
  INACTIVE = 'Change Status to Inactive',
}

export enum StatusChangeModalDescription {
  WITHDRAW = 'How would you like to proceed with this withdraw?',
  ACTIVE = 'How would you like to proceed with reinstating this student?',
  INACTIVE = 'How would you like to proceed with this status change?',
}

export enum StatusChangeModalConfirmText {
  WITHDRAW = 'Withdraw',
  ACTIVE = 'Reinstate',
  INACTIVE = 'Change Status',
}
