import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md'
import { EMPTY_STRING } from '@mth/constants'
import { Packet } from '@mth/screens/HomeroomStudentProfile/Student/types'
import { VaccinesInfoHeader } from './Header'
import ImmunizationItem from './ImmunizationItem'
import { StudentImmunization } from './types'
import { StudentImmunizationsQuery } from '../../services'
import { getValidGrade } from '../helpers'
import { EnrollmentPacketFormType } from '../types'

type EnrollmentVaccineInfoProps = {
  packet: Packet
}

export const EnrollmentPacketVaccineView: React.FC<EnrollmentVaccineInfoProps> = ({ packet }) => {
  const { setValue } = useFormContext()
  const student = packet?.student
  const { watch } = useFormContext<EnrollmentPacketFormType>()
  const immunizations = watch('immunizations')

  const schoolYearId = Number(packet.school_year_id)

  const { loading: immLoading, data: immData } = useQuery<{ StudentImmunizations: StudentImmunization[] }>(
    StudentImmunizationsQuery,
    {
      variables: {
        student_id: Number(student?.student_id || 0),
        school_year_id: schoolYearId,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  )

  useEffect(() => {
    if (!immLoading && immData?.StudentImmunizations && !immunizations.length && immData?.StudentImmunizations.length) {
      const levels = student?.grade_levels
      const grade = getValidGrade(levels?.length ? levels[levels?.length - 1]?.grade_level : EMPTY_STRING)

      setValue(
        'immunizations',
        immData?.StudentImmunizations.map((v) => {
          if (v.value) return v
          const max_grade = getValidGrade(v?.immunization?.max_grade_level || '')
          const min_grade = getValidGrade(v?.immunization?.min_grade_level || '')
          const isNA = grade < min_grade || grade > max_grade
          return {
            ...v,
            value: isNA ? 'NA' : '',
          }
        }),
      )
    }
  }, [immData, immLoading, immunizations])

  return <VaccineView />
}

const VaccineView: React.FC = () => {
  const [showImmunizations, setShowImmunizations] = useState<boolean>(true)
  const { watch } = useFormContext<EnrollmentPacketFormType>()
  const immunizations = watch('immunizations')

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {showImmunizations ? (
          <VaccinesInfoHeader />
        ) : (
          <Typography
            sx={{
              width: '25rem',
              textAlign: 'end',
              fontWeight: 'bold',
              fontSize: 'large',
              paddingRight: '10px',
            }}
          >
            Vaccines
          </Typography>
        )}
        <Box onClick={() => setShowImmunizations(!showImmunizations)}>
          {showImmunizations ? <MdArrowDropUp size='70' height='30px' /> : <MdArrowDropDown size='70' height='30px' />}
        </Box>
      </Box>

      {showImmunizations && (
        <Box display={'flex'}>
          <Box sx={{ width: '250px' }}>
            {immunizations &&
              immunizations
                .slice(0, Math.ceil(immunizations.length / 2))
                .map((it) => <ImmunizationItem key={it.immunization_id} item={it} />)}
          </Box>
          <Box sx={{ width: '250px' }}>
            {immunizations &&
              immunizations
                .slice(Math.ceil(immunizations.length / 2), immunizations.length)
                .map((it) => <ImmunizationItem key={it.immunization_id} item={it} />)}
          </Box>
        </Box>
      )}
    </>
  )
}
