import React from 'react'
import { Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import { EMPTY_STRING } from '@mth/constants'
import { TagType } from '@mth/enums'
import { DisplayHTMLProps } from './DisplayHTML.types'

export const DisplayHTML: React.FC<DisplayHTMLProps> = ({
  text = EMPTY_STRING,
  dataTestId = EMPTY_STRING,
  tagType = TagType.DIV,
  allowIframe = false,
  removeMarginBlock = false,
}) => {
  const sanitize = (text: string, allowIframe: boolean) => {
    if (allowIframe)
      return DOMPurify.sanitize(text, {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['frameborder', 'target'],
      })

    return DOMPurify.sanitize(text, {
      ADD_ATTR: ['target'],
    })
  }

  return (
    <Typography
      component={tagType === TagType.PARAGRAPH ? 'p' : 'span'}
      data-testid={dataTestId}
      dangerouslySetInnerHTML={{
        __html: sanitize(text, allowIframe),
      }}
      sx={
        removeMarginBlock
          ? {
              '> p': {
                marginBlockStart: 0,
              },
            }
          : {}
      }
    />
  )
}
