import { createContext, useContext } from 'react'
import { CurrentSchoolYearContextType } from './types'

export const CurrentSchoolYearContext = createContext<CurrentSchoolYearContextType | undefined>(undefined)

export const useCurrentSchoolYearContext = () => {
  const context = useContext(CurrentSchoolYearContext)
  if (!context) {
    throw new Error('useCurrentSchoolYearContext must be used within a CurrentSchoolYearProvider')
  }
  return context
}
