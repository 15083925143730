import { MthColor } from '@mth/enums'

export const customModalClasses = {
  cancelBtn: {
    minWidth: '135px',
    height: '36px',
    borderRadius: '40px',
    whiteSpace: 'nowrap',
    background: MthColor.LIGHTGRAY,
    color: MthColor.SYSTEM_01,
  },
  confirmBtn: {
    minWidth: '135px',
    height: '36px',
    borderRadius: '40px',
    whiteSpace: 'nowrap',
    background: MthColor.BUTTON_LINEAR_GRADIENT_DARK,
    color: MthColor.WHITE,
  },
  cancelIcon: {
    width: '37px',
    minWidth: '37px',
    height: '35x',
    minHeight: '35px',
    borderRadius: 2,
    background: MthColor.BUTTON_LINEAR_GRADIENT_DARK,
    color: MthColor.WHITE,
  },
}
