import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Box, Modal, TextField, Typography } from '@mui/material'
import { isNil } from 'lodash'
import { MthNumberInput } from '@mth/components/MthNumberInput'
import { EMPTY_STRING } from '@mth/constants'
import { DropDown } from '@mth/core/components/shared/DropDown/DropDown'
import {
  CourseType,
  MthTitle,
  ReduceFunds,
  ReimbursementFormType,
  ReimbursementRequestStatus,
  ReimbursementType,
  ReimbursementValues,
  ScheduleStatus,
} from '@mth/enums'
import { SchedulePeriod } from '@mth/graphql/models/schedule-period'
import { saveReimbursementRequestMutation } from '@mth/graphql/mutation/reimbursement-request'
import { getPeriodsForReimbursements } from '@mth/graphql/queries/schedule-period'
import { SchoolYearMenuItem } from '@mth/hooks'
import { DropDownItem } from '@mth/models'
import { arrayToString } from '@mth/utils'
import { getActivePendingStudentsBySchoolYear } from './services'
import { useStyles } from './styles'
import { ErrorControl } from './types'
import { SaveCancelComponent } from '../../Curriculum/CourseCatalog/Components/SaveCancelComponent'
import { Reimbursement } from '../ReimbursementModal/types'

interface DirectDeductionModalProps {
  showModal: (value: boolean) => void
  refetch: () => void
  selectedYearId: number
  selectedYear: SchoolYearMenuItem
  anyStudentIdFromParent: number
  isEditMode?: boolean
  reimbursementToEdit?: Reimbursement
}

const DOLLAR = '$'
export const DirectDeductionModal: React.FC<DirectDeductionModalProps> = ({
  showModal,
  selectedYearId,
  selectedYear,
  anyStudentIdFromParent,
  refetch,
  isEditMode = true,
  reimbursementToEdit,
}) => {
  const [studentList, setStudentList] = useState<DropDownItem[]>([])
  const [selectedStudent, setSelectedStudent] = useState<number>()
  const [amount, setAmount] = useState<number>()
  const [typeList, setTypeList] = useState<DropDownItem[]>([])
  const [periodsItems, setPeriodsItems] = useState<DropDownItem[]>([])
  const [typeSelected, setTypeSelected] = useState<string>()
  const [periodSelected, setPeriodSelected] = useState<string>()
  const [additionalInformation, setAdditionalInformation] = useState<string>('')
  const [error, setError] = useState<ErrorControl>({ student: false, amount: false, type: false, period: false })

  const [saveRequest] = useMutation(saveReimbursementRequestMutation)

  const { loading, data } = useQuery(getActivePendingStudentsBySchoolYear, {
    variables: {
      schoolYearId: selectedYearId,
      studentId: anyStudentIdFromParent,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const auxTypes: DropDownItem[] = []
    if (selectedYear.require_software)
      auxTypes.push({ value: ReimbursementFormType.REQUIRED_SOFTWARE, label: MthTitle.REQUIRED_SOFTWARE })
    if (
      selectedYear.reimbursements === ReduceFunds.SUPPLEMENTAL ||
      selectedYear.direct_orders === ReduceFunds.SUPPLEMENTAL
    ) {
      auxTypes.push({
        label: MthTitle.SUPPLEMENTAL_LEARNING_FUNDS,
        value: ReimbursementFormType.SUPPLEMENTAL,
      })
    } else if (
      selectedYear.reimbursements === ReduceFunds.TECHNOLOGY ||
      selectedYear.direct_orders === ReduceFunds.TECHNOLOGY
    ) {
      auxTypes.push({
        label: MthTitle.TECHNOLOGY_ALLOWANCE,
        value: ReimbursementFormType.TECHNOLOGY,
      })
      if (selectedYear.ScheduleBuilder?.third_party_provider)
        auxTypes.push({
          label: MthTitle.THIRD_PARTY_PROVIDER,
          value: ReimbursementFormType.THIRD_PARTY_PROVIDER,
        })
      if (selectedYear.ScheduleBuilder?.custom_built)
        auxTypes.push({
          label: MthTitle.CUSTOM_BUILT,
          value: ReimbursementFormType.CUSTOM_BUILT,
        })
    }
    setTypeList([...auxTypes])
  }, [selectedYear])

  useEffect(() => {
    if (!loading) {
      setStudentList(
        data.getActivePendingStudentsBySchoolYear.map((item: { student: string; student_id: number }) => ({
          label: item.student,
          value: item.student_id,
        })),
      )
    }
  }, [loading])

  const [getStudentPeriods, { loading: periodsLoading, data: periodsData }] = useLazyQuery(
    getPeriodsForReimbursements,
    {
      fetchPolicy: 'network-only',
    },
  )

  useEffect(() => {
    if (selectedStudent && selectedStudent !== 0 && selectedYearId) {
      getStudentPeriods({
        variables: {
          schoolYearId: selectedYearId,
          studentId: selectedStudent,
        },
      })
    }
  }, [selectedStudent, selectedYearId])

  useEffect(() => {
    if (!periodsLoading && periodsData?.getPeriodsForReimbursements) {
      const schedulePeriods: SchedulePeriod[] = periodsData?.getPeriodsForReimbursements as SchedulePeriod[]
      if (schedulePeriods?.length && schedulePeriods?.at(0)?.Schedule?.status === ScheduleStatus.ACCEPTED) {
        let mergedPeriods: string[] = []
        if (selectedYear?.ReimbursementSetting?.is_merged_periods && selectedYear.ReimbursementSetting.merged_periods) {
          mergedPeriods = selectedYear.ReimbursementSetting.merged_periods.includes(',')
            ? selectedYear.ReimbursementSetting.merged_periods?.split(',')
            : [selectedYear.ReimbursementSetting.merged_periods]
        }

        const newPeriods: number[] = []
        const newTitles: string[] = []
        const newPeriodIds: number[] = []
        const tempCustomPeriods: DropDownItem[] = []
        const tempPartyPeriods: DropDownItem[] = []
        const tempRequiredSoftwarePeriods: DropDownItem[] = []

        schedulePeriods?.map((schedulePeriod) => {
          const isSoftwareReimbursement =
            schedulePeriod?.Title?.software_reimbursement || schedulePeriod?.Course?.software_reimbursement
          const isCustomBuiltCourse = CourseType.CUSTOM_BUILT === schedulePeriod.course_type
          const isThirdPartyProvider = CourseType.THIRD_PARTY_PROVIDER === schedulePeriod.course_type
          const periodLabel = `${MthTitle.PERIOD} ${schedulePeriod?.Period?.period} - ${schedulePeriod?.Title?.name}`
          const periodValue = schedulePeriod?.schedule_period_id

          if (typeSelected === ReimbursementFormType.REQUIRED_SOFTWARE) {
            if (isSoftwareReimbursement) {
              tempRequiredSoftwarePeriods.push({ label: periodLabel, value: periodValue })
            }
          } else {
            if (
              schedulePeriod?.Period?.period &&
              mergedPeriods.includes(`${schedulePeriod?.Period?.period}`) &&
              isCustomBuiltCourse
            ) {
              newPeriods.push(schedulePeriod?.Period?.period)
              newTitles.push(`${schedulePeriod?.Title?.name}`)
              newPeriodIds.push(schedulePeriod?.schedule_period_id)
            } else if (isCustomBuiltCourse) {
              tempCustomPeriods.push({ label: periodLabel, value: periodValue })
            } else if (isThirdPartyProvider) {
              tempPartyPeriods.push({ label: periodLabel, value: periodValue })
            }
          }
        })
        if (newPeriodIds.length > 0) {
          tempCustomPeriods.push({
            label: `${MthTitle.PERIOD} ${arrayToString(newPeriods)} - ${arrayToString(newTitles)}`,
            value: newPeriodIds.join(','),
          })
        }
        if (typeSelected === ReimbursementFormType.REQUIRED_SOFTWARE) {
          setPeriodsItems(tempRequiredSoftwarePeriods)
        } else {
          setPeriodsItems(typeSelected === ReimbursementFormType.CUSTOM_BUILT ? tempCustomPeriods : tempPartyPeriods)
        }
      }
    }
  }, [periodsLoading, periodsData, typeSelected])

  const handleCancel = () => {
    showModal(false)
  }

  const handleChangeStudent = (value: string | number) => {
    setSelectedStudent(Number(value))
  }

  useEffect(() => {
    if (isEditMode && reimbursementToEdit) {
      setSelectedStudent(reimbursementToEdit.student_id)
      setAmount(Number(reimbursementToEdit.amount.replace(DOLLAR, EMPTY_STRING)))
      setPeriodSelected(reimbursementToEdit.periods)
      setAdditionalInformation(reimbursementToEdit.additional_information ?? '')
      setTypeSelected(ReimbursementValues[reimbursementToEdit.category])
      getStudentPeriods({
        variables: {
          schoolYearId: selectedYearId,
          studentId: reimbursementToEdit.student_id,
        },
      })
    }
  }, [isEditMode, reimbursementToEdit])

  const handleSave = async () => {
    let auxPeriodSelected = ''
    const auxError: ErrorControl = { student: false, type: false, amount: false, period: false }
    if (!selectedStudent) auxError.student = true
    if (!typeSelected) auxError.type = true
    if (!amount || amount === 0) auxError.amount = true
    if (typeSelected !== ReduceFunds.SUPPLEMENTAL && typeSelected !== ReduceFunds.TECHNOLOGY) {
      if (
        !periodSelected ||
        periodsItems.filter((item) => !isNil(item.value) && item.value.toString() === periodSelected).length === 0
      )
        auxError.period = true
      else auxPeriodSelected = periodSelected
    } else setPeriodSelected('')
    setError({ ...auxError })

    if (auxError.amount || auxError.period || auxError.student || auxError.type) return

    const response = await saveRequest({
      variables: {
        requestInput: {
          reimbursement_request_id: isEditMode ? reimbursementToEdit?.reimbursement_request_id : null,
          SchoolYearId: selectedYearId,
          StudentId: selectedStudent,
          form_type: typeSelected,
          reimbursement_type: ReimbursementType.DIRECT_DEDUCTION,
          periods: auxPeriodSelected,
          status: isEditMode ? ReimbursementRequestStatus.REPROCESSED : ReimbursementRequestStatus.PROCESSED,
          total_amount: amount,
          additional_information: additionalInformation,
        },
      },
    })

    if (response.data.createOrUpdateReimbursementRequest.reimbursement_request_id) {
      refetch()
      showModal(false)
    }
  }

  return (
    <>
      <Modal open={true}>
        <Box sx={useStyles.customModal}>
          <Box display='flex' flexDirection='column'>
            <DropDown
              dropDownItems={studentList}
              labelTop={true}
              defaultValue={isEditMode && reimbursementToEdit ? reimbursementToEdit?.student_id : EMPTY_STRING}
              disabled={isEditMode}
              placeholder={MthTitle.STUDENT}
              setParentValue={handleChangeStudent}
              size='medium'
              error={{
                error: error.student,
                errorMsg: MthTitle.REQUIRED,
              }}
            />
            <Box display='flex' flexDirection='row' justifyContent='space-between' mb={2} mt={2}>
              <MthNumberInput
                numberType='price'
                label={MthTitle.AMOUNT}
                placeholder=''
                fullWidth
                InputLabelProps={{ shrink: true }}
                className='MthFormField'
                value={
                  isEditMode && reimbursementToEdit
                    ? Number(reimbursementToEdit?.amount.replace('$', ''))
                    : Number(Number(amount || 0)?.toFixed(2))
                }
                onChangeValue={(value: number | null) => {
                  setAmount(value || 0)
                }}
                sx={{ width: '212px' }}
                helperText={error.amount ? MthTitle.REQUIRED : ''}
                error={error.amount}
              />
              <DropDown
                dropDownItems={typeList}
                labelTop={true}
                placeholder={MthTitle.TYPE}
                defaultValue={
                  isEditMode && reimbursementToEdit ? ReimbursementValues[reimbursementToEdit.category] : undefined
                }
                setParentValue={(value) => {
                  setTypeSelected(value.toString())
                }}
                size='medium'
                sx={{ width: '212px' }}
                error={{
                  error: error.type,
                  errorMsg: MthTitle.REQUIRED,
                }}
              />
            </Box>
            <Box>
              {selectedYear.schedule === true &&
                typeSelected !== ReduceFunds.SUPPLEMENTAL &&
                typeSelected !== ReduceFunds.TECHNOLOGY && (
                  <Box mb={1.5}>
                    <DropDown
                      dropDownItems={periodsItems}
                      labelTop={true}
                      placeholder={MthTitle.PERIOD}
                      defaultValue={isEditMode && reimbursementToEdit ? reimbursementToEdit?.periods : undefined}
                      setParentValue={(value) => {
                        setPeriodSelected(value.toString())
                      }}
                      size='medium'
                      sx={{ width: '100%' }}
                      error={{
                        error: error.period,
                        errorMsg: MthTitle.REQUIRED,
                      }}
                    />
                  </Box>
                )}
              <Typography sx={{ fontSize: 18, fontWeight: 700, mb: 1.5 }}>{MthTitle.ADDITIONAL_INFORMATION}</Typography>
              <TextField
                multiline
                fullWidth
                className='MthFormField'
                rows={14}
                sx={{ maxHeight: '360px', mb: 4.5 }}
                value={additionalInformation}
                onChange={(e) => setAdditionalInformation(e.target.value)}
              />
            </Box>
          </Box>
          <SaveCancelComponent isSubmitted={false} handleCancel={handleCancel} handleSave={handleSave} />
        </Box>
      </Modal>
    </>
  )
}
