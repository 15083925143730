import React, { useEffect, useState, ReactNode } from 'react'
import { useCurrentSchoolYearByRegionId } from '@mth/hooks'
import { CurrentSchoolYearContext } from './CurrentSchoolYearContext'
import { CurrentSchoolYearContextType } from './types'

interface CurrentSchoolYearProviderProps {
  regionId: number
  children: ReactNode
}

export const CurrentSchoolYearProvider: React.FC<CurrentSchoolYearProviderProps> = ({ regionId, children }) => {
  const [schoolYearData, setSchoolYearData] = useState<CurrentSchoolYearContextType['schoolYear']>(null)
  const { data: schoolYear } = useCurrentSchoolYearByRegionId(regionId)

  // Save the data to state when it's available
  useEffect(() => {
    if (schoolYear) {
      setSchoolYearData(schoolYear)
    }
  }, [schoolYear])

  return (
    <CurrentSchoolYearContext.Provider value={{ schoolYear: schoolYearData }}>
      {children}
    </CurrentSchoolYearContext.Provider>
  )
}
