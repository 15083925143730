import { MthTitle } from './title.enum'

export enum ReimbursementFormType {
  CUSTOM_BUILT = 'CUSTOM_BUILT',
  TECHNOLOGY = 'TECHNOLOGY',
  THIRD_PARTY_PROVIDER = 'THIRD_PARTY_PROVIDER',
  SUPPLEMENTAL = 'SUPPLEMENTAL',
  REQUIRED_SOFTWARE = 'REQUIRED_SOFTWARE',
  NONE = 'NONE',
}

export const ReimbursementValues: { [key: string]: string } = {
  [MthTitle.CUSTOM_BUILT]: ReimbursementFormType.CUSTOM_BUILT,
  [MthTitle.TECHNOLOGY_ALLOWANCE]: ReimbursementFormType.TECHNOLOGY,
  [MthTitle.THIRD_PARTY_PROVIDER]: ReimbursementFormType.THIRD_PARTY_PROVIDER,
  [MthTitle.SUPPLEMENTAL_LEARNING_FUNDS]: ReimbursementFormType.SUPPLEMENTAL,
  [MthTitle.REQUIRED_SOFTWARE]: ReimbursementFormType.REQUIRED_SOFTWARE,
}

export const ReimbursementKeyValues: { [key: string]: string } = {
  [ReimbursementFormType.CUSTOM_BUILT]: MthTitle.CUSTOM_BUILT,
  [ReimbursementFormType.TECHNOLOGY]: MthTitle.TECHNOLOGY_ALLOWANCE,
  [ReimbursementFormType.THIRD_PARTY_PROVIDER]: MthTitle.THIRD_PARTY_PROVIDER,
  [ReimbursementFormType.SUPPLEMENTAL]: MthTitle.SUPPLEMENTAL_LEARNING_FUNDS,
  [ReimbursementFormType.REQUIRED_SOFTWARE]: MthTitle.REQUIRED_SOFTWARE,
}
