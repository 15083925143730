export const defaultSelectedColumns: string[] = ['studentLegalName', 'grade', 'parentLegalName']

export enum ColumnValueEnum {
  STUDENT_LEGAL_NAME = 'studentLegalName',
  STUDENT_PREF_NAME = 'studentPreferredName',
  STUDENT_UNIQUE_ID = 'studentUniqueId',
  PARENT_LEGAL_NAME = 'parentLegalName',
  PARENT_PREF_NAME = 'parentPreferredName',
  PARENT_UNIQUE_ID = 'parentUniqueId',
  OPT_OUT = 'opt_out',
}

export enum ColumnLabelEnum {
  STUDENT_UNIQUE_ID = 'Student Unique Identifier',
  PARENT_UNIQUE_ID = 'Parent Unique Identifier',
}

export const columnsValues = [
  { label: 'Student Legal Name', value: ColumnValueEnum.STUDENT_LEGAL_NAME, columnName: 'Student Legal' },
  { label: 'Student Preferred Name', value: ColumnValueEnum.STUDENT_PREF_NAME, columnName: 'Student Pref Name' },
  { label: 'Grade', value: 'grade', columnName: 'Grade' },
  { label: 'SPED', value: 'sped', columnName: 'SPED' },
  { label: 'Diploma-seeking Status', value: 'dsStatus', columnName: 'Diploma status' },
  { label: 'Program Year', value: 'programYear', columnName: 'Year' },
  { label: 'Status', value: 'statusPY', columnName: 'Status' },
  { label: 'Status Date', value: 'statusDate', columnName: 'Status Date' },
  { label: 'School of Enrollment', value: 'soe', columnName: 'SoE' },
  { label: 'Student Gender', value: 'gender', columnName: 'Gender' },
  { label: 'Student DOB', value: 'studentDOB', columnName: 'Student DOB' },
  { label: 'Student Email', value: 'studentEmail', columnName: 'Student Email' },
  {
    label: ColumnLabelEnum.STUDENT_UNIQUE_ID,
    value: ColumnValueEnum.STUDENT_UNIQUE_ID,
    columnName: 'Student Unique Identifier',
  },
  { label: 'Parent Legal Name', value: ColumnValueEnum.PARENT_LEGAL_NAME, columnName: 'Parent Legal' },
  { label: 'Parent Preferred Name', value: ColumnValueEnum.PARENT_PREF_NAME, columnName: 'Parent Pref Name' },
  { label: 'Phone Number', value: 'phone', columnName: 'Number' },
  { label: 'Parent Email', value: 'parentEmail', columnName: 'Parent Email' },
  {
    label: ColumnLabelEnum.PARENT_UNIQUE_ID,
    value: ColumnValueEnum.PARENT_UNIQUE_ID,
    columnName: 'Parent Unique Identifier',
  },
  { label: 'Street 1', value: 'street1', columnName: 'Street 1' },
  { label: 'Street 2', value: 'street2', columnName: 'Street 2' },
  { label: 'City', value: 'city', columnName: 'City' },
  { label: 'Zip Code', value: 'zipCode', columnName: 'Zip Code' },
  { label: 'County', value: 'county', columnName: 'County' },
  { label: 'School District', value: 'schoolDistrict', columnName: 'School District' },
  { label: 'ITR Answer', value: 'intentToReenrollAnswer', columnName: 'ITR Answer' },
  { label: 'ITR Status', value: 'intentToReenrollStatus', columnName: 'ITR Status' },
  { label: 'ITR Reason', value: 'intentToReenrollReason', columnName: 'ITR Reason' },
]

//when FF release-epic-1766-story-5524 is removed, this object should be included on columnValues and remove this const
export const columnsValuesWithSMSOptIn = [
  ...columnsValues,
  { label: 'SMS Opt-in Response', value: 'SMSOptIn', columnName: 'SMS Opt-in Response' },
]
