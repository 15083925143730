import { useState, useContext, useMemo, useEffect } from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import { isEmpty, sortBy } from 'lodash'
import { DropDownItem } from '@mth/core/components/shared/DropDown/types'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { ReduceFunds } from '../../enums/reduce-funds.enum'
import { ScheduleBuilder } from '../../models/schedule-builder.model'

export const getSchoolYearsQuery = gql`
  query GetSchoolYearsByRegionId($regionId: ID!) {
    getSchoolYearsByRegionId(region_id: $regionId) {
      school_year_id
      date_begin
      date_end
      midyear_application
      grades
      IsCurrentYear
      special_ed
      special_ed_options
      diploma_seeking
      reimbursements
      testing_preference
      testing_preference_title
      testing_preference_description
      opt_out_form_title
      opt_out_form_description
      schedule
      midyear_application_open
      midyear_application_close
      midyear_schedule_open
      midyear_schedule_close
      second_semester_open
      second_semester_close
      schedule_builder_open
      schedule_builder_close
      homeroom_resource_open
      homeroom_resource_close
      direct_order_open
      direct_order_close
      reimbursement_open
      reimbursement_close
      custom_built_open
      custom_built_close
      reimbursement_custom_built_open
      reimbursement_custom_built_close
      require_software_open
      require_software_close
      third_party_open
      third_party_close
      mid_direct_order_open
      mid_direct_order_close
      mid_reimbursement_open
      mid_reimbursement_close
      mid_custom_built_open
      mid_custom_built_close
      mid_reimbursement_custom_built_open
      mid_reimbursement_custom_built_close
      mid_require_software_open
      mid_require_software_close
      mid_third_party_open
      mid_third_party_close
      intent_to_re_enroll_open
      intent_to_re_enroll_close
      notify_of_withdraw
      direct_orders
      require_software
      learning_logs_first_second_semesters
      first_semester_ends
      second_semester_starts
      learning_logs_eoy_deadline
      grade_transition_graduated
      grade_transition_completed
      enable_grade_transition
      full_application_open
      full_application_close
      ScheduleBuilder {
        max_num_periods
        custom_built
        third_party_provider
      }
      ReimbursementSetting {
        is_merged_periods
        merged_periods
      }
      participation_tracking
    }
  }
`

type ReimbursementSetting = {
  is_merged_periods: boolean | null
  merged_periods: string | null
}
export interface SchoolYearMenuItem {
  ScheduleBuilder: ScheduleBuilder
  date_begin: string
  date_end: string
  school_year_id: number
  grades: string
  midyear_application: boolean
  IsCurrentYear: boolean
  special_ed: boolean
  special_ed_options?: string
  diploma_seeking: boolean
  reimbursements: ReduceFunds
  direct_orders: ReduceFunds
  testing_preference: boolean
  testing_preference_title: string
  testing_preference_description: string
  opt_out_form_title: string
  opt_out_form_description: string
  midyear_application_open: string
  midyear_application_close: string
  midyear_schedule_open: string
  midyear_schedule_close: string
  second_semester_open: string
  second_semester_close: string
  schedule_builder_open: string
  schedule_builder_close: string
  homeroom_resource_open: string
  homeroom_resource_close: string
  direct_order_open: string
  direct_order_close: string
  reimbursement_open: string
  reimbursement_close: string
  custom_built_open: string
  custom_built_close: string
  reimbursement_custom_built_open: string
  reimbursement_custom_built_close: string
  require_software_open: string
  require_software_close: string
  third_party_open: string
  third_party_close: string
  mid_direct_order_open: string
  mid_direct_order_close: string
  mid_reimbursement_open: string
  mid_reimbursement_close: string
  mid_custom_built_open: string
  mid_custom_built_close: string
  mid_reimbursement_custom_built_open: string
  mid_reimbursement_custom_built_close: string
  mid_require_software_open: string
  mid_require_software_close: string
  mid_third_party_open: string
  mid_third_party_close: string
  schedule: boolean
  require_software: boolean
  learning_logs_first_second_semesters: boolean
  first_semester_ends: string
  second_semester_starts: string
  learning_logs_eoy_deadline: string
  intent_to_re_enroll_open: string
  intent_to_re_enroll_close: string
  notify_of_withdraw: string
  ReimbursementSetting?: ReimbursementSetting
  grade_transition_graduated: string
  grade_transition_completed: string
  enable_grade_transition: boolean
  full_application_open: string
  full_application_close: string
  participation_tracking: boolean
}

export interface useSchoolYearsByRegionIdResult {
  loading: boolean
  schoolYears: SchoolYearMenuItem[]
  currentYear: SchoolYearMenuItem | undefined
  dropdownItems: DropDownItem[]
  error: ApolloError | undefined
  refetchSchoolYear: () => void
  selectedYearId: number | undefined
  setSelectedYearId: (_?: number) => void
  selectedYear: SchoolYearMenuItem | undefined
}

export const useSchoolYearsByRegionId = (
  regionId?: number | undefined,
  defaultSchoolYearId?: number,
  preservePreviousSelection?: boolean,
  onlyPastYears?: boolean,
): useSchoolYearsByRegionIdResult => {
  const { me } = useContext(UserContext)
  const selectedRegionId = regionId || me?.selectedRegionId
  const [selectedYearId, setSelectedYearId] = useState<number | undefined>()

  const { loading, data, error, refetch } = useQuery<{ getSchoolYearsByRegionId: Array<SchoolYearMenuItem> }>(
    getSchoolYearsQuery,
    {
      variables: {
        regionId: selectedRegionId,
      },
      skip: selectedRegionId === undefined,
      fetchPolicy: 'network-only',
    },
  )

  const schoolYears: Array<SchoolYearMenuItem> = useMemo(() => {
    if (!loading && data && data.getSchoolYearsByRegionId) {
      return data.getSchoolYearsByRegionId
    }
    return []
  }, [data, loading])

  const currentYear: SchoolYearMenuItem | undefined = useMemo(() => {
    return schoolYears.find((item) => item.IsCurrentYear)
  }, [schoolYears])

  const dropdownItems = useMemo(() => {
    if (!isEmpty(schoolYears)) {
      let filteredSchoolYears = schoolYears
      if (onlyPastYears) {
        filteredSchoolYears = schoolYears.filter(
          (schoolYear) => new Date(schoolYear.date_end) <= new Date(currentYear?.date_end || ''),
        )
      }
      return sortBy(filteredSchoolYears, 'date_begin').map((item: SchoolYearMenuItem) => ({
        value: item.school_year_id,
        label: `${dayjs(item.date_begin).format('YYYY')}-${dayjs(item.date_end).format('YY')}`,
      }))
    }

    return []
  }, [loading, data])

  useEffect(() => {
    if (preservePreviousSelection && selectedYearId) {
      const selectedYearOnCurrentYears = schoolYears.find((schoolYear) => schoolYear.school_year_id === selectedYearId)
      if (selectedYearOnCurrentYears) return
    }
    if (schoolYears?.length) {
      if (defaultSchoolYearId) setSelectedYearId(defaultSchoolYearId)
      else {
        setSelectedYearId(currentYear?.school_year_id || schoolYears[0].school_year_id)
      }
    }
  }, [schoolYears])

  const selectedYear: SchoolYearMenuItem | undefined = useMemo(() => {
    if (selectedYearId && schoolYears.length) {
      return schoolYears.find((item) => item.school_year_id === selectedYearId)
    } else return undefined
  }, [selectedYearId, schoolYears])

  return {
    error,
    selectedYearId: selectedYearId,
    selectedYear,
    loading,
    schoolYears,
    currentYear,
    dropdownItems,
    refetchSchoolYear: refetch,
    setSelectedYearId,
  }
}
