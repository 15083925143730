export enum QuestionTypeLabel {
  DROP_DOWN = 'Drop Down',
  TEXT_FIELD = 'Text Field',
  CHECKBOX = 'Checkbox',
  AGREEMENT = 'Agreement',
  MULTIPLE_CHOICES = 'Multiple Choices',
  CALENDAR = 'Calendar',
  INFORMATION = 'Information',
  SPECIAL_EDUCATION = 'Special Education',
  PROGRAM_STATE = 'Program State',
  PROGRAM_YEAR = 'Program Year',
}
