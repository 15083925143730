import { ApolloError, useQuery } from '@apollo/client'
import { GetCurrentSchoolYearByRegionId } from '@mth/announcements/services'

export const useCurrentSchoolYearByRegionId = (
  regionId?: number,
): {
  loading: boolean
  data: {
    grades: string
    RegionId: number
    date_begin: string
    date_end: string
    schoolEnrollmentYear: {
      id: number
      school_of_enrollment_id: string
      active: boolean
      schoolPartner: {
        region_id: number
        abbreviation: string
        name: string
        id: number
      }
    }[]
    SchoolPartners: {
      abbreviation: string
      active: number
      school_partner_id: string
    }[]
    enrollment_packet: boolean
    midyear_application: boolean
    midyear_application_open: string
    midyear_application_close: string
    schedule: boolean
    diploma_seeking: boolean
    testing_preference: boolean
    school_year_id: number
    ScheduleBuilder: {
      split_enrollment: boolean
      always_unlock: boolean
      max_num_periods: number
    }
    special_ed: boolean
    special_ed_options: string
    participation_tracking: boolean
  }
  error: ApolloError | undefined
  refetch: () => void // Refetch function provided by useQuery
} => {
  const { loading, data, error, refetch } = useQuery(GetCurrentSchoolYearByRegionId, {
    variables: {
      regionId: regionId,
    },
    skip: !regionId,
    fetchPolicy: 'network-only',
  })

  return {
    loading: loading,
    data: data?.schoolyear_getcurrent,
    error: error,
    refetch, // Include the refetch function provided by useQuery
  }
}
