import { gql } from '@apollo/client'

export const getFamilyReimbursements = gql`
  query GetFamilyReimbursements(
    $schoolYearId: Int
    $search: String
    $skip: Int
    $sort: String
    $studentId: Int
    $take: Int
    $isFromParent: Boolean
    $personId: Int
  ) {
    getFamilyReimbursements(
      schoolYearId: $schoolYearId
      search: $search
      skip: $skip
      sort: $sort
      studentId: $studentId
      take: $take
      isFromParent: $isFromParent
      personId: $personId
    ) {
      results {
        amount
        category
        grade
        paid
        ordered
        period
        rb_type
        reimbursement_request_id
        status
        student
        student_id
        submitted
        approved_ordered_by
        additional_information
        periods
      }
      total
      studentId
    }
  }
`
