import { ReimbursementRequestStatus, StudentsGradeLevel } from '@mth/enums'

export const defaultStatusFilter: ReimbursementRequestStatus[] = [
  ReimbursementRequestStatus.SUBMITTED,
  ReimbursementRequestStatus.RESUBMITTED,
]

export const defaultGradeFilter: StudentsGradeLevel[] = [
  StudentsGradeLevel.ALL,
  StudentsGradeLevel.PRE_KINDERGARTEN,
  StudentsGradeLevel.KINDERGARTEN,
  StudentsGradeLevel.FIRST,
  StudentsGradeLevel.SECOND,
  StudentsGradeLevel.THIRD,
  StudentsGradeLevel.FOURTH,
  StudentsGradeLevel.FIFTH,
  StudentsGradeLevel.SIXTH,
  StudentsGradeLevel.SEVENTH,
  StudentsGradeLevel.EIGHTH,
  StudentsGradeLevel.NINTH,
  StudentsGradeLevel.TENTH,
  StudentsGradeLevel.ELEVENTH,
  StudentsGradeLevel.TWELFTH,
]
