import { ApplicationReportColumns } from '@mth/enums'

export const applicationsReportDefaultColumns = [
  {
    label: 'Date Submitted',
    columnName: 'Date Submitted',
    value: ApplicationReportColumns.DateSubmitted,
  },
  {
    label: 'Student Name',
    columnName: 'Student Name',
    value: ApplicationReportColumns.StudentLegalName,
  },
  {
    label: 'Grade Level',
    columnName: 'Grade Level',
    value: ApplicationReportColumns.StudentGradeLevel,
  },
  {
    label: 'School District',
    columnName: 'School District',
    value: ApplicationReportColumns.AddressSchoolDistrict,
  },
  {
    label: 'SPED',
    columnName: 'SPED',
    value: ApplicationReportColumns.SpecialEducation,
  },
  {
    label: 'Parent Name',
    columnName: 'Parent Name',
    value: ApplicationReportColumns.ParentLegalName,
  },
  {
    label: 'Parent Email',
    columnName: 'Parent Email',
    value: ApplicationReportColumns.ParentEmail,
  },
]
