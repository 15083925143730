import React from 'react'
import { ContentState, EditorState, Modifier } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { EMPTY_STRING, orderConfirmationRegex } from '@mth/constants'
import { MthTitle } from '@mth/enums'
import { extractContent } from '@mth/utils/string/string.util'

export const stripStylesFromHtml = (html: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  doc.querySelectorAll('*').forEach((element) => element.removeAttribute('style'))
  return doc.body.innerHTML
}

export const handlePastedText = (
  html: string,
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  editorState: EditorState,
) => {
  if (!html) {
    return false
  }

  const processedHtml = stripStylesFromHtml(html)
  const contentBlock = htmlToDraft(processedHtml)
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)

  const clearedContentState = Modifier.replaceWithFragment(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    contentState.getBlockMap(),
  )

  const newEditorState = EditorState.push(editorState, clearedContentState, 'insert-fragment')
  setEditorState(newEditorState)
  return true
}

export const truncateHtmlString = (input: string, limit: string | number, truncateDelineator = '...') => {
  limit = Number(limit)
  const parser = new DOMParser()
  const doc = parser.parseFromString(`<div>${input}</div>`, 'text/html')
  const contentDiv = doc.body.firstChild as Element

  if (!contentDiv) {
    console.error('Failed to parse HTML content, returning regular string instead.')
    return `${extractContent(input).substring(0, limit)}${truncateDelineator}`
  }

  let textCount = 0
  let nodeQueue = Array.from(contentDiv.childNodes)
  let shouldContinue = true

  while (nodeQueue.length && shouldContinue) {
    const node = nodeQueue.shift()

    if (node?.textContent) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (textCount + node.textContent.length > limit) {
          node.textContent = `${node.textContent.slice(0, limit - textCount)}${truncateDelineator}`
          textCount = limit
          shouldContinue = false
        } else {
          textCount += node.textContent.length
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        nodeQueue = Array.from(node.childNodes).concat(nodeQueue)
      }
    }
  }

  while (nodeQueue.length) {
    const node = nodeQueue.pop()

    if (node?.parentNode) {
      node.parentNode.removeChild(node)
    }
  }

  return contentDiv.innerHTML
}

export const getNewHtmlLine = (htmlLine: string, newConfirmation: string): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(`<div>${htmlLine}</div>`, 'text/html')
  const contentDiv = doc.body.firstChild as Element

  if (!contentDiv) {
    return EMPTY_STRING
  }

  let nodeQueue = Array.from(contentDiv.childNodes)
  let foundConfirmation = false

  while (nodeQueue.length) {
    const node = nodeQueue.shift()

    if (node?.textContent) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.toLowerCase().includes(MthTitle.CONFIRMATION.toLowerCase()) && !foundConfirmation) {
          foundConfirmation = true
          //no more nodes, order confirmation number is here
          if (nodeQueue.length === 0) {
            node.textContent = node.textContent.replace(orderConfirmationRegex, `$1${newConfirmation}`)
            break
          }
        } else if (foundConfirmation) {
          // returning next node type TEXT
          node.textContent = `${newConfirmation}`
          break
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        //next node
        nodeQueue = Array.from(node.childNodes).concat(nodeQueue)
      }
    }
  }
  return contentDiv.innerHTML
}

export const updateConfirmationInHtml = (htmlString: string, newConfirmation: string): string => {
  const parser = new DOMParser()
  const doc: Document = parser.parseFromString(htmlString, 'text/html')
  const confirmationElements = Array.from(doc.querySelectorAll('p strong'))

  for (const elem of confirmationElements) {
    if (elem.textContent?.includes('Order Confirmation')) {
      elem.nextSibling!.textContent = ` ${newConfirmation}`
      break
    }
  }

  return doc.body.innerHTML
}

export const getConfirmationNumber = (htmlLine: string): string | undefined => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(`<div>${htmlLine}</div>`, 'text/html')
  const contentDiv = doc.body.firstChild as Element

  if (!contentDiv) {
    return EMPTY_STRING
  }

  let nodeQueue = Array.from(contentDiv.childNodes)
  let foundConfirmation = false

  while (nodeQueue.length) {
    const node = nodeQueue.shift()

    if (node?.textContent) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.toLowerCase().includes(MthTitle.CONFIRMATION.toLowerCase()) && !foundConfirmation) {
          foundConfirmation = true
          //no more nodes, order confirmation number is here
          if (nodeQueue.length === 0) {
            const text = node.textContent
            const m = orderConfirmationRegex.exec(text)
            orderConfirmationRegex.lastIndex = 0
            if (!m) return ''
            return m.at(-1) ? m.at(-1) : ''
          }
        } else if (foundConfirmation) {
          // returning next node type TEXT
          return node.textContent
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        //next node
        nodeQueue = Array.from(node.childNodes).concat(nodeQueue)
      }
    }
  }
  return ''
}
