export enum ColumnName {
  STUDENT_LEGAL = 'studentLegalName',
}

export enum RequestName {
  STUDENT_LEGAL = 'Student Legal',
  GRADE = 'Grade',
  SOE = 'SoE',
  ASSESSMENT = 'Assessment',
  OPT_IN = 'Opt-in',
  OPT_OUT = 'Opt-out',
  DATE = 'Date',
  PARENT = 'Parent',
  PHONE = 'Phone',
  EMAIL = 'Email',
  UNIQUE_ID = 'Student Unique Identifier',
}

export enum ExtraTitle {
  STUDENT_LEGAL_FIRST_NAME = 'Student Legal First Name',
  STUDENT_LEGAL_LAST_NAME = 'Student Legal Last Name',
}

export enum ReportTitle {
  STUDENT_LEGAL = 'Student Legal',
  GRADE = 'Grade Level',
  SOE = 'SoE',
  ASSESSMENT = 'Assessment Title',
  OPT_IN = 'Opt-in selection',
  OPT_OUT = 'Opt-out selection',
  DATE = 'Date',
  PARENT = 'Parent',
  PHONE = 'Phone',
  EMAIL = 'Parent Email',
  UNIQUE_ID = 'Student Unique Identifier',
}

export enum ReportButtons {
  SEND_OPTOUT_TO_RECORD = 'Send Opt-Out to Records',
}
