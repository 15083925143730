import { ActivateOption, InactiveOption, WithdrawalOption } from '@mth/enums'

export const WITHDRAWAL_OPTIONS = [
  { value: WithdrawalOption.NOTIFY_PARENT_OF_WITHDRAW, label: 'Notify Parent of Withdrawal' },
  { value: WithdrawalOption.NO_FORM_NO_EMAIL, label: 'No Form/No Email' },
  { value: WithdrawalOption.UNDECLARED_FORM_EMAIL, label: 'Undeclared Form/Email' },
  { value: WithdrawalOption.UNDECLARED_FORM_NO_EMAIL, label: 'Undeclared Form/No Email' },
]

export const ACTIVATE_OPTIONS = [
  { value: ActivateOption.DELETE_WITHDRAWAL_FORM, label: 'Delete Withdraw Form from Records' },
  { value: ActivateOption.KEEP_WITHDRAWAL_FORM, label: 'Keep Withdraw Form' },
]

export const INACTIVE_OPTIONS = [
  { value: InactiveOption.NOTIFY_PARENT_OF_INACTIVE, label: 'Notify Parent of Status Change' },
  { value: InactiveOption.NO_EMAIL_INACTIVE, label: 'Change Status Without Notifying Parent' },
]
