import React from 'react'
import { Theme } from '@emotion/react'
import { Box, Button } from '@mui/material'
import { SxProps } from '@mui/system'
import { saveCancelClasses } from './styles'

type SaveCancelComponentProps = {
  isSubmitted: boolean
  handleCancel: () => void
  handleSave?: () => void
  sxCancelBtn?: SxProps<Theme>
  sxSaveBtn?: SxProps<Theme>
}

const SaveCancelComponent: React.FC<SaveCancelComponentProps> = ({
  isSubmitted,
  handleCancel,
  handleSave,
  sxCancelBtn = {},
  sxSaveBtn = {},
}) => {
  return (
    <Box sx={saveCancelClasses.align}>
      {/* @ts-ignore */}
      <Button sx={{ ...saveCancelClasses.cancelBtn, ...sxCancelBtn }} onClick={() => handleCancel()}>
        Cancel
      </Button>
      <Button
        // @ts-ignore
        sx={{ ...saveCancelClasses.saveBtn, ...sxSaveBtn }}
        type='submit'
        disabled={isSubmitted}
        onClick={handleSave}
      >
        Save
      </Button>
    </Box>
  )
}

export default SaveCancelComponent
