import { gql } from '@apollo/client'

export const CreateNewSchoolPartnerMutation = gql`
  mutation CreateNewSchoolPartner($schoolInput: SchoolInput!) {
    createSchool(schoolInput: $schoolInput) {
      id
      name
      abbreviation
      photo
    }
  }
`

export const GetSchoolsOfEnrollment = gql`
  query GetSchoolsOfEnrollmentByRegion($schoolPartnerArgs: SchoolPartnerArgs!) {
    getSchoolsOfEnrollmentByRegion(schoolPartnerArgs: $schoolPartnerArgs) {
      id
      name
      abbreviation
      photo
      schoolEnrollmentYear {
        id
        active
        schoolYear {
          school_year_id
        }
      }
    }
  }
`

export const UpdateSchoolPartnerMutation = gql`
  mutation UpdateSchoolPartner($updateSchoolInput: UpdateSchoolInput!) {
    updateSchool(updateSchoolInput: $updateSchoolInput) {
      id
      name
      abbreviation
      photo
    }
  }
`

export const ToggleSchoolPartnerMutation = gql`
  mutation ToggleSchoolPartner($schoolPartnerId: Float!, $schoolYearId: Float!) {
    toggleSchoolArchive(schoolPartnerId: $schoolPartnerId, schoolYearId: $schoolYearId) {
      id
    }
  }
`
