import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { DeleteForeverOutlined } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Card, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { MthTable } from '@mth/components/MthTable'
import { MthTableField, MthTableRowItem } from '@mth/components/MthTable/types'
import { Pagination } from '@mth/components/Pagination/Pagination'
import { paginationOptions } from '@mth/constants'
import { DropDown } from '@mth/core/components/shared/DropDown/DropDown'
import { Search } from '@mth/core/components/shared/Search'
import { Subtitle } from '@mth/core/components/shared/Subtitle/Subtitle'
import { MthColor, MthTitle, Order, datePatterns } from '@mth/enums'
import { getResourceRequestsQuery } from '@mth/graphql/queries/resource-request'
import { useSchoolYearsByRegionId } from '@mth/hooks'
import { ResourceRequest, StudentGradeLevel } from '@mth/models'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { formatDateByPatternUtc, gradeShortText, resourceRequestCost, resourceRequestStatus } from '@mth/utils'
import { useStyles } from './styles'

type HomeroomResourceModalProps = {
  showHomeromResourceModal: boolean
  toggleHomeroomResourceModal: () => void
  schoolYearIdSelected?: number | undefined
  studentIds: number[]
}
const defaultPaginationOption = paginationOptions[0]
export const HomeroomResourceModal: React.FC<HomeroomResourceModalProps> = ({
  showHomeromResourceModal,
  toggleHomeroomResourceModal,
  schoolYearIdSelected,
  studentIds,
}) => {
  const styles = useStyles
  const { me } = useContext(UserContext)
  const [searchField, setSearchField] = useState<string>('')
  const [paginationLimit, setPaginationLimit] = useState<number>(defaultPaginationOption)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [skip, setSkip] = useState<number>(0)
  const [tableData, setTableData] = useState<MthTableRowItem<ResourceRequest>[]>([])
  const [totalCnt, setTotalCnt] = useState<number>(0)

  useEffect(() => {
    handlePageChange(1)
  }, [searchField])

  const {
    dropdownItems: schoolYearDropdownItems,
    selectedYearId,
    setSelectedYearId,
  } = useSchoolYearsByRegionId(me?.selectedRegionId, schoolYearIdSelected)

  const handleChangePageLimit = (value: number) => {
    handlePageChange(1)
    setPaginationLimit(value)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    setSkip(paginationLimit ? paginationLimit * (page - 1) : defaultPaginationOption)
  }

  const gradeLevelText = (gradeLevels: StudentGradeLevel[]) => {
    const gradeLevelYear = gradeLevels?.find(
      (gradeLevel) => Number(gradeLevel?.school_year_id) === Number(selectedYearId),
    )
    return gradeShortText(gradeLevelYear?.grade_level ?? gradeLevels?.[gradeLevels?.length - 1]?.grade_level)
  }

  const fields: MthTableField<ResourceRequest>[] = [
    {
      key: 'created_at',
      label: 'Submitted',
      sortable: true,
      width: '110px',
      formatter: (item: MthTableRowItem<ResourceRequest>) => {
        // @ts-ignore
        return formatDateByPatternUtc(item.rawData.created_at, datePatterns.ZMONTH_ZDAY_4DYEAR)
      },
    },
    {
      key: 'student',
      label: 'Student',
      sortable: true,
      formatter: (item: MthTableRowItem<ResourceRequest>) => {
        return `${item.rawData.Student?.person?.last_name}, ${item.rawData.Student?.person?.first_name}`
      },
    },
    {
      key: 'grade',
      label: 'Grade',
      sortable: true,
      formatter: (item: MthTableRowItem<ResourceRequest>) => {
        // @ts-ignore
        return gradeLevelText(item.rawData.Student?.grade_levels)
      },
    },
    {
      key: 'vendor',
      label: 'Vendor',
      sortable: true,
      formatter: (item: MthTableRowItem<ResourceRequest>) => {
        return item.rawData.Resource?.title
      },
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      formatter: (item: MthTableRowItem<ResourceRequest>) => {
        // @ts-ignore
        return resourceRequestStatus(item.rawData.status)
      },
    },
    {
      key: 'cost',
      label: 'Cost',
      sortable: true,
      formatter: (item: MthTableRowItem<ResourceRequest>) => {
        return resourceRequestCost(item.rawData)
      },
    },
    {
      key: 'level',
      label: 'Level',
      sortable: true,
      formatter: (item: MthTableRowItem<ResourceRequest>) => {
        return item.rawData.ResourceLevel?.name
      },
    },
    {
      key: 'action',
      formatter: () => {
        return (
          <Box display={'flex'} flexDirection='row' justifyContent={'flex-end'}>
            <Tooltip title={MthTitle.DELETE}>
              <IconButton>
                <DeleteForeverOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  const { loading, data } = useQuery(getResourceRequestsQuery, {
    variables: {
      schoolYearId: Number(selectedYearId),
      skip: skip,
      take: paginationLimit,
      region: Number(me?.selectedRegionId),
      search: searchField,
      studentIds: studentIds,
    },
    skip: !selectedYearId,
    fetchPolicy: 'network-only',
  })

  const createData = (resourceRequest: ResourceRequest): MthTableRowItem<ResourceRequest> => {
    return {
      key: `schedule-request-${resourceRequest.resource_id}-${resourceRequest.student_id}`,
      columns: {},
      selectable: true,
      isSelected: false,
      rawData: resourceRequest,
    }
  }

  useEffect(() => {
    if (!loading && data?.resourceRequests) {
      const { resourceRequests } = data
      const { results, total } = resourceRequests
      setTotalCnt(total)
      setTableData(
        (results || []).map((item: ResourceRequest) => {
          return createData(item)
        }),
      )
    }
  }, [loading, data])

  return (
    <Modal open={showHomeromResourceModal} onClose={toggleHomeroomResourceModal}>
      <Card sx={{ ...styles.homeroomResourceModal }}>
        <Box display='flex' justifyContent='flex-end' alignItems='center'>
          <Button sx={styles.cancelIcon} onClick={toggleHomeroomResourceModal}>
            <CloseIcon fontSize='small' />
          </Button>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center' mt={1.5}>
          <Subtitle size='large' fontWeight={'bold'}>
            {MthTitle.HOMEROOM_RESOURCES}
          </Subtitle>
          <DropDown
            dropDownItems={schoolYearDropdownItems}
            placeholder={MthTitle.SELECT_YEAR}
            defaultValue={selectedYearId}
            borderNone={true}
            setParentValue={(val) => {
              setSelectedYearId(Number(val))
            }}
            sx={{ mr: 3, '& .MuiFormControl-root .MuiInputBase-root': { fontSize: 18 } }}
          />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{ mt: 3, mb: 2.75 }}>
          <Box display='flex' flexDirection='row'>
            <Search setSearchField={setSearchField} sx={{ width: 280, fontSize: 12, fontWeight: 600, height: 40 }} />
            <Button
              onClick={() => {}}
              disableElevation
              variant='contained'
              sx={{
                background: MthColor.BG_MAIN,
                color: MthColor.BLACK,
                width: '200px',
                height: '44px',
                alignItems: 'center',
                ml: 2.5,
              }}
              startIcon={<AddIcon />}
            >
              {MthTitle.HOMEROOM_RESOURCE}
            </Button>
          </Box>
          <Pagination
            testId='pagination'
            setParentLimit={handleChangePageLimit}
            handlePageChange={handlePageChange}
            defaultValue={paginationLimit || 25}
            numPages={Math.ceil(totalCnt / paginationLimit) || 1}
            currentPage={currentPage}
            fontWeight={600}
            dropdownOptions={[
              {
                label: '25',
                value: 25,
              },
              {
                label: '50',
                value: 50,
              },
              {
                label: '100',
                value: 100,
              },
            ]}
          />
        </Box>
        <Box maxHeight={450} overflow='auto'>
          <MthTable
            items={tableData}
            fields={fields}
            size='small'
            labelSize={12}
            selectable={true}
            oddBg={false}
            isTableCellBorder={false}
            defaultOrder={Order.ASC}
          />
        </Box>
        <Typography fontSize={20} fontWeight={700} mt={4.75}>
          {MthTitle.SUM}&emsp;{`$${data?.resourceRequests?.totalPrice.toFixed(2)}`}
        </Typography>
      </Card>
    </Modal>
  )
}
