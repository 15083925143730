import {
  isAfter,
  isBefore,
  format,
  addDays as addDays_,
  subDays,
  isValid,
  addYears as addYears_,
  isDate,
  compareAsc,
  compareDesc,
  parse,
  endOfDay as endOfDay_,
  startOfDay as startOfDay_,
  isEqual,
  subWeeks,
} from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc as _zonedTimeToUtc } from 'date-fns-tz'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { isNil } from 'lodash'
import { EMPTY_STRING, STRING_TYPE, localZonedIsoDate } from '@mth/constants'
import { MthTitle, Order } from '@mth/enums'
import { datePatterns, dateSettings } from '@mth/enums'
import { ValidationResult } from '@mth/types'

dayjs.extend(utc)
dayjs.extend(timezone)

export const nowBetweenUtcInterval = (begin: Date, end: Date): boolean => {
  try {
    return afterNow(end) && beforeNow(begin)
  } catch (error) {
    return false
  }
}

export const nowBetweenUtcIntervalFromString = (begin: string | undefined, end: string | undefined): boolean => {
  if (isNil(begin) || isNil(end)) {
    return false
  }
  try {
    return afterNow(new Date(end)) && beforeNow(new Date(begin))
  } catch (error) {
    return false
  }
}

export const beforeNow = (date: Date): boolean => {
  return isBefore(date, new Date())
}

export const afterNow = (date: Date): boolean => {
  return isAfter(date, new Date())
}

export const addTimeToDate = (date: Date, hours: number, minutes: number, seconds: number): Date => {
  return new Date(date.setHours(date.getHours() + hours, date.getMinutes() + minutes, seconds))
}

export const isStartOfDay = (date: Date): boolean => {
  return isEqual(date, startOfDay(date))
}

export const isEndOfDay = (date: Date): boolean => {
  return isEqual(date, new Date(endOfDay(date).setMilliseconds(0)))
}

export const toUTCString = (date: Date, timezone: string): string => {
  const utcDate = zonedTimeToUtc(
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
    timezone,
  )
  return utcDate.toISOString()
}

export const utcToTimezoned = (date: Date | string | number, timezone: string): Date => {
  return utcToZonedTime(date, timezone)
}

export const formatUtcToTzByPattern = (utcDate: Date | string | number, timezone: string, pattern: string): string => {
  try {
    const date = utcToTimezoned(utcDate, timezone)
    return format(date, pattern)
  } catch (error) {
    console.error('An error occurred while converting UTC date to formatted timezone')
    console.error(error)
    return ''
  }
}

export const startOfDay = (date: Date): Date => {
  return startOfDay_(date)
}

export const formatDateByPattern = (date: Date | number, pattern: string): string => {
  return format(date, pattern)
}

/**
 * Returns the date of the previous Monday based on the given date.
 *
 * This function is designed to operate only on dates that fall on weekdays
 * (Monday through Friday). If the input date is a weekend (Saturday or Sunday),
 * the calculation may yield unexpected results, as it does not adjust for weekends.
 *
 * @param date - The reference date to calculate from.
 * @returns The Date object representing the last Monday before the given date.
 */
export function getLastWeekMonday(date: Date): Date {
  const resultDate = new Date(date.getTime())
  let prevMonday = subWeeks(resultDate, 1)
  const MONDAY = 1
  while (prevMonday.getUTCDay() !== MONDAY) {
    prevMonday = subDays(prevMonday, 1)
  }
  return prevMonday
}

//Converts a valid string date or Date object to a UTC zoned date If the input is invalid or cannot be parsed, it returns null
export const formatDateUtc = (date: string | Date): Date | null => {
  try {
    const parsedDate = new Date(date)
    if (isNaN(parsedDate.getTime())) {
      return null
    }
    return utcToZonedTime(date, dateSettings.UTC)
  } catch {
    return null
  }
}

export const formatDateByPatternUtc = (date: string | Date | number, pattern: string): string => {
  const utcDate = utcToZonedTime(date, dateSettings.UTC)
  return format(utcDate, pattern)
}

export const addDays = (date: Date, amount: number): Date => {
  return addDays_(date, amount)
}

export const addYears = (date: Date, amountYears: number): Date => {
  return addYears_(date, amountYears)
}

export const substractDays = (date: Date, amount: number): Date => {
  return subDays(date, amount)
}

export const concatDateAndTime = (date: string | Date, hour: string): Date => {
  const date_ = new Date(date)
  const hourSplit = hour.split(':')
  date_.setHours(Number(hourSplit[0]), Number(hourSplit[1]))
  return date_
}

export const utcDateFromString = (date: string): Date => {
  return utcToZonedTime(date, dateSettings.UTC)
}

export const utcDateFromDate = (date: Date): Date => {
  return utcToZonedTime(date, dateSettings.UTC)
}

export const endOfDay = (date: Date): Date => {
  return endOfDay_(date)
}

export const nowBetweenInterval = (begin: string, end: string, timezone: string): boolean => {
  try {
    const begin_ = startOfDay(utcDateFromString(begin))
    const end_ = endOfDay(utcDateFromString(end))
    const now = utcToTimezoned(new Date(), timezone)
    return now >= begin_ && now <= end_
  } catch (error) {
    return false
  }
}

export const dateBetweenInterval = (date: string, begin: string, end: string): boolean => {
  try {
    const begin_ = startOfDay(utcDateFromString(begin))
    const end_ = endOfDay(utcDateFromString(end))
    const date_ = utcDateFromString(date)
    return date_ >= begin_ && date_ <= end_
  } catch (error) {
    console.error('An error occurred while checking date interval:')
    console.error(error)
    return false
  }
}

export const onlyDateISOString = (date: Date): string => {
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${date.getFullYear()}-${month}-${day}T00:00:00.000Z`
}

//This function is meant to detect whether the input `value` is a valid date whether a `Date` object
// or a well-formed date in a string. It compares against the formats from `datePatterns` in date-patterns.enum.ts.
// We are checking this way as opposed to `Date.parse(value)` or `new Date(value)` because there's a behavior
// in Javascript that causes a numeric value to be returned when a string has numbers at the end rather than `NaN`.

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const isValidDate = (value: any): boolean => {
  if (!Boolean(value)) {
    return false
  }

  try {
    const formats = Object.values(datePatterns)

    if (isValid(value)) {
      return true
    }

    // if the value is a string, and it matches ISO local zone format, then it's a valid date string
    if (typeof value === STRING_TYPE && value.match(localZonedIsoDate)) {
      return true
    }

    for (const format of formats) {
      const parsedDate = parse(value, format, new Date())
      if (isValid(parsedDate)) {
        return true
      }
    }
  } catch (e) {
    console.error(e)
  }

  return false
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const isTypeDate = (value: any): boolean => {
  return isDate(value)
}

export const compare = (
  date1: Date | number,
  date2: Date | number,
  order: Order.ASC | Order.DESC = Order.ASC,
): number => {
  return order === Order.ASC ? compareAsc(date1, date2) : compareDesc(date1, date2)
}

// all functions above use date fns to implement date management

export const getFirstDayAndLastDayOfMonth = (date: Date = new Date()): { firstDay?: Date; lastDay?: Date } => {
  const CALENDAR_CELL_COUNT = 42
  const CALENDAR_CELL_START = 1
  const CALENDAR_END = 36
  const DAY_OF_WEEK_SUNDAY = 0
  const DAYS_IN_WEEK = 7

  const calendarDays: Date[] = []
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
  const weekdayOfFirstDay = firstDayOfMonth.getDay()
  for (let cell = CALENDAR_CELL_START; cell <= CALENDAR_CELL_COUNT; cell++) {
    if (cell === CALENDAR_CELL_START && weekdayOfFirstDay === DAY_OF_WEEK_SUNDAY) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - (DAYS_IN_WEEK - 1))
    } else if (cell === 1) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (cell - weekdayOfFirstDay))
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1)
    }
    if (cell === DAYS_IN_WEEK && firstDayOfMonth.getMonth() !== date.getMonth()) {
      calendarDays.splice(0)
      continue
    }

    if (cell === CALENDAR_END && firstDayOfMonth.getMonth() !== date.getMonth()) {
      break
    }

    calendarDays.push(new Date(firstDayOfMonth))
  }

  return { firstDay: calendarDays?.at(0), lastDay: calendarDays?.at(-1) }
}

export const calcAge = (birth: string | Date | undefined): number => {
  if (birth) {
    return dayjs().tz('UTC').diff(birth, 'years')
  }
  return 0
}

export const getTimezoneOffsetStr = (offsetMin: number): string => {
  const h = Math.floor(Math.abs(offsetMin) / 60)
  const m = Math.abs(offsetMin) % 60
  return `${offsetMin > 0 ? '-' : '+'}${h}:${m}`
}

export const showDate = (date: string | Date | undefined, format = 'MM/DD/YYYY'): string => {
  if (date) {
    return dayjs(date).tz('UTC').format(format)
  }
  return ''
}

export const formatTwoDigits = (num: number): string => {
  return num.toString().padStart(2, '0')
}

// hh:mm A
export const formatTime = (dateString?: Date): string => {
  if (!dateString) return ''
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }
  return date.toLocaleString('en-US', options)
}

//MMMM DD
export const formatMonthDays = (dateString?: Date): string => {
  if (!dateString) return ''
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
  }
  return date.toLocaleString('en-US', options)
}

export const formatSchoolYear = (date_begin: string, date_end: string, timezone: string): string => {
  const begin = formatDateByPattern(utcToTimezoned(new Date(date_begin), timezone), datePatterns.FULL_YEAR)
  const end = formatDateByPattern(utcToTimezoned(new Date(date_end), timezone), datePatterns.SHORT_YEAR)
  return `${begin}-${end}`
}

export const formatSchoolYearWithAddedYears = (
  dateBegin: string,
  dateEnd: string,
  midYear: boolean,
  timezone: string,
  amount: number,
): string => {
  if (!dateBegin || !dateEnd) {
    return EMPTY_STRING
  }

  const nextBeginYear = utcToTimezoned(addYears(new Date(dateBegin), amount), timezone)
  const nextEndYear = utcToTimezoned(addYears(new Date(dateEnd), amount), timezone)

  if (midYear) {
    return `${formatDateByPattern(nextBeginYear, datePatterns.FULL_YEAR)}-${formatDateByPattern(
      nextEndYear,
      datePatterns.SHORT_YEAR,
    )} ${MthTitle.MID_YEAR}`
  }

  return `${formatDateByPattern(nextBeginYear, datePatterns.FULL_YEAR)}-${formatDateByPattern(
    nextEndYear,
    datePatterns.SHORT_YEAR,
  )}`
}

export const validateDateRange = (startDate: Date | null, endDate: Date | null): ValidationResult => {
  if (!startDate || !endDate) {
    return { valid: false, message: 'Both start date and end date are required.' }
  }

  const currentDate = startOfDay(new Date())

  if (isBefore(startDate, currentDate)) {
    return { valid: false, message: 'Start date cannot be in the past.' }
  }

  if (isBefore(endDate, currentDate)) {
    return { valid: false, message: 'End date cannot be in the past.' }
  }

  if (isBefore(endDate, startDate)) {
    return { valid: false, message: 'End date must be after start date.' }
  }

  return { valid: true, message: 'Date range is valid.' }
}

export const zonedTimeToUtc = (date: Date, tz: string): Date => {
  return _zonedTimeToUtc(date, tz)
}

export function splitFormatDate(date: string | undefined): string {
  if (date) {
    const formattedDate = new Date(date)
    const year = formattedDate.getFullYear().toString().slice(-2)
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0')
    const day = formattedDate.getDate().toString().padStart(2, '0')
    return `${month}/${day}/${year}`
  }

  return ''
}

export function formatDateForInput(date: string | undefined | null): string {
  if (date) {
    return dayjs(new Date(date)).utc().format('YYYY-MM-DD')
  }
  return ''
}
