import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Modal, Typography } from '@mui/material'
import { CustomModal } from '@mth/components/CustomModal/CustomModals'
import { MthTableRowItem } from '@mth/components/MthTable/types'
import { Pagination } from '@mth/components/Pagination/Pagination'
import { EMPTY_STRING, lastComma, paginationOptions } from '@mth/constants'
import { DropDown } from '@mth/core/components/shared/DropDown/DropDown'
import { Search } from '@mth/core/components/shared/Search'
import {
  GRADE_LEVEL_LABEL,
  MthColor,
  MthTitle,
  Order,
  ReimbursementKeyValues,
  ReimbursementRequestStatus,
  ReimbursementRequestTypeAbr,
  datePatterns,
} from '@mth/enums'
import { deleteReimbursementRequestsMutation } from '@mth/graphql/mutation/reimbursement-request'
import { useSchoolYearsByRegionId } from '@mth/hooks'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { formatDateByPattern, getTimezone, utcToTimezoned } from '@mth/utils'
import { ReimbursementTable } from './ReimbursementTable'
import { getFamilyReimbursements } from './services'
import { useStyles } from './styles'
import { REIMBURSEMENT_TABLE_HEAD, Reimbursement } from './types'
import { DirectDeductionModal } from '../DirectDeduction'

interface ReimbursementModalProps {
  closeModal: () => void
  studentId?: number
  schoolYearIdSelected?: number | undefined
  isFromParent?: boolean
  personId?: number
}

const defaultPaginationOption = paginationOptions[0]
const dollar = '$'

export const ReimbursementModal: React.FC<ReimbursementModalProps> = ({
  closeModal,
  studentId = 0,
  schoolYearIdSelected = undefined,
  isFromParent = false,
  personId,
}) => {
  const { me } = useContext(UserContext)
  const [searchField, setSearchField] = useState<string>('')
  const [paginationLimit, setPaginationLimit] = useState<number>(defaultPaginationOption)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [skip, setSkip] = useState<number>(0)
  const [sort, setSort] = useState<string>(`${REIMBURSEMENT_TABLE_HEAD.SUBMITTED.toLowerCase()}|${Order.ASC}`)
  const [tableData, setTableData] = useState<Reimbursement[]>([])
  const [totalData, setTotalData] = useState<number>(0)
  const [selectedItems, setSelectedItems] = useState<Reimbursement[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [reimbursementToDelete, setReimbursementToDelete] = useState<number>(-1)
  const [reimbursementToEdit, setReimbursementToEdit] = useState<Reimbursement>()
  const [showDiretDeductionModal, setShowDirectDeductionModal] = useState<boolean>(false)

  const totalSum: string = selectedItems
    .reduce((acc, curr) => acc + Number(curr.amount.replace(dollar, '')), 0)
    .toFixed(2)

  const [deleteReimbursementRequests] = useMutation(deleteReimbursementRequestsMutation)

  const {
    dropdownItems: schoolYearDropdownItems,
    selectedYearId,
    setSelectedYearId,
    selectedYear,
  } = useSchoolYearsByRegionId(me?.selectedRegionId, schoolYearIdSelected)

  const {
    loading: loadingFamilyReimbursement,
    data: familyReimbursementData,
    refetch: refetchReimbursementData,
  } = useQuery(getFamilyReimbursements, {
    variables: {
      schoolYearId: selectedYearId,
      search: searchField,
      skip: skip,
      sort: sort,
      studentId: studentId,
      take: paginationLimit,
      isFromParent,
      personId,
    },
    skip: studentId !== undefined ? false : true,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loadingFamilyReimbursement && familyReimbursementData) {
      const {
        getFamilyReimbursements: { results, total },
      } = familyReimbursementData
      if (total >= 0) {
        const data: Reimbursement[] = results.map((item: Reimbursement) => {
          let paid_ordered = ''

          if (
            item.rb_type === ReimbursementRequestTypeAbr.REIMBURSEMENT &&
            item.status === ReimbursementRequestStatus.PAID
          ) {
            paid_ordered = item.paid
          }

          if (
            (item.rb_type === ReimbursementRequestTypeAbr.DIRECT_DEDUCTION ||
              item.rb_type === ReimbursementRequestTypeAbr.DIRECT_ORDER) &&
            item.status === ReimbursementRequestStatus.ORDERED
          ) {
            paid_ordered = item.ordered
          }

          return {
            reimbursement_request_id: item.reimbursement_request_id,
            student_id: item.student_id,
            amount: `${dollar}${item.amount}`,
            period: item.period?.replace(lastComma, ' &' + '$1'),
            rb_type: item.rb_type,
            status: item.status,
            student: item.student,
            grade: isNaN(Number(item.grade)) ? GRADE_LEVEL_LABEL.K : item.grade,
            category: ReimbursementKeyValues[item.category],
            paid_ordered: paid_ordered
              ? formatDateByPattern(
                  utcToTimezoned(new Date(paid_ordered), getTimezone(me!)),
                  datePatterns.ZMONTH_ZDAY_4DYEAR,
                )
              : EMPTY_STRING,
            submitted: item.submitted
              ? formatDateByPattern(
                  utcToTimezoned(new Date(item.submitted), getTimezone(me!)),
                  datePatterns.ZMONTH_ZDAY_4DYEAR,
                )
              : EMPTY_STRING,
            approved_ordered_by: item.approved_ordered_by,
            additional_information: item.additional_information,
            periods: item.periods,
          }
        })
        setTotalData(total)
        setTableData([...data])
      }
    }
  }, [loadingFamilyReimbursement, familyReimbursementData])

  const anyStudentId = useMemo(() => {
    if (!loadingFamilyReimbursement && familyReimbursementData) {
      const {
        getFamilyReimbursements: { studentId },
      } = familyReimbursementData
      return studentId
    }
    return 0
  }, [loadingFamilyReimbursement, familyReimbursementData])

  useEffect(() => {
    handlePageChange(1)
  }, [searchField])

  useEffect(() => {
    setSelectedItems([])
  }, [selectedYearId])

  const handleChangePageLimit = (value: number) => {
    handlePageChange(1)
    setPaginationLimit(value)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    setSkip(paginationLimit ? paginationLimit * (page - 1) : defaultPaginationOption)
  }

  const handleSort = (property: string, order: Order) => {
    setSort(`${property}|${order}`)
  }

  const handleSelection = (items: MthTableRowItem<Reimbursement>[], isAll?: boolean) => {
    const result = items.filter((item) => item.isSelected).map<Reimbursement>((item) => item.rawData)
    if (isAll) setSelectedItems([...result])
  }

  const handleSelectItem = (item: MthTableRowItem<Reimbursement>, isUnselect?: boolean) => {
    if (isUnselect) {
      setSelectedItems(
        selectedItems.filter((f_item) => f_item.reimbursement_request_id !== item.rawData.reimbursement_request_id),
      )
    } else {
      setSelectedItems([...selectedItems, item.rawData])
    }
  }

  const handleClickDelete = (reimbursement_id: number) => {
    setReimbursementToDelete(reimbursement_id)
    setShowDeleteModal(true)
  }

  const handleDelete = async () => {
    if (reimbursementToDelete === -1) return
    await deleteReimbursementRequests({
      variables: {
        reimbursementRequestsActionInput: {
          reimbursementRequestIds: reimbursementToDelete,
        },
      },
    })
    await refetchReimbursementData()
    setSelectedItems(selectedItems.filter((item) => item.reimbursement_request_id !== reimbursementToDelete))
  }

  const editDirectDeduction = (item: Reimbursement) => {
    setReimbursementToEdit(item)
    setShowDirectDeductionModal(true)
  }

  const handleShowModal = (value: boolean) => {
    setReimbursementToEdit(undefined)
    setShowDirectDeductionModal(value)
  }

  return (
    <>
      <Modal open={true} onClose={() => closeModal()}>
        <Box sx={useStyles.customReportModal}>
          <Box display='flex' flexDirection='row' justifyContent='end'>
            {/* @ts-ignore */}
            <CloseIcon style={useStyles.closeBtn} onClick={() => closeModal()} />
          </Box>
          <Box display='flex' flexDirection='row' justifyContent='space-between' mt={1.5}>
            <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>{MthTitle.FAMILY_REIMBURSEMENT_TITLE}</Typography>
            <DropDown
              dropDownItems={schoolYearDropdownItems}
              placeholder={MthTitle.SELECT_YEAR}
              defaultValue={selectedYearId}
              borderNone={true}
              setParentValue={(val) => {
                setSelectedYearId(Number(val))
              }}
              sx={{ mr: 3, '& .MuiFormControl-root .MuiInputBase-root': { fontSize: 18 } }}
            />
          </Box>
          <Box sx={{ mt: 3, mb: 2.75 }} display='flex' flexDirection='row' justifyContent='space-between'>
            <Box>
              <Search setSearchField={setSearchField} sx={{ width: 280, fontSize: 12, fontWeight: 600, height: 40 }} />
              <Button
                sx={{ bg: MthColor.BG_MAIN, ml: 2.5, fontSize: 16, fontWeight: 600 }}
                startIcon={<AddIcon />}
                onClick={() => setShowDirectDeductionModal(true)}
              >
                {MthTitle.DIRECT_DEDUCTION}
              </Button>
            </Box>
            <Pagination
              testId='pagination'
              setParentLimit={handleChangePageLimit}
              handlePageChange={handlePageChange}
              defaultValue={paginationLimit || defaultPaginationOption}
              numPages={Math.ceil(totalData / paginationLimit) || 1}
              currentPage={currentPage}
            />
          </Box>
          <Box maxHeight={450} overflow='auto'>
            <ReimbursementTable
              data={tableData}
              selectedItems={selectedItems}
              handleSort={handleSort}
              handleSelection={handleSelection}
              reimbursementToDelete={handleClickDelete}
              handleSelectItem={handleSelectItem}
              showPeriod={false}
              editDirectDeduction={editDirectDeduction}
            />
          </Box>
          <Typography fontSize={20} fontWeight={700} mt={4.75}>
            {MthTitle.SUM}&emsp;{`${dollar}${totalSum}`}
          </Typography>
        </Box>
      </Modal>
      {showDeleteModal && (
        <CustomModal
          title={MthTitle.DELETE}
          description={MthTitle.DELETE_REQUEST}
          cancelStr={MthTitle.CANCEL}
          confirmStr={MthTitle.DELETE}
          // @ts-ignore
          backgroundColor={MthColor.WHITE}
          onClose={() => {
            setShowDeleteModal(false)
          }}
          onConfirm={() => {
            handleDelete()
            setShowDeleteModal(false)
          }}
        />
      )}
      {showDiretDeductionModal && (
        <DirectDeductionModal
          showModal={handleShowModal}
          selectedYear={selectedYear!}
          selectedYearId={selectedYearId!}
          anyStudentIdFromParent={anyStudentId}
          refetch={refetchReimbursementData}
          isEditMode={reimbursementToEdit !== undefined}
          reimbursementToEdit={reimbursementToEdit}
        />
      )}
    </>
  )
}
