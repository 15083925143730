import { EnrollmentHeadCellsId, EnrollmentResultsProps } from '@mth/enums'

export type EnrollmentPacketMapping = Record<EnrollmentHeadCellsId, string>

export const enrollmentPacketMapping: EnrollmentPacketMapping = {
  [EnrollmentHeadCellsId.SUBMITTED]: EnrollmentResultsProps.SUBMITTED,
  [EnrollmentHeadCellsId.STATUS]: EnrollmentResultsProps.STATUS,
  [EnrollmentHeadCellsId.DEADLINE]: `${EnrollmentResultsProps.DEADLINE}.props.children`,
  [EnrollmentHeadCellsId.STUDENT]: `${EnrollmentResultsProps.STUDENT}.props.children`,
  [EnrollmentHeadCellsId.GRADE]: EnrollmentResultsProps.GRADE_LEVEL,
  [EnrollmentHeadCellsId.PARENT]: `${EnrollmentResultsProps.PARENT}.props.children`,
  [EnrollmentHeadCellsId.STUDENT_STATUS]: `${EnrollmentResultsProps.STUDENT_STATUS}.props.children`,
  [EnrollmentHeadCellsId.EMAILED]: `${EnrollmentResultsProps.EMAILED}.props.children`,
  [EnrollmentHeadCellsId.LAST_UPDATED]: EnrollmentResultsProps.LAST_UPDATED,
  [EnrollmentHeadCellsId.LAST_SUBMITTED]: EnrollmentResultsProps.LAST_SUBMITTED,
}
