export enum SOEFieldKey {
  STUDENT = 'student',
  GRADE = 'grade',
  CITY = 'city',
  PARENT = 'parent',
  CURRENT_SOE = 'currentSoe',
  PREVIOUS_SOE = 'previousSoe',
}

export enum SOEFieldLabel {
  STUDENT = 'Student',
  GRADE = 'Grade',
  CITY = 'City',
  PARENT = 'Parent',
  CURRENT_SOE = 'Current Soe',
  PREVIOUS_SOE = 'Previous Soe',
}

export enum SoETransferOption {
  ASSIGN = 1,
  ASSIGN_AND_FORM,
  UNASSSIGN,
}

export enum SoETransferOptionText {
  ASSIGN = 'Assign New SOE',
  ASSIGN_AND_FORM = 'Assign New SOE and Create Transfer Form',
  UNASSSIGN = 'Are you sure you want to change this student to Unassigned?',
}
