export enum fontFamilyEnum {
  VisbyCFRegular = 'Regular',
  VisbyCFMedium = 'Medium',
  VisbyCFDemiBold = 'Demi Bold',
  VisbyCFBold = 'Bold',
  VisbyCFExtraBold = 'Extra Bold',
  VisbyCFHeavy = 'Heavy',
  HeavyFont = 'Helvetica',
  VisbyFont = 'VisbyCF',
}
