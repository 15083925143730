import React, { useEffect, useState } from 'react'
import { DeleteForeverOutlined } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { isNil } from 'lodash'
import { MthTable } from '@mth/components/MthTable'
import { MthTableField, MthTableRowItem } from '@mth/components/MthTable/types'
import {
  MthColor,
  MthRoute,
  MthTitle,
  Order,
  ReimbursementRequestStatus,
  ReimbursementRequestTypeAbr,
} from '@mth/enums'
import { useStyles } from './styles'
import { REIMBURSEMENT_TABLE_HEAD, Reimbursement } from './types'

interface ReimbursementTableProps {
  data: Reimbursement[]
  handleSort: (property: string, type: Order) => void
  handleSelection: (items: MthTableRowItem<Reimbursement>[], isAll?: boolean, isUnselect?: boolean) => void
  handleSelectItem: (items: MthTableRowItem<Reimbursement>, isUnselect?: boolean) => void
  reimbursementToDelete: (reimbursement_id: number) => void
  selectedItems: Reimbursement[]
  showPeriod: boolean
  editDirectDeduction: (item: Reimbursement) => void
}

export const ReimbursementTable: React.FC<ReimbursementTableProps> = ({
  data,
  handleSort,
  handleSelection,
  reimbursementToDelete,
  handleSelectItem,
  selectedItems,
  showPeriod,
  editDirectDeduction,
}) => {
  const [items, setItems] = useState<MthTableRowItem<Reimbursement>[]>([])

  const openReimbursement = (reimbursement_id: number) => {
    window.open(
      `${import.meta.env.VITE_PUBLIC_WEB_URL}${MthRoute.REIMBURSEMENTS_REQUESTS}?id=${reimbursement_id}`,
      '_blank',
      'status=yes',
    )
  }

  useEffect(() => {
    if (data) {
      setItems(
        data.map<MthTableRowItem<Reimbursement>>((item: Reimbursement) => ({
          key: `col-${item.amount}-${Math.random()}`,
          isSelected:
            selectedItems?.find((fitem) => fitem.reimbursement_request_id === item.reimbursement_request_id) !==
            undefined,
          columns: {
            submitted: item.submitted,
            amount: item.amount,
            rb_student: item.student,
            grade: item.grade,
            rb_status: item.status,
            paid_ordered: item.paid_ordered,
            category: item.category,
            period: item.period,
            rb_type: item.rb_type,
            approved_ordered_by: item.approved_ordered_by,
          },
          rawData: item,
        })),
      )
    }
  }, [data])

  let tHeads = Object.entries(REIMBURSEMENT_TABLE_HEAD).map(([key, value]) => {
    const head = {
      key: key.toLowerCase(),
      label: value,
      sortable: value !== REIMBURSEMENT_TABLE_HEAD.PERIOD,
    }

    if (value === REIMBURSEMENT_TABLE_HEAD.RB_STUDENT) {
      return {
        ...head,
        formatter: (item: MthTableRowItem<Reimbursement>) => {
          return (
            <Box
              sx={{ cursor: 'pointer', color: MthColor.VORTEX, textDecoration: 'underline' }}
              onClick={() => {
                if (item.rawData.rb_type !== ReimbursementRequestTypeAbr.DIRECT_DEDUCTION)
                  openReimbursement(item.rawData.reimbursement_request_id)
                else editDirectDeduction(item.rawData)
              }}
            >
              {item.rawData.student}
            </Box>
          )
        },
      }
    }
    return head
  })

  if (!showPeriod) tHeads = tHeads.filter((item) => item.label !== REIMBURSEMENT_TABLE_HEAD.PERIOD)

  const fields: MthTableField<Reimbursement>[] = [
    ...tHeads,
    {
      key: 'action',
      formatter: (item: MthTableRowItem<Reimbursement>) => {
        return (
          <Box display={'flex'} flexDirection='row' justifyContent={'flex-end'}>
            <Tooltip title={MthTitle.DELETE}>
              <IconButton
                sx={useStyles.actionButton}
                disabled={
                  (!isNil(item.rawData.status) && item.rawData.status === ReimbursementRequestStatus.APPROVED) ||
                  item.rawData.status === ReimbursementRequestStatus.PAID
                }
                onClick={() => reimbursementToDelete(item.rawData.reimbursement_request_id)}
              >
                <DeleteForeverOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  return (
    <MthTable
      items={items}
      fields={fields}
      selectable={true}
      oddBg={false}
      isTableCellBorder={false}
      sx={useStyles.tableStyle}
      onSortChange={handleSort}
      labelSize={12}
      onSelectionChange={handleSelection}
      showSelectAll={true}
      onSelectionHandle={handleSelectItem}
    />
  )
}
