import React from 'react'
import { Typography } from '@mui/material'
import { TypographyProps } from '../../../types/TypographyProps'

export const Title: React.FC<TypographyProps> = ({
  sx,
  fontWeight,
  children,
  size,
  color,
  textAlign,
  testId,
  lineHeight,
}) => {
  const fontSize = () => (size === 'large' ? 32 : size === 'medium' ? 28 : size === 'small' ? 24 : size ? size : 24)

  return (
    <Typography
      fontWeight={fontWeight || 'bold'}
      fontSize={fontSize}
      color={color}
      textAlign={textAlign}
      sx={sx}
      data-testid={testId}
      lineHeight={lineHeight}
    >
      {children}
    </Typography>
  )
}
