import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Alert } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { EmailModal } from '@mth/components/EmailModal/EmailModal'
import { StandardResponseOption, StandardResponseOptionType } from '@mth/components/EmailModal/StandardReponses/types'
import { EMPTY_STRING } from '@mth/constants'
import { EmailTemplateEnum, EmailType, EnrollmentPacketFormProperty, PacketStatus, datePatterns } from '@mth/enums'
import { SchoolYearMenuItem } from '@mth/hooks'
import { CurrentSchoolYearStatus, Packet, SchoolYear } from '@mth/models'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { EmailTemplateVariables } from '@mth/types/email-template'
import {
  longestCommonSubsequence,
  htmlToText,
  getYearText,
  removeDuplicateNewLines,
  utcToTimezoned,
  getTimezone,
  formatDateByPattern,
  replaceTemplateVariables,
} from '@mth/utils'
import EnrollmentWarnSaveModal from './ConfirmSaveModal'
import { sendEmailMutation } from '../../services'
import { studentContext } from '../providers'
import { EnrollmentPacketFormType } from '../types'

type PacketConfirmModalsProps = {
  packet: Packet
  submitForm: () => void
  selectedYear: SchoolYearMenuItem | undefined
}
export const PacketConfirmModals: React.FC<PacketConfirmModalsProps> = ({ packet, submitForm, selectedYear }) => {
  const student = useContext(studentContext)
  const { me } = useContext(UserContext)
  const { watch, setValue } = useFormContext<EnrollmentPacketFormType>()
  const [emailTemplate, setEmailTemplate] = useState(null)
  const [sendPacketEmail] = useMutation(sendEmailMutation)
  const [emailFrom, setEmailFrom] = useState('')
  const timezone = getTimezone(me!)

  let currentSchoolYearStatus: SchoolYearMenuItem | CurrentSchoolYearStatus | undefined | SchoolYear = selectedYear
    ? selectedYear
    : student?.current_school_year_status
  const school_year_id = packet?.school_year_id ?? currentSchoolYearStatus?.school_year_id

  // When the PacketConfirmModals come from the EnrollmentPacketModal, it have the selectedYear
  // on the selectedYear we can get the correct begin_year and end_year
  // if the selectedYear is null, this occurs when this component is loaded by the ProfilePacketModal, it can be obtained from the student?.applications
  // to get the correct begin_year and end_year
  if (packet?.school_year_id !== currentSchoolYearStatus?.school_year_id) {
    const packetSchoolYear = student?.applications?.find(
      (application) => application.school_year_id === packet.school_year_id,
    )?.school_year
    if (packetSchoolYear) currentSchoolYearStatus = packetSchoolYear
  }
  const [
    notes,
    showMissingInfoModal,
    showAgeIssueModal,
    showSaveWarnModal,
    missingInfoAlert,
    saveAlert,
    saveError,
    preSaveStatus,
  ] = watch([
    EnrollmentPacketFormProperty.NOTES,
    EnrollmentPacketFormProperty.SHOW_MISSING_INFO_MODAL,
    EnrollmentPacketFormProperty.SHOW_AGE_ISSUE_MODAL,
    EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL,
    EnrollmentPacketFormProperty.MISSING_INFO_ALERT,
    EnrollmentPacketFormProperty.SAVE_ALERT,
    EnrollmentPacketFormProperty.SAVE_ERROR,
    EnrollmentPacketFormProperty.PRE_SAVE_STATUS,
  ])

  function onSubmit(status?: string) {
    setValue('status', status || preSaveStatus)
    submitForm()
  }

  const setEmailBodyInfo = (body: string) => {
    const yearText = getYearText({
      // @ts-ignore
      dateBegin: currentSchoolYearStatus?.date_begin,
      // @ts-ignore
      dateEnd: currentSchoolYearStatus?.date_end,
      // @ts-ignore
      midyearApplication:
        student?.applications?.at(-1)?.school_year_id === school_year_id
          ? student?.applications?.at(-1)?.midyear_application
          : false,
    })

    let url = window.location.href
    url = url.substring(0, url.indexOf('/', url.indexOf('//') + 2))
    const grade_level =
      // @ts-ignore
      student.grade_levels?.at(-1)?.grade_level.toLowerCase() === 'k'
        ? 'Kindergarten'
        : // @ts-ignore
          student.grade_levels?.at(-1)?.grade_level
    const replacements: EmailTemplateVariables = {
      studentId: student?.student_id + '',
      files: packet.missing_files,
      link: `<a href="${url}/homeroom/enrollment/${student?.student_id}">${url}/homeroom/enrollment/${student?.student_id}</a>`,
      student: student?.person.first_name,
      studentLegal: student?.person.first_name,
      studentPreferred: student?.person.preferred_first_name,
      parent: student?.parent.person.first_name,
      studentGradeLevel: grade_level || ' ',
      year: yearText,
      applicationYear: yearText,
    }
    return replaceTemplateVariables(body, replacements)
  }

  const setEmailCompareBodyInfo = (body: string) => {
    const yearText = getYearText({
      // @ts-ignore
      dateBegin: currentSchoolYearStatus?.date_begin,
      // @ts-ignore
      dateEnd: currentSchoolYearStatus?.date_end,
      // @ts-ignore
      midyearApplication:
        student?.applications?.at(-1)?.school_year_id === school_year_id
          ? student?.applications?.at(-1)?.midyear_application
          : false,
    })

    let url = window.location.href
    url = url.substring(0, url.indexOf('/', url.indexOf('//') + 2))
    const grade_level =
      // @ts-ignore
      student.grade_levels?.at(-1)?.grade_level.toLowerCase() === 'k'
        ? 'Kindergarten'
        : // @ts-ignore
          student.grade_levels?.at(-1)?.grade_level
    const replacements: EmailTemplateVariables = {
      studentId: String(student?.student_id),
      link: `<a href="${url}/homeroom/enrollment/${student?.student_id}">${url}/homeroom/enrollment/${student?.student_id}</a>`,
      student: student?.person.first_name,
      studentLegal: student?.person.first_name,
      studentPreferred: student?.person.preferred_first_name,
      parent: student?.parent.person.first_name,
      studentGradeLevel: grade_level || ' ',
      year: yearText,
      applicationYear: yearText,
    }
    return replaceTemplateVariables(body, replacements)
  }

  const handleEmailSend = (subject: string, body: string, options: StandardResponseOption) => {
    try {
      let bodyData = setEmailBodyInfo(body)
      // @ts-ignore
      const bodyDataCompare = setEmailCompareBodyInfo(emailTemplate.body)
      sendPacketEmail({
        variables: {
          emailInput: {
            content: bodyData,
            email: student?.parent.person.email,
            subject: subject,
            recipients: null,
            from:
              // @ts-ignore
              emailFrom !== emailTemplate?.from
                ? emailFrom
                : // @ts-ignore
                  emailTemplate && emailTemplate?.from
                  ? // @ts-ignore
                    emailTemplate?.from
                  : null,
            // @ts-ignore
            bcc: emailTemplate && emailTemplate?.bcc ? emailTemplate?.bcc : EMPTY_STRING,
            template_name:
              options.type === StandardResponseOptionType.AGE_ISSUE
                ? EmailTemplateEnum.AGE_ISSUE
                : EmailTemplateEnum.MISSING_INFORMATION,
            region_id: me?.selectedRegionId,
          },
        },
      })
      bodyData = bodyData.replace(' target="_self"', '')
      const commonText = longestCommonSubsequence(bodyDataCompare, bodyData)
      const commonLines = commonText.split('\n')
      let uniqueToSecondString = bodyData
      commonLines.forEach((line) => {
        // Note that this will replace all occurrences of the line.
        uniqueToSecondString = uniqueToSecondString.replace(line, '')
      })

      uniqueToSecondString = htmlToText(uniqueToSecondString)

      setValue('notes', constructPacketNotes(notes || '', options, options.type, uniqueToSecondString))
      if (options.type === StandardResponseOptionType.AGE_ISSUE) {
        setValue(EnrollmentPacketFormProperty.SHOW_AGE_ISSUE_MODAL, false)
        setValue(EnrollmentPacketFormProperty.AGE_ISSUE, true)
        onSubmit()
      } else if (options.type === StandardResponseOptionType.MISSING_INFO) {
        setValue(EnrollmentPacketFormProperty.SHOW_MISSING_INFO_MODAL, false)
        setValue(EnrollmentPacketFormProperty.PRE_SAVE_STATUS, PacketStatus.MISSING_INFO)
        setValue(
          EnrollmentPacketFormProperty.MISSING_FILES,
          options.values.map((v) => v.title),
        )
        onSubmit(PacketStatus.MISSING_INFO)
      }
    } catch (e) {
      console.error('Error while attempting to send email:', e)
    }
  }

  const constructPacketNotes = (
    oldNotes: string,
    options: StandardResponseOption,
    type: StandardResponseOptionType,
    emailText: string,
  ) => {
    const date = new Date()

    // Convert the date to a specific timezone
    const zonedDate = utcToTimezoned(date, timezone)

    // Use formatDateByPattern function
    const formattedDate = formatDateByPattern(zonedDate, datePatterns.ZMONTH_ZDAY_4DYEAR)

    let newNotes = `${formattedDate} - ${
      type === StandardResponseOptionType.AGE_ISSUE ? PacketStatus.AGE_ISSUE : PacketStatus.MISSING_INFO
    }\n`

    let writingBody = ''

    const yearText = getYearText({
      // @ts-ignore
      dateBegin: currentSchoolYearStatus?.date_begin,
      // @ts-ignore
      dateEnd: currentSchoolYearStatus?.date_end,
      // @ts-ignore
      midyearApplication:
        student?.applications?.at(-1)?.school_year_id === student?.current_school_year_status.school_year_id
          ? student?.applications?.at(-1)?.midyear_application
          : false,
    })

    let url = window.location.href
    url = url.substring(0, url.indexOf('/', url.indexOf('//') + 2))
    const grade_level =
      // @ts-ignore
      student.grade_levels?.at(-1)?.grade_level.toLowerCase() === 'k'
        ? 'Kindergarten'
        : // @ts-ignore
          student.grade_levels?.at(-1)?.grade_level

    emailText = emailText.replace(/\n+$/, '')
    writingBody = emailText

    options.values.map((option) => {
      const replacements: EmailTemplateVariables = {
        studentId: String(student?.student_id),
        files: packet.missing_files,
        link: `<a href="${url}/homeroom/enrollment/${student?.student_id}">${url}/homeroom/enrollment/${student?.student_id}</a>`,
        student: student?.person.first_name,
        studentLegal: student?.person.first_name,
        studentPreferred: student?.person.preferred_first_name,
        parent: student?.parent.person.first_name,
        studentGradeLevel: grade_level || ' ',
        year: yearText,
        applicationYear: yearText,
      }
      option.title = replaceTemplateVariables(option.title, replacements)

      // does option.title exist in emailText
      if (writingBody.includes(option.title.trim())) {
        writingBody = writingBody.replace(`${option.title.trim()}\n\n`, `${option.title.trim()}\n`)
        writingBody = writingBody.replace(option.title.trim(), `-${option.title.trim()}`)
      } else {
        if (writingBody) {
          writingBody = writingBody + '\n'
        }
        writingBody = `${writingBody}-${option.title}\n${option.extraText}`
      }
    })

    writingBody = removeDuplicateNewLines(writingBody)
    const replacements: EmailTemplateVariables = {
      studentId: String(student?.student_id),
      files: packet.missing_files,
      link: `<a href="${url}/homeroom/enrollment/${student?.student_id}">${url}/homeroom/enrollment/${student?.student_id}</a>`,
      student: student?.person.first_name,
      studentLegal: student?.person.first_name,
      studentPreferred: student?.person.preferred_first_name,
      parent: student?.parent.person.first_name,
      studentGradeLevel: grade_level || ' ',
      year: yearText,
      applicationYear: yearText,
    }
    writingBody = replaceTemplateVariables(writingBody, replacements)

    if (writingBody) {
      newNotes += writingBody
    }

    if (oldNotes.length) return newNotes + '\n\n' + oldNotes

    return newNotes + '\n'
  }

  if (showMissingInfoModal)
    return (
      <EmailModal
        handleModem={() => setValue(EnrollmentPacketFormProperty.SHOW_MISSING_INFO_MODAL, false)}
        // @ts-ignore
        title={`Missing Information on ${student.person.first_name}’s Enrollment Packet`}
        // @ts-ignore
        options={emailTemplate && emailTemplate.standard_responses && JSON.parse(emailTemplate?.standard_responses)}
        // @ts-ignore
        handleSubmit={handleEmailSend}
        // @ts-ignore
        setEmailTemplate={setEmailTemplate}
        type={EmailType.MISSING_INFO}
        // @ts-ignore
        setEmailFrom={setEmailFrom}
        // @ts-ignore
        emailFrom={emailFrom}
        // @ts-ignore
        setEmailBodyInfo={setEmailBodyInfo}
        schoolYearId={school_year_id}
        midYear={
          student?.applications?.at(-1)?.school_year_id === school_year_id
            ? student?.applications?.at(-1)?.midyear_application
            : false
        }
      />
    )
  if (showAgeIssueModal) {
    return (
      <EmailModal
        handleModem={() => setValue(EnrollmentPacketFormProperty.SHOW_AGE_ISSUE_MODAL, false)}
        // @ts-ignore
        title={`Age Issue on ${student.person.first_name}’s Enrollment Packet`}
        // @ts-ignore
        options={emailTemplate && emailTemplate.standard_responses && JSON.parse(emailTemplate?.standard_responses)}
        // @ts-ignore
        handleSubmit={handleEmailSend}
        // @ts-ignore
        setEmailTemplate={setEmailTemplate}
        type={EmailType.AGE_ISSUE}
        // @ts-ignore
        setEmailFrom={setEmailFrom}
        // @ts-ignore
        emailFrom={emailFrom}
        // @ts-ignore
        setEmailBodyInfo={setEmailBodyInfo}
        schoolYearId={school_year_id}
        midYear={
          student?.applications?.at(-1)?.school_year_id === school_year_id
            ? student?.applications?.at(-1)?.midyear_application
            : false
        }
      />
    )
  }

  if (showSaveWarnModal)
    return (
      <EnrollmentWarnSaveModal
        onClose={() => {
          setValue(EnrollmentPacketFormProperty.SHOW_VALIDATION_ERRORS, true)
          setValue(EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL, false)
        }}
        onSave={() => {
          onSubmit()
          setValue(EnrollmentPacketFormProperty.SHOW_VALIDATION_ERRORS, false)
          setValue(EnrollmentPacketFormProperty.SHOW_SAVE_WARN_MODAL, false)
        }}
      />
    )

  if (missingInfoAlert) {
    return (
      <Alert
        sx={{
          position: 'relative',
          bottom: '25px',
          marginBottom: '15px',
        }}
        onClose={() => {
          setValue(EnrollmentPacketFormProperty.MISSING_INFO_ALERT, false)
        }}
        severity='error'
      >
        This packet has not yet been submitted
      </Alert>
    )
  }
  // @ts-ignore
  if ((saveAlert && saveAlert.length) || saveError) {
    return (
      <Alert
        sx={{
          position: 'relative',
          bottom: '25px',
          marginBottom: '15px',
        }}
        onClose={() => {
          setValue(EnrollmentPacketFormProperty.SAVE_ALERT, '')
        }}
        severity={saveError ? 'error' : 'success'}
      >
        {saveError || saveAlert}
      </Alert>
    )
  }
  return <div />
}
