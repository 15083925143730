export enum StudentStatus {
  INACTIVE = 4,
  PENDING = 0,
  ACTIVE = 1,
  WITHDRAWN = 2,
  GRADUATED = 3,
  APPLIED = 5,
  ACCEPTED = 6,
  REAPPLIED = 7,
  /**
   * Packet is deleted by Admin
   */
  DELETED = 8,
  INELIGIBLE = 9,
  COMPLETED = 10,
  /**
   * This is not stored DB and used to pass the filter option
   */
  MID_YEAR = 50,
  NO_STATUS = -1,
}

export enum StudentStatusLabel {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  WITHDRAWN = 'Withdrawn',
  GRADUATED = 'Graduated',
  INACTIVE = 'Inactive',
  APPLIED = 'Applied',
  ACCEPTED = 'Accepted',
  REAPPLIED = 'Reapplied',
  DELETED = 'Deleted',
  CONDITIONAL = 'Conditional',
  REQUESTED = 'Requested',
  INELIGIBLE = 'Ineligible',
  COMPLETED = 'Completed',
}

export enum StudentActiveStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
