import { gql } from '@apollo/client'

export const getTestingPreferencesReport = gql`
  query GetTestingPreferencesReport($schoolYear: Int, $search: String, $skip: Int, $sort: String, $take: Int) {
    getTestingPreferencesReport(school_year: $schoolYear, search: $search, skip: $skip, sort: $sort, take: $take) {
      page_total
      total
      results {
        grade_level
        opt_in
        opt_out
        parent_email
        parent_first_name
        parent_id
        parent_last_name
        parent_number
        preference_date
        school_of_enrollment_name
        school_of_enrollment_abbreviation
        student_first_name
        student_id
        student_last_name
        assessment
        unique_id
        regionAbbreviation
      }
    }
  }
`

export const generateOptOutPDFMutation = gql`
  mutation GenerateOptOutForm($optOutFormArgs: OptOutPDFArgs!) {
    generateOptOutForm(OptOutPDFArgs: $optOutFormArgs)
  }
`
