import { MthColor } from '@mth/enums'

export const macScroll = {
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&:hover::-webkit-scrollbar-track': {
    background: MthColor.WHITE,
  },
  // to have a custom scrollbar, similar to the scrollbar in MAC computer
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '10px',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
  // Hide scrollbar thumb when not hovering
  '&::-webkit-scrollbar-thumb:vertical': {
    display: 'none',
  },
  '&:hover::-webkit-scrollbar-thumb:vertical': {
    display: 'block',
  },
}

export const macScrollNotHiding = {
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    background: MthColor.WHITE,
  },
  // to have a custom scrollbar, similar to the scrollbar in MAC computer
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '10px',
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}
