import { isFinite } from 'lodash'
import {
  EMPTY_STRING,
  KINDERGARTEN_LABEL,
  KINDERGARTEN_NUM_VALUE,
  KINDER_ABBREVIATION,
  PRE_KINDERGARTEN_LABEL,
  PRE_KINDERGARTEN_NUM_VALUE,
  PRE_KINDER_ABBREVIATION,
  PRE_KINDER_AGE,
  UNASSIGNED,
} from '@mth/constants'
import { MthTitle } from '@mth/enums'
import { SchoolYear, Student, StudentStatusModel } from '@mth/models'
import { studentStatusTextById } from '@mth/utils/student-status/student-status.util'
import { GRADE_LEVEL_LABEL } from '../../enums/grade-level.enum'
import { StudentsGradeLevel } from '../../enums/grade-levels.enum'
import { StudentStatus } from '../../enums/student-status.enum'
import { toOrdinalSuffix } from '../string/string.util'

export const gradeText = (student: Student, currentYear?: number[], nextYear?: number[]): string => {
  if (!student.grade_levels?.length) {
    return ''
  }

  const yearStatus = student.status.map((studentStatus) => studentStatus.school_year_id)

  if (
    currentYear &&
    yearStatus.includes(currentYear[0]) &&
    (!nextYear ||
      (nextYear &&
        yearStatus.filter((schoolYear) => schoolYear === nextYear[0]).length &&
        !student.status
          .filter((status) => status.school_year_id === currentYear[0])
          .map((student) => student.status)
          .includes(StudentStatus.WITHDRAWN)))
  ) {
    const gradeLevel: string | number | undefined = student.grade_levels
      .filter((gradeLevel) => Number(gradeLevel.school_year_id) === currentYear[0])
      .at(-1)?.grade_level

    if (!gradeLevel) {
      if (student.grade_levels?.length) {
        const gradeLevelDefault: string | number | undefined = student.grade_levels[0].grade_level

        if (!gradeLevelDefault) return ''

        return expandAbbreviationSingleGrade(gradeLevelDefault)
      } else {
        return ''
      }
    } else {
      return expandAbbreviationSingleGrade(gradeLevel)
    }
  } else if (nextYear && student.status.map((studentStatus) => studentStatus.school_year_id).includes(nextYear[0])) {
    const gradeLevel: string | number | undefined = student.grade_levels
      .filter((gradeLevel) => Number(gradeLevel.school_year_id) === nextYear[0])
      .at(-1)?.grade_level
    if (!gradeLevel) return ''

    return expandAbbreviationSingleGrade(gradeLevel)
  }
  const currentYearId = student.current_school_year_status?.school_year_id
  const gradeLevel: string | number | undefined = student.grade_levels
    .filter((gradeLevel) => Number(gradeLevel?.school_year_id) === currentYearId)
    .at(-1)?.grade_level

  if (!gradeLevel) return ''

  if (String(gradeLevel) === String(PRE_KINDERGARTEN_NUM_VALUE) || gradeLevel === PRE_KINDERGARTEN_LABEL)
    return PRE_KINDERGARTEN_LABEL
  // @ts-ignore
  return gradeLevel.toLowerCase().startsWith('k')
    ? StudentsGradeLevel.KINDERGARTEN
    : `${toOrdinalSuffix(Number(gradeLevel))} Grade`
}

export const gradeTextByStatus = (student: Student, schoolYearId: number): string => {
  if (!student.grade_levels?.length) {
    return ''
  }
  const gradeLevel: string | number | undefined = student.grade_levels.find(
    (grade) => grade.school_year_id.toString() === schoolYearId.toString(),
  )?.grade_level

  if (!gradeLevel) return ''

  return expandAbbreviationSingleGrade(gradeLevel)
}

export const getStatusText = (student: Student, schoolYearId: number): string => {
  if (!student.status?.length) {
    return ''
  }
  const studentStatus: StudentStatusModel | undefined = student.status.find(
    (status) => status.school_year_id.toString() === schoolYearId.toString(),
  )

  if (!studentStatus) return ''

  return studentStatusTextById(studentStatus)
}

export const getSoeByYear = (student: Student, schoolYearId: number): string => {
  if (!student?.currentSoe?.length) return UNASSIGNED

  const soe = student.currentSoe.find((item) => item.school_year_id === schoolYearId)

  return soe?.partner?.abbreviation ? soe.partner.abbreviation : UNASSIGNED
}

export const currentGradeText = (student: Student, currentSchoolYear?: SchoolYear[]): string => {
  if (student && !currentSchoolYear) {
    if (!student.grade_levels?.length) {
      return ''
    }
    let gradeLevel = student.grade_levels?.find(
      (item) => item.school_year_id === student.current_school_year_status?.school_year_id,
    )?.grade_level
    if (!gradeLevel && student.grade_levels.length) {
      gradeLevel = student.grade_levels[0].grade_level
    }
    if (gradeLevel) {
      if (gradeLevel === PRE_KINDERGARTEN_LABEL) return PRE_KINDERGARTEN_LABEL
      return gradeLevel.toLowerCase().startsWith('k') ? 'Kindergarten' : `${toOrdinalSuffix(+gradeLevel)} Grade`
    }
  } else if (student && currentSchoolYear) {
    const currentSchoolYearId = currentSchoolYear.map((schoolYear) => schoolYear.school_year_id)
    const studentGradeLevel = student.grade_levels.filter((gradeLevel) =>
      currentSchoolYearId.includes(Number(gradeLevel.school_year_id)),
    )
    if (studentGradeLevel.length === 1) {
      if (studentGradeLevel[0].grade_level === PRE_KINDERGARTEN_LABEL) return PRE_KINDERGARTEN_LABEL
      return studentGradeLevel[0].grade_level.toLowerCase().startsWith('k')
        ? 'Kindergarten'
        : `${toOrdinalSuffix(+studentGradeLevel[0].grade_level)} Grade`
    } else if (studentGradeLevel.length > 1) {
      if (studentGradeLevel[studentGradeLevel.length - 1].grade_level === PRE_KINDERGARTEN_LABEL)
        return PRE_KINDERGARTEN_LABEL
      return studentGradeLevel[studentGradeLevel.length - 1].grade_level.toLowerCase().startsWith('k')
        ? 'Kindergarten'
        : `${toOrdinalSuffix(+studentGradeLevel[studentGradeLevel.length - 1].grade_level)} Grade`
    } else if (student.grade_levels?.length) {
      const gradeLevel = student.grade_levels[0].grade_level
      if (gradeLevel && typeof gradeLevel === 'string' && gradeLevel === PRE_KINDERGARTEN_LABEL)
        return PRE_KINDERGARTEN_LABEL
      return gradeLevel && typeof gradeLevel === 'string' && gradeLevel.toLowerCase().startsWith('k')
        ? 'Kindergarten'
        : `${toOrdinalSuffix(Number(student.grade_levels[0].grade_level))} Grade`
    }
  }
  return ''
}

export const gradeNum = (student: Student | undefined): string => {
  if (!student || !student.grade_levels?.length) {
    return ''
  }
  const gradeLevel = student.grade_levels[student.grade_levels.length - 1]?.grade_level

  if (gradeLevel === undefined) return ''

  return (gradeLevel + '').toLowerCase().startsWith('k') ? 'Kindergarten' : gradeLevel.toString()
}

export const sortGrades = (grades: string, useExtension = false): string => {
  let result = ''
  if (grades) {
    const tempArray = grades.split(',')
    if (tempArray.includes(PRE_KINDERGARTEN_LABEL)) {
      result += `${useExtension ? PRE_KINDERGARTEN_LABEL : PRE_KINDER_ABBREVIATION},`
    }
    if (tempArray.includes('Kindergarten') || tempArray.includes('Kin')) {
      result += `${useExtension ? KINDERGARTEN_LABEL : KINDER_ABBREVIATION},`
    }
    result += tempArray
      .filter(
        (item) =>
          !item.includes('Kin') && !item.includes(PRE_KINDERGARTEN_LABEL) && !item.includes(KINDER_ABBREVIATION),
      )
      .sort((a: string, b: string) => {
        if (Number(a) > Number(b)) {
          return 1
        } else if (Number(a) < Number(b)) {
          return -1
        }
        return 0
      })
      .join(',')
    return result
  } else {
    result = 'Select'
  }
  return result
}

export const gradeSortCompare = (a: string, b: string): number => {
  const kValues = [KINDERGARTEN_LABEL, KINDER_ABBREVIATION]
  const preKValues = [PRE_KINDERGARTEN_LABEL, PRE_KINDER_ABBREVIATION]
  let aVal = Number(a)
  let bVal = Number(b)
  if (kValues.includes(a)) aVal = KINDERGARTEN_NUM_VALUE
  if (kValues.includes(b)) bVal = KINDERGARTEN_NUM_VALUE
  if (preKValues.includes(a)) aVal = PRE_KINDERGARTEN_NUM_VALUE
  if (preKValues.includes(b)) bVal = PRE_KINDERGARTEN_NUM_VALUE
  return Number(aVal) - Number(bVal)
}

export const gradeShortText = (gradeLevel?: number | string | null | undefined): string => {
  switch (String(gradeLevel)) {
    case String(PRE_KINDERGARTEN_NUM_VALUE):
    case String(PRE_KINDERGARTEN_LABEL):
      return PRE_KINDER_ABBREVIATION
    case String(KINDERGARTEN_NUM_VALUE):
    case String(KINDERGARTEN_LABEL):
      return KINDER_ABBREVIATION
    default:
      if (String(gradeLevel).toLowerCase().startsWith('k')) return KINDER_ABBREVIATION
      else return (gradeLevel || '').toString()
  }
}

export const parseGradeLevel = (value?: string | number): string => {
  if (!value) return ''
  if (value === PRE_KINDERGARTEN_LABEL) return `${PRE_KINDERGARTEN_LABEL} (${PRE_KINDER_AGE})`
  if (value === 'OR-K') return 'OR - Kindergarten (5)'
  if (['K', 'Kindergarten', 'Kin'].indexOf(value + '') !== -1) return 'Kindergarten (5)'
  const numberValue = parseInt(value + '')

  if (numberValue === 1) return '1st Grade (6)'
  if (numberValue === 2) return '2nd Grade (7)'
  if (numberValue === 3) return '3rd Grade (8)'
  return `${value}th Grade (${value !== '12' ? numberValue + 5 : `${numberValue + 5}/${numberValue + 6}`})`
}

export const gradeToNumber = (grade: number | string): number => {
  // handle numeric input
  if (typeof grade === 'number') {
    return grade
  }

  // convert to lowercase
  const gradeStr = (grade as string).toLowerCase()

  // handle pre-kindergarten
  if (gradeStr === PRE_KINDERGARTEN_LABEL.toLowerCase() || gradeStr === PRE_KINDER_ABBREVIATION.toLowerCase())
    return PRE_KINDERGARTEN_NUM_VALUE

  // handle kindergarten abbreviations
  const kindergartenAbbr: string[] = ['k', 'kin', 'kind', 'kinder', 'kindergart', 'kindergarten', 'kg']
  if (
    gradeStr !== PRE_KINDERGARTEN_LABEL &&
    gradeStr !== PRE_KINDER_ABBREVIATION &&
    kindergartenAbbr.some((abbr) => gradeStr.includes(abbr))
  ) {
    return KINDERGARTEN_NUM_VALUE
  }

  // remove non-digit characters
  const numericGrade = gradeStr.replace(/\D/g, '')

  // parse grade as integer
  let num: number | undefined = parseInt(numericGrade)

  // If parsing successful, return the number
  if (!isNaN(num)) {
    return num
  }

  // If parsing not successful, check for text based numbers
  type TextNumbersType = {
    [key: string]: number | undefined
    first: number
    second: number
    third: number
    fourth: number
    fifth: number
    sixth: number
    seventh: number
    eighth: number
    ninth: number
    tenth: number
    eleventh: number
    twelfth: number
  }

  const textNumbers: TextNumbersType = {
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    fifth: 5,
    sixth: 6,
    seventh: 7,
    eighth: 8,
    ninth: 9,
    tenth: 10,
    eleventh: 11,
    twelfth: 12,
  }

  num = textNumbers[gradeStr]

  if (num !== undefined) {
    return num
  }

  throw new Error(`Invalid grade: ${grade}`)
}

export const getGradeBySchoolYearId = (student: Student, school_year_id: number): string => {
  const gradeLevel = student.grade_levels.find((item) => item.school_year_id.toString() === school_year_id.toString())
  if (gradeLevel) return gradeLevel.grade_level
  return EMPTY_STRING
}

export function gradeToAbbreviation(grade: string | number, useKinderAbbreviation = false): string {
  if (typeof grade === 'string') {
    if (grade === PRE_KINDERGARTEN_LABEL) return PRE_KINDER_ABBREVIATION
    return grade.toString().toLowerCase().startsWith(GRADE_LEVEL_LABEL.K.toLowerCase())
      ? useKinderAbbreviation
        ? KINDER_ABBREVIATION
        : GRADE_LEVEL_LABEL.KINDERGARTEN
      : grade.toString()
  }

  if (grade === PRE_KINDERGARTEN_NUM_VALUE) {
    return PRE_KINDER_ABBREVIATION
  } else if (grade === KINDERGARTEN_NUM_VALUE) {
    return KINDER_ABBREVIATION
  } else {
    return grade.toString()
  }
}

export function expandAbbreviationGrades(grades: string): string[] {
  return grades.split(',').map((item: string) => {
    if (item.includes(KINDER_ABBREVIATION) && !item.includes(PRE_KINDER_ABBREVIATION)) {
      return KINDERGARTEN_LABEL
    } else if (item.includes(PRE_KINDER_ABBREVIATION)) {
      return PRE_KINDERGARTEN_LABEL
    } else {
      return `${toOrdinalSuffix(Number(item))} ${MthTitle.GRADE}`
    }
  })
}

export function expandAbbreviationSingleGrade(grade: string): string {
  if (grade === PRE_KINDERGARTEN_LABEL || grade === PRE_KINDER_ABBREVIATION) return PRE_KINDERGARTEN_LABEL
  if (grade === '') return ''
  const isKinder = grade && typeof grade === 'string' && grade.toLowerCase().startsWith('k')
  if (!isKinder && !isFinite(Number(grade))) return grade
  // @ts-ignore
  return isKinder ? StudentsGradeLevel.KINDERGARTEN : `${toOrdinalSuffix(Number(grade))} ${MthTitle.GRADE}`
}

export function numToStringGrade(grade: number, ordinalText = true): string {
  switch (grade) {
    case PRE_KINDERGARTEN_NUM_VALUE:
      return PRE_KINDERGARTEN_LABEL
    case KINDERGARTEN_NUM_VALUE:
      return KINDERGARTEN_LABEL
    default:
      if (ordinalText) return `${toOrdinalSuffix(grade)} ${MthTitle.GRADE}`
      else return `${grade}`
  }
}
