import React, { useState } from 'react'
import { Modal, Box } from '@mui/material'
import { MthColor, MthTitle, RoleLabels } from '@mth/enums'
import { ProfileContext } from './ProfileContext'
import { CustomModal } from '../../screens/Admin/SiteManagement/EnrollmentSetting/components/CustomModal/CustomModals'
import { UserProfile } from '../../screens/Admin/UserProfile/UserProfile'

type RefetchType = () => void
type JSONData = string | number | boolean | null | JSONData[] | { [key: string]: JSONData }
type UserData = {
  role: string
  parent_id: number
  student_id: number
  parent: { parent_id: number }
}

export const ProfileProvider: React.FC = ({ children }) => {
  const [store, setStore] = useState(false)
  const [open, setOpen] = useState(false)
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const [data, setData] = useState<UserData>()
  const [refetch, setRefetch] = useState<RefetchType>(() => () => {})
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const profileContext = React.useMemo(
    () => ({
      store,
      setStore,
      hideModal: () => {},
      showModal: (data: JSONData, refetch: RefetchType = () => () => {}) => {
        setData(data as UserData)
        setRefetch(() => () => refetch())
        setOpen(true)
      },
    }),
    [store],
  )
  const handelClose = (value: boolean) => {
    if (value) {
      setOpen(false)
      setStore(false)
      setIsChanged(false)
      refetch()
    } else if (isChanged) {
      setShowConfirmModal(true)
    } else {
      setOpen(false)
      setStore(false)
      setIsChanged(false)
      refetch()
    }
  }
  return (
    <ProfileContext.Provider value={profileContext}>
      {open && (
        <Modal
          open={true}
          onClose={() => handelClose(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box>
            <UserProfile
              handleClose={handelClose}
              role={data?.role}
              student_id={data?.student_id}
              parent_id={data?.role === RoleLabels.PARENT ? data?.parent_id : data?.parent.parent_id}
              setIsChanged={setIsChanged}
            />
          </Box>
        </Modal>
      )}
      {showConfirmModal && (
        <CustomModal
          title={MthTitle.UNSAVED_TITLE}
          description={MthTitle.UNSAVED_DESCRIPTION}
          cancelStr='Cancel'
          confirmStr='Yes'
          // @ts-ignore
          backgroundColor={MthColor.WHITE}
          onClose={() => {
            setShowConfirmModal(false)
          }}
          onConfirm={() => {
            setShowConfirmModal(false)
            setIsChanged(false)
            setOpen(false)
            setStore(false)
          }}
        />
      )}
      {children}
    </ProfileContext.Provider>
  )
}
