import { gql } from '@apollo/client'

export const saveIntentToReEnrollAnswer = gql(`mutation SaveIntentToReEnrollAnswer(
    $answerIntentToReenroll: Boolean!
    $schoolyearId: Float!
    $studentId: Float!
    $reasonIntentToReenroll: String
  ) {
    saveIntentToReEnrollAnswer(
      answer_intent_to_reenroll: $answerIntentToReenroll
      schoolyear_id: $schoolyearId
      student_id: $studentId
      reason_intent_to_reenroll: $reasonIntentToReenroll
    ) {
      response
    }
  }`)
