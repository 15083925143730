import React from 'react'
import TodayIcon from '@mui/icons-material/Today'
import { IconButton, Stack, TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isNil } from 'lodash'
import { MYSQL_DATE_FORMAT } from '@mth/constants'
import { endOfDay, startOfDay, toUTCString, utcToTimezoned } from '@mth/utils'
import { MthDatePickerProps } from './types'

dayjs.extend(utc)

export const MthDatePicker: React.FC<MthDatePickerProps> = ({
  date,
  label,
  dateFormat = MYSQL_DATE_FORMAT,
  minDate,
  maxDate,
  showCalendarIcon,
  disabled = false,
  timezone = null,
  dayEnd = false,
  handleChange,
}) => {
  // The x-date-picker works correctly only at midnight
  const convert2MidNight = (val: Date | string) => {
    if (!isNil(timezone)) return utcToTimezoned(val, timezone)
    const tempDate = typeof val === 'string' ? new Date(val) : val
    return dayjs(tempDate)
      .add(tempDate.getTimezoneOffset() + 60, 'minutes')
      .toDate()
  }

  // Should store local date string
  const convert2LocalDate = (val: Date) => {
    if (!isNil(timezone)) return dayEnd ? toUTCString(endOfDay(val), timezone) : toUTCString(startOfDay(val), timezone)
    return dayjs(val)
      .subtract(val.getTimezoneOffset() - 60, 'minutes')
      .utc()
      .format(dateFormat)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} marginRight={8}>
        <MobileDatePicker
          disabled={disabled}
          label={label || 'Date'}
          inputFormat='MM/dd/yyyy'
          value={date && convert2MidNight(date)}
          minDate={minDate && convert2MidNight(minDate)}
          maxDate={maxDate && convert2MidNight(maxDate)}
          onChange={(val) => {
            handleChange(val ? convert2LocalDate(val) : null)
          }}
          renderInput={(params) =>
            !showCalendarIcon ? (
              <TextField {...params} />
            ) : (
              <TextField
                {...params}
                variant='outlined'
                margin='normal'
                fullWidth
                helperText=''
                InputProps={{
                  endAdornment: (
                    <IconButton size='small'>
                      <TodayIcon />
                    </IconButton>
                  ),
                }}
              />
            )
          }
        />
      </Stack>
    </LocalizationProvider>
  )
}
