import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Button, Modal } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import { EPIC_3537_STORY_5147 } from '@mth/constants'
import { MthColor, TEXT_SIZE } from '@mth/enums'
import { useStyles } from './styles'
import { Paragraph } from '../../core/components/shared/Paragraph/Paragraph'
import { Subtitle } from '../../core/components/shared/Subtitle/Subtitle'

type CustomConfirmModalType = {
  header: string
  headerFontSize?: TEXT_SIZE
  content: string
  confirmBtnTitle?: string
  cancelBtnTitle?: string
  maxWidth?: number
  height?: number
  padding?: string
  handleConfirmModalChange: (isOk: boolean) => void
}

export const CustomConfirmModal: React.FC<CustomConfirmModalType> = ({
  header,
  headerFontSize,
  content,
  confirmBtnTitle = 'Yes',
  cancelBtnTitle = 'Cancel',
  maxWidth = 441,
  height = 295,
  padding = '32px',
  handleConfirmModalChange,
}) => {
  const epic3537story5147 = useFlag(EPIC_3537_STORY_5147)
  const classes = useStyles

  const handleConfirm = () => {
    handleConfirmModalChange(true)
  }

  const handleCancel = () => {
    handleConfirmModalChange(false)
  }

  return (
    <Modal open={true}>
      <Box sx={{ ...classes.modalCard, maxWidth: maxWidth, height: height, paddingX: padding }}>
        <Box sx={classes.header}>
          <Subtitle fontWeight='700' size={headerFontSize}>
            {header}
          </Subtitle>
        </Box>
        <Box sx={classes.content}>
          {/* @ts-ignore */}
          <ErrorOutlineIcon style={classes.errorOutline} />
          <Paragraph
            size='large'
            // @ts-ignore
            color={MthColor.SYSTEM_01}
            textAlign='center'
            sx={epic3537story5147 ? { whiteSpace: 'pre-line' } : {}}
          >
            {content}
          </Paragraph>
          <Box display='flex' flexDirection='row'>
            <Button variant='contained' disableElevation sx={classes.cancelButton} onClick={handleCancel}>
              {cancelBtnTitle}
            </Button>
            <Button variant='contained' disableElevation sx={classes.submitButton} onClick={handleConfirm}>
              {confirmBtnTitle}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
