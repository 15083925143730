export enum EnrollmentHeadCellsId {
  SUBMITTED = 'submitted',
  STATUS = 'status',
  DEADLINE = 'deadline',
  STUDENT = 'student',
  GRADE = 'grade',
  PARENT = 'parent',
  STUDENT_STATUS = 'studentStatus',
  EMAILED = 'emailed',
  LAST_UPDATED = 'lastUpdated',
  LAST_SUBMITTED = 'lastSubmitted',
}

export enum EnrollmentHeadCellsLabel {
  DATE = 'Date',
  STATUS = 'Status',
  DEADLINE = 'Deadline',
  STUDENT = 'Student',
  GRADE = 'Grade',
  PARENT = 'Parent',
  PACKET = 'Packet',
  EMAILED = 'Emailed',
  LAST_UPDATED = 'Last Updated',
  LAST_SUBMITTED = 'Last Submitted',
}

export enum EnrollmentResultsProps {
  ID = 'id',
  SUBMITTED = 'submitted',
  STATUS = 'status',
  DEADLINE = 'deadline',
  STUDENT = 'student',
  GRADE_LEVEL = 'grade_level',
  PARENT = 'parent',
  STUDENT_STATUS = 'studentStatus',
  EMAILED = 'emailed',
  DELETE = 'delete',
  LAST_UPDATED = 'lastUpdated',
  LAST_SUBMITTED = 'lastSubmitted',
}
