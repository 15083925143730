export interface Reimbursement {
  student_id: number
  reimbursement_request_id: number

  submitted: string
  amount: string
  student: string
  grade: string
  status: string
  paid_ordered: string | undefined
  paid: string
  ordered: string
  category: string
  period: string
  rb_type: string
  approved_ordered_by: string
  additional_information?: string
  periods?: string
}

export const REIMBURSEMENT_TABLE_HEAD = {
  SUBMITTED: 'Submitted',
  AMOUNT: 'Amount',
  RB_STUDENT: 'Student',
  GRADE: 'Grade',
  RB_STATUS: 'Status',
  PAID_ORDERED: 'Paid/Ordered',
  CATEGORY: 'Category',
  PERIOD: 'Period',
  RB_TYPE: 'Type',
  APPROVED_ORDERED_BY: 'Approved By',
}
