import dayjs from 'dayjs'
import { StudentStatus } from '@mth/enums'
import { StudentStatusModel } from '@mth/models'

export const studentStatusText = (studentStatusData: StudentStatusModel | undefined, showDate = false): string => {
  const dateStr = showDate ? ` ${dayjs(studentStatusData?.date_updated).format('MM/DD/YYYY')}` : ''
  switch (studentStatusData?.status) {
    case StudentStatus.APPLIED: {
      return `Applied${dateStr}`
    }
    case StudentStatus.REAPPLIED: {
      return `Applied (re-apply)${dateStr}`
    }
    case StudentStatus.ACCEPTED: {
      return `Accepted${dateStr}`
    }
    case StudentStatus.PENDING: {
      return `Pending${dateStr}`
    }
    case StudentStatus.ACTIVE: {
      return `Active${dateStr}`
    }
    case StudentStatus.WITHDRAWN: {
      return `Withdrawn${dateStr}`
    }
    case StudentStatus.COMPLETED: {
      return `Completed${dateStr}`
    }
    case StudentStatus.GRADUATED: {
      return `Graduated${dateStr}`
    }
  }

  return ''
}

export const formatDate = (date: Date): string => {
  return date.toLocaleDateString('en-US')
}

export const studentStatusTextById = (studentStatusData: StudentStatusModel): string => {
  const statusDate = new Date(studentStatusData.date_updated)

  switch (studentStatusData?.status) {
    case StudentStatus.PENDING: {
      return `Pending ${formatDate(statusDate)}`
    }
    case StudentStatus.ACTIVE: {
      return `Active ${formatDate(statusDate)}`
    }
    case StudentStatus.WITHDRAWN: {
      return `Withdrawn ${formatDate(statusDate)}`
    }
    case StudentStatus.GRADUATED: {
      return `Graduated ${formatDate(statusDate)}`
    }
    case StudentStatus.APPLIED: {
      return `Applied ${formatDate(statusDate)}`
    }
    case StudentStatus.ACCEPTED: {
      return `Accepted ${formatDate(statusDate)}`
    }
    case StudentStatus.REAPPLIED: {
      return `Reapplied ${formatDate(statusDate)}`
    }
    case StudentStatus.COMPLETED: {
      return `Completed ${formatDate(statusDate)}`
    }
  }
  return ''
}
