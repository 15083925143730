import { EmailTemplateVariables } from '@mth/types/email-template'
import { camelToSnakeCase } from '../string/string.util'

export const replaceTemplateVariables = (emailInformation: string, variables: EmailTemplateVariables) => {
  let emailData = emailInformation.toString()

  const updatedVariables = { ...variables }

  if (updatedVariables.studentLegal && !updatedVariables.studentPreferred) {
    updatedVariables.studentPreferred = updatedVariables.studentLegal
  }

  for (const [variable, value] of Object.entries(updatedVariables)) {
    const snakeCaseVariable = camelToSnakeCase(variable)
    const regex = new RegExp(`\\[${snakeCaseVariable.toUpperCase()}\\]`, 'g')
    emailData = emailData.replace(regex, value)
  }

  return emailData
}
