import { DropDownItem } from '@mth/core/components/shared/DropDown/types'
import { ScheduleStatus } from '../enums/schedule-status.enums'

export const SCHEDULE_STATUS_OPTIONS: DropDownItem[] = [
  {
    label: ScheduleStatus.NOT_SUBMITTED,
    value: ScheduleStatus.NOT_SUBMITTED,
  },
  {
    label: ScheduleStatus.SUBMITTED,
    value: ScheduleStatus.SUBMITTED,
  },
  {
    label: ScheduleStatus.RESUBMITTED,
    value: ScheduleStatus.RESUBMITTED,
  },
  {
    label: ScheduleStatus.UPDATES_REQUIRED,
    value: ScheduleStatus.UPDATES_REQUIRED,
  },
  {
    label: ScheduleStatus.UPDATES_REQUESTED,
    value: ScheduleStatus.UPDATES_REQUESTED,
  },
  {
    label: ScheduleStatus.ACCEPTED,
    value: ScheduleStatus.ACCEPTED,
  },
]

export const COMPONENT_SEPARATOR = '-'
export const SLUG_MIDYEAR = 'midyear'
