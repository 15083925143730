/**
 * It should be synced with the database.
 */
export enum RoleLevel {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  TEACHER_ASSISTANT = 3,
  SCHOOL_PARTNER = 12,
  STUDENT = 13,
  OBSERVER = 14,
  PARENT = 15,
  TEACHER = 16,
}

export enum RoleLabels {
  PARENT = 'Parent',
  STUDENT = 'Student',
  OBSERVER = 'Observer',
  TEACHER = 'Teacher',
}
