import { Theme } from '@mui/material/styles'

export const containerBoxStyles = { position: 'relative' }

export const progressCircleContainerStylesNoCentered = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: (theme: Theme): number => theme.zIndex.drawer + 10,
}

export const progressCircleContainerStylesCentered = {
  ...progressCircleContainerStylesNoCentered,
  alignItems: 'center',
}

export const progressCircleStyles = { 'svg circle': { stroke: 'url(#mth_loading_gradient)' } }
