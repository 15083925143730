import React from 'react'
import { Typography } from '@mui/material'
import { TEXT_SIZE } from '@mth/enums'
import { TypographyProps } from '../../../types/TypographyProps'

export const Subtitle: React.FC<TypographyProps> = ({
  testId,
  textAlign,
  children,
  size,
  color,
  fontWeight,
  sx,
  onClick,
  className,
  lineHeight,
}) => {
  const fontSize = () =>
    size === TEXT_SIZE.EXTRA_LARGE ? 25 : size === TEXT_SIZE.LARGE ? 20 : size === TEXT_SIZE.MEDIUM ? 18 : 16

  return (
    <Typography
      data-testid={testId}
      textAlign={textAlign}
      sx={sx}
      fontWeight={fontWeight}
      color={color}
      fontSize={fontSize}
      onClick={onClick}
      className={className}
      lineHeight={lineHeight}
    >
      {children}
    </Typography>
  )
}
