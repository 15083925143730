import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useFlag } from '@unleash/proxy-client-react'
import { isNil } from 'lodash'
import { EPIC_2209_STORY_3780, UNASSIGNED, UNASSIGN_SIGNAL } from '@mth/constants'
import { SOEOperations } from '@mth/enums'
import { assignStudentToSOEGql, transferStudentSOEMutation } from '@mth/screens/Admin/SiteManagement/services'
import { generateOptOutPDFMutation } from '@mth/screens/Admin/TestingPreference/services'
import { generateSoeTransferPdf } from '@mth/services'

type StudentBasicInfo = {
  studentId: number | string
  name: string
}

export type useSoeOperationsType = {
  transferStudentsSOE: (
    schoolPartnerIdToTransfer: string,
    schoolYearId: number,
    studentIds: string[],
    studentList: StudentBasicInfo[],
    shouldGenerateTransferForm?: boolean,
  ) => Promise<void>
  assignStudentsSOE: (
    schoolPartnerIdToAssign: string,
    schoolYearId: number,
    studentIds: string[],
    studentList: StudentBasicInfo[],
  ) => Promise<void>
  operation: SOEOperations | undefined
  operationProgress: number
  operationErrors: string[]
  clearSoeOperation: () => void
}

export const useSoeOperations = (): useSoeOperationsType => {
  const epic2209story3780 = useFlag(EPIC_2209_STORY_3780)
  const [operation, setOperation] = useState<SOEOperations | undefined>()
  const [operationProgress, setOperationProgress] = useState<number>(-1)
  const [operationErrors, setOperationErrors] = useState<string[]>([])

  const [transferStudentsSOEMutation] = useMutation(transferStudentSOEMutation)
  const [generateOptOutPDF] = useMutation(generateOptOutPDFMutation)
  const [assignStudentToSOEMutation] = useMutation(assignStudentToSOEGql)

  const transferStudentsSOE = async (
    schoolPartnerIdToTransfer: string, // This param could be a number or could be UNASSIGNED.toLowerCase()
    schoolYearId: number,
    studentIds: string[],
    studentList: StudentBasicInfo[],
    shouldGenerateTransferForm = false,
  ) => {
    setOperation(SOEOperations.TRANSFER)
    setOperationProgress(0)
    setOperationErrors([])
    for (const [index, studentId] of studentIds.map((item) => parseInt(item)).entries()) {
      const student = studentList.find((i) => String(i.studentId) === String(studentId))
      const schoolPartnerToTransferId = Number(schoolPartnerIdToTransfer)
      const studentNameId = !isNil(student) ? student.name : `${studentId}`
      try {
        const response = await transferStudentsSOEMutation({
          variables: {
            assignStudentToSoeInput: {
              school_partner_id: schoolPartnerToTransferId,
              school_year_id: schoolYearId,
              student_ids: [studentId],
            },
          },
        })
        if (response.data.transferStudentSOE.successStudentsIds[0] === studentId) {
          await generateOptOutPDF({
            variables: {
              optOutFormArgs: {
                school_year: schoolYearId,
                optOutStudentIds: [studentId],
              },
            },
          })
          if (epic2209story3780 && shouldGenerateTransferForm) await generateSoeTransferPdf(schoolYearId, studentId)
        } else {
          console.error(`Error transfering student: ${studentNameId}`)
          setOperationErrors((opErrors) => [...opErrors, `Error transfering student: ${studentNameId}`])
        }
        setOperationProgress(((index + 1) / studentIds.length) * 100)
      } catch (error) {
        console.error(error)
        setOperationErrors((opErrors) => [...opErrors, `Error transfering student: ${studentNameId}`])
      }
    }
  }

  const assignStudentsSOE = async (
    schoolPartnerIdToAssign: string, // This param could be a number or could be UNASSIGNED.toLowerCase()
    schoolYearId: number,
    studentIds: string[],
    studentList: StudentBasicInfo[],
  ) => {
    setOperation(SOEOperations.ASSIGN)
    setOperationProgress(0)
    setOperationErrors([])
    for (const [index, studentId] of studentIds.map((item) => parseInt(item)).entries()) {
      const student = studentList.find((i) => String(i.studentId) === String(studentId))
      const studentNameId = !isNil(student) ? student.name : `${studentId}`
      try {
        await assignStudentToSOEMutation({
          variables: {
            assignStudentToSoeInput: {
              school_partner_id:
                schoolPartnerIdToAssign === UNASSIGNED.toLowerCase()
                  ? UNASSIGN_SIGNAL
                  : parseInt(schoolPartnerIdToAssign),
              school_year_id: schoolYearId,
              student_ids: [studentId],
            },
          },
        })
        await generateOptOutPDF({
          variables: {
            optOutFormArgs: {
              school_year: schoolYearId,
              optOutStudentIds: [studentId],
            },
          },
        })
        setOperationProgress(((index + 1) / studentIds.length) * 100)
      } catch (error) {
        console.error(error)
        setOperationErrors((opErrors) => [...opErrors, `Error assigning student: ${studentNameId}`])
      }
    }
  }

  const clearSoeOperation = () => setOperation(undefined)

  return {
    transferStudentsSOE,
    assignStudentsSOE,
    operation,
    operationProgress,
    operationErrors,
    clearSoeOperation,
  }
}
