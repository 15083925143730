import React from 'react'
import { Alert as AlertMUI, Snackbar } from '@mui/material'
import { useRecoilState } from 'recoil'
import { alertState } from '@mth/providers/Store/State'

export const MthAlert: React.FC = () => {
  const [alertSetting, setAlertSetting] = useRecoilState(alertState)

  const handleCloseAlert = () => {
    setAlertSetting({ ...alertSetting, message: '', show: false })
  }

  return (
    <Snackbar
      open={alertSetting.show}
      autoHideDuration={3000}
      onClose={handleCloseAlert}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <AlertMUI onClose={handleCloseAlert} severity={alertSetting.type}>
        {alertSetting.message}
      </AlertMUI>
    </Snackbar>
  )
}
