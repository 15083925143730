import { useCallback, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { reloadComponentState } from '@mth/providers/Store/State'

export function useReload(): [() => void, boolean] {
  const [reloading, setReloading] = useRecoilState(reloadComponentState)
  const reload = useCallback(() => {
    setReloading(true)
  }, [setReloading])

  useEffect(() => {
    if (reloading) {
      setReloading(false)
    }
  }, [reloading, setReloading])

  return [reload, reloading]
}
