export enum datePatterns {
  UNIX_TIMESTAMP = 'T',
  HOUR_MINUTE = 'HH:mm',
  ZMONTH_ZDAY_4DYEAR = 'MM/dd/yyyy', // 2-digit month / 2-digit day / 4-digit year
  MONTH_NAME_ORDINAL_DAY_4DYEAR = 'MMMM do, yyyy', // full month name day with ordinal number, 4-digit year (e.g. "April 1st, 1999")
  MONTH_NAME_DAY_4DYEAR = 'MMMM d, yyyy', // full month name day with ordinal number, 4-digit year (e.g. "April 1st, 1999")
  ABRV_MONTH_NAME_ORDINAL_DAY_4DYEAR = 'MMM do, yyyy', // abbreviated month name day with ordinal number, 4-digit year (e.g. "Apr 1st, 1999")
  ZMONTH_ZDAY_2DYEAR = 'MM/dd/yy', // 2-digit month / 2-digit day / 2-digit year
  MONTH_DAY_4DYEAR = 'M/d/yyyy', // 1-2 digit month / 1-2 digit day / 4-digit year
  FULL_YEAR = 'yyyy',
  SHORT_YEAR = 'yy',
  MONTH = 'L',
  DAY = 'd',
  DAY_OF_WEEK = 'c',
  YEAR_ZMONTH_ZDAY = 'yyyy-MM-dd',
  MONTH_NAME_DAY = 'MMMM dd',
  TIME_12_HOURS = 'hh:mm a',
  EEE = 'EEE',
  EEEE = 'EEEE',
  MM_DOT_DD = 'MM.dd',
  MONTH_NAME_4DYEAR = 'MMMM yyyy',
  FULL_DAY_NAME = 'cccc',
  MYSQL = 'yyyy-MM-dd HH:mm:ss',
  SHORT_MONTH_YEAR = 'MMM yyyy',
  SHORT_MONTH_DAY = 'MMM dd',
  USMONTH_DAY_YEAR = 'MM-dd-yyyy',
}
