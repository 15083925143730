import React, { useEffect, useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { EMPTY_STRING, isDecimal, isNumber, isPrice } from '@mth/constants'

export type MthNumberInputProps = TextFieldProps & {
  numberType: 'price' | 'numeric' | 'decimal'
  value: number | null | undefined
  onChangeValue: (value: number | null) => void
  inputAdornmentColor?: string
}

export const MthNumberInput = (props: MthNumberInputProps): React.ReactElement => {
  const { numberType, InputProps, value: defaultValue, onChangeValue, inputAdornmentColor, ...otherProps } = props
  const [value, setValue] = useState<string>('')

  const handleChange = (newValue: string) => {
    switch (numberType) {
      case 'price': {
        if (!newValue || isPrice.test(newValue)) {
          setValue(newValue)
          onChangeValue(newValue === EMPTY_STRING ? null : Number(newValue) === 0 ? 0 : Number(newValue) || null)
        }
        break
      }
      case 'numeric': {
        if (!newValue || isNumber.test(newValue)) {
          setValue(newValue)
          onChangeValue(Number(newValue) || null)
        }
        break
      }
      case 'decimal': {
        if (!newValue || isDecimal.test(newValue)) {
          if (newValue.endsWith('.') && Number(newValue) === defaultValue) {
            setValue(newValue)
          } else {
            onChangeValue(Number(newValue) || null)
          }
        }
        break
      }
    }
  }

  useEffect(() => {
    setValue(defaultValue === 0 ? '0' : (defaultValue || '').toString())
  }, [defaultValue])

  return (
    <TextField
      InputProps={{
        startAdornment:
          numberType === 'price' ? (
            <InputAdornment position='start'>
              <p style={inputAdornmentColor ? { color: inputAdornmentColor } : {}}>$</p>
            </InputAdornment>
          ) : (
            ''
          ),
        ...InputProps,
      }}
      value={value || ''}
      onChange={(event: { target: { value: string } }) => {
        handleChange(event?.target?.value)
      }}
      {...otherProps}
      type='text'
    />
  )
}

export default MthNumberInput
