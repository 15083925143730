import React, { useEffect, useState } from 'react'
import { CssBaseline } from '@mui/material'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { FlagProvider } from '@unleash/proxy-client-react'
import { BrowserRouter as Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import WebFont from 'webfontloader'
import { theme } from '@mth/utils'
import { MthLoading } from './components/MthLoading/MthLoading'
import { UserLeaveConfirmation } from './components/UserLeaveConfirmation/UserLeaveConfirmation'
import { MthAlert } from './core/components/shared/Alert/MthAlert'
import { MthColor } from './core/enums/color.enum'
import { ApolloProvider } from './providers/ApolloProvider/ApolloProvider'
import { AuthProvider } from './providers/AuthProvider/AuthProvider'
import BackStackProvider from './providers/BackStackProvider/BackStackProvider'
import { ProfileProvider } from './providers/ProfileProvider/ProfileProvider'
import { CurrentSchoolYearProvider } from './providers/SchoolYearProvider/CurrentSchoolYearProvider'
import { TabContext, TabInfo, UserContext, UserInfo } from './providers/UserContext/UserProvider'
import { Root } from './root/Root'
import './config/datadog-rum-config'

//moment.tz.setDefault('MST')

declare global {
  interface ImportMeta {
    env: {
      MODE: string
      VITE_PUBLIC_APP_VERSION: string
      VITE_PUBLIC_APP_VERSION_POLLING_RATE: string
      VITE_PUBLIC_API_URL: string
      VITE_PUBLIC_API_V3_URL: string
      VITE_PUBLIC_API_USERS_URL: string
      VITE_PUBLIC_S3_URL: string
      VITE_PUBLIC_S3_UPLOAD: string
      VITE_PUBLIC_MEDIA_SERVICE_URL: string
      VITE_PUBLIC_S3_STUDENT_RECORD_FILES_DOWNLOAD: string
      VITE_PUBLIC_COUNTIES_TEMPLATE: string
      VITE_PUBLIC_SCHOOL_DISTRICT_TEMPLATE: string
      VITE_PUBLIC_BASE_S3_UPLOAD_URL: string
      VITE_PUBLIC_BASE_S3_IMAGE_URL: string
      VITE_PUBLIC_WEB_URL: string
      VITE_PUBLIC_FEATURE_FLAG_URL: string
      VITE_PUBLIC_FEATURE_FLAG_CLIENT_KEY: string
      VITE_PUBLIC_GOOGLE_ANALYTICS_ID: string
      VITE_PUBLIC_FEATURE_FLAG_APP_NAME: string
      VITE_PUBLIC_FEATURE_FLAG_ENV: string
      VITE_PUBLIC_APP_STAGE: string
      VITE_PUBLIC_APP_ENVIRONMENT: string
      VITE_PUBLIC_DATADOG_APPLICATION_ID: string // secret injected in the github workflow for testing environments
      VITE_PUBLIC_DATADOG_CLIENT_TOKEN: string // secret injected in the github workflow for testing environments
      VITE_PUBLIC_HYPERWALLET_URL: string
      VITE_PUBLIC_FEATURE_FLAG_REFRESH_INTERVAL: number
      VITE_PUBLIC_MAINTENANCE_PAGE_POLLING: number
      VITE_PUBLIC_MAINTENANCE_PAGE_URL: string
      VITE_PUBLIC_MB_SIZE_LIMIT: number
      NODE_ENV: string
    }
  }
}

export const App: React.FC = () => {
  const [me, setMe] = useState<UserInfo | null>(null)
  const [tab, setTab] = useState<TabInfo | null>(null)
  const [visitedTabs, setVisitedTabs] = useState<number[] | null>([])
  // const [confirmOpen, setConfirmOpen] = useState(true);
  const userContext = React.useMemo(
    () => ({
      me,
      setMe,
    }),
    [me],
  )

  const tabContext = React.useMemo(
    () => ({
      tab,
      setTab,
      visitedTabs,
      setVisitedTabs,
    }),
    [tab, visitedTabs],
  )

  useEffect(() => {
    const gaScript = document.createElement('script')
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_PUBLIC_GOOGLE_ANALYTICS_ID}`
    gaScript.async = true

    const gaConfigScript = document.createElement('script')
    gaConfigScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${import.meta.env.VITE_PUBLIC_GOOGLE_ANALYTICS_ID}');

    `
    document.head.appendChild(gaScript)
    document.head.appendChild(gaConfigScript)
  }, [])

  const featureFlagConfig = {
    url: import.meta.env.VITE_PUBLIC_FEATURE_FLAG_URL, // or https://UNLEASH_HOSTNAME/api/frontend
    clientKey: import.meta.env.VITE_PUBLIC_FEATURE_FLAG_CLIENT_KEY,
    refreshInterval: import.meta.env.VITE_PUBLIC_FEATURE_FLAG_REFRESH_INTERVAL ?? 300,
    appName: import.meta.env.VITE_PUBLIC_FEATURE_FLAG_APP_NAME,
    environment: import.meta.env.VITE_PUBLIC_FEATURE_FLAG_ENV,
  }

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['VisbyCF'],
        urls: ['/fonts.css'],
      },
    })
  }, [])
  return (
    <FlagProvider config={featureFlagConfig}>
      <Router
        getUserConfirmation={(message, callback) => {
          return UserLeaveConfirmation(
            // @ts-ignore
            message,
            callback,
            // confirmOpen,
            // setConfirmOpen
          )
        }}
      >
        <BackStackProvider>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <UserContext.Provider value={userContext}>
                <ApolloProvider>
                  {/* @ts-ignore */}
                  <TabContext.Provider value={tabContext}>
                    <RecoilRoot>
                      <ProfileProvider>
                        <CurrentSchoolYearProvider regionId={me?.userRegion?.at(-1)?.region_id ?? 1}>
                          <CssBaseline />
                          <Root />
                          <MthLoading />
                          <MthAlert />
                          <svg width={0} height={0}>
                            <defs>
                              <linearGradient id='mth_loading_gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
                                {/* @ts-ignore */}
                                <stop offset='30%' stopColor={MthColor.MTHBLUE} />
                                {/* @ts-ignore */}
                                <stop offset='100%' stopColor={MthColor.MTHPURPLE} />
                              </linearGradient>
                            </defs>
                          </svg>
                        </CurrentSchoolYearProvider>
                      </ProfileProvider>
                    </RecoilRoot>
                  </TabContext.Provider>
                </ApolloProvider>
              </UserContext.Provider>
            </AuthProvider>
          </ThemeProvider>
        </BackStackProvider>
      </Router>
    </FlagProvider>
  )
}
