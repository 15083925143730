import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useFlag } from '@unleash/proxy-client-react'
import { CustomModal } from '@mth/components/CustomModal/CustomModals'
import { PhoneInput } from '@mth/components/PhoneInput/PhoneInput'
import { EPIC_275_STORY_5395, STATES_WITH_ABBREVIATION, USER_ID, US_STATES } from '@mth/constants'
import { DropDown } from '@mth/core/components/shared/DropDown/DropDown'
import { DropDownItem } from '@mth/core/components/shared/DropDown/types'
import { Paragraph } from '@mth/core/components/shared/Paragraph/Paragraph'
import { Subtitle } from '@mth/core/components/shared/Subtitle/Subtitle'
import { MthColor, MthTitle, TEXT_SIZE } from '@mth/enums'
import { SchoolDistrict } from '@mth/models'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { ReimbursementModal } from '@mth/screens/Admin/Reimbursements/ReimbursementModal'
import { getSchoolDistrictCode } from '@mth/utils'
import { testIDs } from './__tests__/ParentProfile.testIds'
import { ParentProfileProps } from './types'
import { HomeroomResourceModal } from '../components/NewUserModal/HomeroomResourceModal'
import { FamilyNotesAndStudentNotes } from '../components/Share/FamilyNotesAndStudentNotes'
import { getCountiesByRegionId, getSchoolDistrictsByRegionId } from '../services'
import { useStyles } from '../styles'

export const ParentProfile: React.FC<ParentProfileProps> = ({
  userInfo,
  setUserInfo,
  phoneInfo,
  setPhoneInfo,
  notes,
  setNotes,
  studentNotes,
  setStudentNotes,
  applicationState,
  familyStudentIds,
  currentSchoolYear,
  nextSchoolYear,
}) => {
  const { me } = useContext(UserContext)
  const [preferedFirstName, setPreferredFirstName] = useState('')
  const [preferedLastName, setPreferredLastName] = useState('')

  const [legalFirstName, setLegalFirstName] = useState('')
  const [legalMiddleName, setLegalMiddleName] = useState('')
  const [legalLastName, setLegalLastName] = useState('')

  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [city, setCity] = useState('')
  const [zip, setZip] = useState('')
  const [street1, setStreet1] = useState('')
  const [street2, setStreet2] = useState('')
  const [county, setCounty] = useState(0)
  const [school_district, setSchoolDistrict] = useState('')
  // @ts-ignore
  const [state, setState] = useState<string>(userInfo?.address?.state ? '' : STATES_WITH_ABBREVIATION[applicationState])
  const [canMessage, setCanMessage] = useState(false)
  const [showReimbursementModal, setShowReimbursementModal] = useState<boolean>(false)
  const classes = useStyles
  const epic275story5395 = useFlag(EPIC_275_STORY_5395)

  const { loading: countyLoading, data: countyData } = useQuery(getCountiesByRegionId, {
    variables: { regionId: Number(me?.selectedRegionId) },
    fetchPolicy: 'network-only',
  })

  const [counties, setCounties] = useState([])
  const [clickedEvent, setClickedEvent] = useState({})
  const [warningPopup, setWarningPopup] = useState(false)
  const [ableToEdit, setAbleToEdit] = useState(false)
  const [selectedDrop, setSelectedDrop] = useState('')
  const [selectedValue, setSelectedValue] = useState('')
  const [showHomeroomResourceModal, setShowHomeroomResourceModal] = useState(false)

  const selectedYearId = useMemo(() => {
    return currentSchoolYear?.school_year_id || nextSchoolYear?.school_year_id
  }, [currentSchoolYear, nextSchoolYear])

  const setCancelWarningPopup = () => {
    setWarningPopup(false)
    setAbleToEdit(false)
  }
  const toggleHomeroomResourceModal = () => setShowHomeroomResourceModal(!showHomeroomResourceModal)
  const setConfirmWarningPopup = () => {
    setWarningPopup(false)
    if (selectedDrop == 'County') {
      setCounty(Number(selectedValue))
      // @ts-ignore
      setUserInfo({ ...userInfo, address: { ...userInfo.address, county_id: Number(selectedValue) } })
    } else if (selectedDrop == 'School District') {
      setSchoolDistrict(selectedValue)
      setUserInfo({
        // @ts-ignore
        ...userInfo,
        address: {
          // @ts-ignore
          ...userInfo.address,
          school_district: selectedValue,
          school_district_code: getSchoolDistrictCode(schoolDistrictsData?.schoolDistrict, selectedValue),
        },
      })
    } else if (selectedDrop == 'State') {
      setState(selectedValue)
      // @ts-ignore
      setUserInfo({ ...userInfo, address: { ...userInfo.address, state: selectedValue } })
    } else {
      setAbleToEdit(true)
    }
    setSelectedValue('')
    setSelectedDrop('')
  }

  useEffect(() => {
    // @ts-ignore
    if (ableToEdit == true) clickedEvent.target.focus()
  }, [ableToEdit])

  // @ts-ignore
  const setFocused = (event) => {
    // @ts-ignore
    if (!ableToEdit || clickedEvent.target != event.target) {
      event.preventDefault()
      event.target.blur()
      setClickedEvent(event)
      setWarningPopup(true)
    }
  }

  const setBlured = () => {
    setAbleToEdit(false)
  }

  useEffect(() => {
    if (!countyLoading && countyData?.getCounties) {
      setCounties(
        // @ts-ignore
        countyData.getCounties.map((v) => {
          return { label: v.county_name, value: Number(v.id) }
        }),
      )
    }
  }, [countyData])

  const { loading: schoolDistrictsDataLoading, data: schoolDistrictsData } = useQuery(getSchoolDistrictsByRegionId, {
    variables: {
      regionId: Number(me?.selectedRegionId),
    },
    skip: Number(me?.selectedRegionId) ? false : true,
    fetchPolicy: 'network-only',
  })
  const [schoolDistricts, setSchoolDistricts] = useState<Array<DropDownItem>>([])

  useEffect(() => {
    if (!schoolDistrictsDataLoading && schoolDistrictsData?.schoolDistrict.length > 0) {
      setSchoolDistricts(
        schoolDistrictsData?.schoolDistrict.map((d: SchoolDistrict) => {
          return { label: d.school_district_name, value: d.school_district_name }
        }),
      )
    }
  }, [schoolDistrictsDataLoading])

  useEffect(() => {
    if (userInfo) {
      // @ts-ignore
      setEmail(userInfo.email)
      // @ts-ignore
      setPreferredFirstName(userInfo.preferred_first_name || '')
      // @ts-ignore
      setPreferredLastName(userInfo.preferred_last_name || '')
      // @ts-ignore
      setLegalFirstName(userInfo.first_name || '')
      // @ts-ignore
      setLegalLastName(userInfo.last_name || '')
      // @ts-ignore
      setLegalMiddleName(userInfo.middle_name || '')
      // @ts-ignore
      setCity(userInfo.address.city || '')
      // @ts-ignore
      setState(userInfo.address.state)
      // @ts-ignore
      setStreet1(userInfo.address.street || '')
      // @ts-ignore
      setStreet2(userInfo.address.street2 || '')
      // @ts-ignore
      setZip(userInfo.address.zip || '')
      // @ts-ignore
      setCounty(userInfo.address.county_id || 0)
      // @ts-ignore
      setSchoolDistrict(userInfo.address.school_district || '')
    }
    // @ts-ignore
    setPhone(phoneInfo?.number || '')
    // @ts-ignore
    if (phoneInfo?.recieve_text) {
      setCanMessage(true)
    }
  }, [userInfo])

  const closeReimbursementModal = () => setShowReimbursementModal(false)

  return (
    <Box
      sx={{
        marginTop: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            background: MthColor.BUTTON_LINEAR_GRADIENT,
            textTransform: 'none',
            color: 'white',
            marginRight: 5,
            width: '264px',
            height: '34px',
            borderRadius: 2,
          }}
          onClick={() => setShowReimbursementModal(true)}
        >
          Reimbursements
        </Button>
        <Button
          onClick={toggleHomeroomResourceModal}
          sx={{
            background: MthColor.BUTTON_LINEAR_GRADIENT,
            textTransform: 'none',
            color: 'white',
            marginRight: 2,
            width: '264px',
            height: '34px',
            borderRadius: 2,
          }}
        >
          Homeroom Resources
        </Button>
      </Box>
      <Grid container marginTop={4} columnSpacing={4} rowSpacing={3}>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Preferred First Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={preferedFirstName}
            onChange={(e) => {
              setPreferredFirstName(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, ...{ preferred_first_name: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Preferred Last Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={preferedLastName}
            onChange={(e) => {
              setPreferredLastName(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, ...{ preferred_last_name: e.target.value } })
            }}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={0} columnSpacing={4} rowSpacing={3}>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Legal First Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={legalFirstName}
            onChange={(e) => {
              setLegalFirstName(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, ...{ first_name: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Legal Middle Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={legalMiddleName}
            onChange={(e) => {
              setLegalMiddleName(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, ...{ middle_name: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Legal Last Name
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={legalLastName}
            onChange={(e) => {
              setLegalLastName(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, ...{ last_name: e.target.value } })
            }}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={0} columnSpacing={4} rowSpacing={3}>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Phone
          </Paragraph>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              sx={classes.text}
              size='small'
              variant='outlined'
              fullWidth
              value={phone}
              onChange={(e) => {
                const formattedNumber = e.target.value.replace(/\D+/g, '').slice(0, 10)
                setPhone(formattedNumber)
                // @ts-ignore
                setPhoneInfo({ ...phoneInfo, ...{ number: formattedNumber } })
              }}
              InputProps={{
                // @ts-ignore
                inputComponent: PhoneInput as React.ComponentType<PhoneInputProps>,
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={canMessage}
                  onChange={(e) => {
                    setCanMessage(e.target.checked)
                    // @ts-ignore
                    setPhoneInfo({ ...phoneInfo, ...{ ext: e.target.checked ? '1' : null } })
                  }}
                  disabled
                />
              }
              label={<Paragraph sx={classes.checkBox}>{MthTitle.TEXT_MESSAGE}</Paragraph>}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Email
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, ...{ email: e.target.value } })
            }}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={0} columnSpacing={4} rowSpacing={3}>
        <Grid item xs={6}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Address Line 1
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={street1}
            onFocus={(e) => {
              setFocused(e)
            }}
            onBlur={() => {
              setBlured()
            }}
            onChange={(e) => {
              setStreet1(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, address: { ...userInfo.address, street: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Address Line 2
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={street2}
            onFocus={(e) => {
              setFocused(e)
            }}
            onBlur={() => {
              setBlured()
            }}
            onChange={(e) => {
              setStreet2(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, address: { ...userInfo.address, street2: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            City
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={city}
            onFocus={(e) => {
              setFocused(e)
            }}
            onBlur={() => {
              setBlured()
            }}
            onChange={(e) => {
              setCity(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, address: { ...userInfo.address, city: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            State
          </Paragraph>
          <DropDown
            sx={classes.dropdown}
            dropDownItems={US_STATES as DropDownItem[]}
            setParentValue={(val) => {
              setSelectedValue(String(val))
              setSelectedDrop('State')
              setWarningPopup(true)
            }}
            alternate={true}
            size='medium'
            defaultValue={state || ''}
          />
        </Grid>
        <Grid item xs={3}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            Zip
          </Paragraph>
          <TextField
            sx={classes.text}
            size='small'
            variant='outlined'
            fullWidth
            value={zip}
            onFocus={(e) => {
              setFocused(e)
            }}
            onBlur={() => {
              setBlured()
            }}
            onChange={(e) => {
              setZip(e.target.value)
              // @ts-ignore
              setUserInfo({ ...userInfo, address: { ...userInfo.address, zip: e.target.value } })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            County
          </Paragraph>
          <DropDown
            size='medium'
            sx={classes.dropdown}
            dropDownItems={counties}
            defaultValue={county}
            placeholder={'Select County'}
            auto={false}
            setParentValue={(e) => {
              setSelectedValue(String(e))
              setSelectedDrop('County')
              setWarningPopup(true)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Paragraph size='medium' textAlign='left' sx={classes.label}>
            School District
          </Paragraph>
          <DropDown
            size='medium'
            sx={classes.dropdown}
            dropDownItems={schoolDistricts}
            defaultValue={school_district}
            placeholder={'Select School District'}
            auto={false}
            setParentValue={(e) => {
              setSelectedValue(String(e))
              setSelectedDrop('School District')
              setWarningPopup(true)
            }}
          />
        </Grid>
      </Grid>
      {epic275story5395 ? (
        <>
          <Grid container marginTop={0} columnSpacing={4} rowSpacing={3}>
            {/* @ts-ignore */}
            <FamilyNotesAndStudentNotes
              subtittleStyle={classes.note}
              textFieldStyle={classes.text}
              notes={notes}
              setNotes={setNotes}
              familyNotesTestId={testIDs.familyNotes}
              studentNotesTestId={testIDs.studentNotes}
              studentNotes={studentNotes}
              setStudentNotes={setStudentNotes}
              isFamilyNotesEditable={true}
            ></FamilyNotesAndStudentNotes>
          </Grid>
          <Grid container marginTop={0} columnSpacing={4} rowSpacing={3}>
            <Grid item xs={12} sx={{ marginTop: 'auto', textAlign: 'right' }}>
              <Typography color={MthColor.DISABLED} sx={{ fontWeight: '700' }}>
                {/* @ts-ignore */}
                {USER_ID} {userInfo?.uniqueId}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container marginTop={0} columnSpacing={4} rowSpacing={3}>
          <Grid item xs={7}>
            <Subtitle fontWeight='700' size={TEXT_SIZE.LARGE} textAlign='left' sx={classes.note}>
              Notes
            </Subtitle>
            <TextField
              sx={classes.text}
              size='small'
              variant='outlined'
              fullWidth
              // @ts-ignore
              borderNone={true}
              value={notes || ''}
              onChange={(e) => {
                setNotes(e.target.value)
              }}
              multiline
              rows={8}
            />
          </Grid>

          <Grid item xs={5} sx={{ marginTop: 'auto', textAlign: 'right' }}>
            <Typography color={MthColor.DISABLED} sx={{ fontWeight: '700' }}>
              {/* @ts-ignore */}
              {USER_ID} {userInfo?.uniqueId}
            </Typography>
          </Grid>
        </Grid>
      )}
      {warningPopup && (
        <CustomModal
          title='Warning'
          description='Updating the address will affect all children'
          onConfirm={setConfirmWarningPopup}
          onClose={setCancelWarningPopup}
          confirmStr='Update'
        />
      )}
      {showHomeroomResourceModal && (
        <HomeroomResourceModal
          toggleHomeroomResourceModal={toggleHomeroomResourceModal}
          showHomeromResourceModal={showHomeroomResourceModal}
          schoolYearIdSelected={selectedYearId}
          studentIds={familyStudentIds}
        />
      )}
      {showReimbursementModal && (
        <ReimbursementModal
          closeModal={closeReimbursementModal}
          isFromParent={true}
          personId={
            typeof userInfo === 'object' && userInfo && 'person_id' in userInfo ? Number(userInfo.person_id) : 0
          }
        />
      )}
    </Box>
  )
}
