import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, Modal } from '@mui/material'
import { Box } from '@mui/system'
import { isNil } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { EMPTY_STRING } from '@mth/constants'
import { MthColor, QUESTION_TYPE, QuestionTypeSlug, datePatterns } from '@mth/enums'
import { useSubmitEnrollmentPacket } from '@mth/hooks/useSubmitEnrollmentPacket/useSubmitEnrollmentPacket'
import { Packet, SchoolYear } from '@mth/models'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { phoneFormat, formatDateByPatternUtc, getStudentGradeLevelBySY, formatDateForInput } from '@mth/utils'
import { EnrollmentPacketInfo } from './EnrollmentPacketInfo'
import { EnrollmentPacketVaccineView } from './EnrollmentPacketVaccineView'
import { PacketConfirmModals } from './modals/ConfirmModals'
import { EnrollmentPacketDocument } from './PacketDocuments'
import { EnrollmentPacketNotes } from './PacketNotes'
import { PacketSaveButtons } from './PacketSaveButtons'
import { EnrollmentPacketDropDownButton } from './PacketStatusDropDown'
import { EnrollmentJobsInfo } from './PacketStudentInfo'
import { studentContext, PacketModalQuestionsContext } from './providers'
import { useStyles } from './styles'
import { EnrollmentQuestionTab } from '../../SiteManagement/EnrollmentSetting/EnrollmentQuestions/types'
import {
  getSettingsQuery,
  getEnrollmentPacketQuery,
  getSchoolYearSettingForPacketSubmit,
  getPacketQuestionsInputGql,
} from '../services'

type ProfilePacketModalProps = {
  packet_id: number
  handleModem: () => void
  refetch: () => void
}

// TODO: how this component is different from src/screens/Admin/EnrollmentPackets/EnrollmentPacketModal/index.tsx
// should this component be replaced from enrollmentPacketModal/index

export const ProfilePacketModal: React.FC<ProfilePacketModalProps> = ({ handleModem, packet_id, refetch }) => {
  const classes = useStyles
  const { me } = useContext(UserContext)

  const settingsQuery = useQuery(getSettingsQuery, {
    variables: {
      regionId: Number(me?.selectedRegionId),
    },
    fetchPolicy: 'network-only',
  })

  const enableImmunization = settingsQuery.data?.immunizationEnabled ?? false

  const { loading: packetLoading, data: packetData } = useQuery<{ packet: Packet }>(getEnrollmentPacketQuery, {
    variables: { packetID: packet_id },
    fetchPolicy: 'network-only',
  })

  const { packet, school_year_id } = useMemo(
    () => ({
      packet: packetData?.packet,
      school_year_id: Number(packetData?.packet?.school_year_id),
    }),
    [packetData?.packet],
  )

  const { data: packetSchoolYear } = useQuery<{ schoolyear: SchoolYear }>(getSchoolYearSettingForPacketSubmit, {
    variables: {
      schoolYearId: packet?.school_year_id,
    },
    skip: !packet?.school_year_id,
    fetchPolicy: 'network-only',
  })

  const scheduleEnabled = packetSchoolYear?.schoolyear?.schedule

  const gradeLevel = getStudentGradeLevelBySY(packet?.student, school_year_id)

  const { data } = useQuery(getPacketQuestionsInputGql, {
    variables: {
      input: {
        region_id: Number(me?.selectedRegionId),
        school_year_id: packet?.school_year_id ?? packet?.student?.current_school_year_status.school_year_id,
        mid_year: packet?.reenroll
          ? false
          : packet?.student?.applications?.at(-1)?.school_year?.school_year_id ===
              packet?.student?.current_school_year_status.school_year_id
            ? packet?.student?.applications?.at(-1)?.midyear_application
            : false,
      },
    },
    skip: isNil(packet) || isNil(me?.selectedRegionId),
    fetchPolicy: 'network-only',
  })

  const [questionsData, setQuestionsData] = useState<EnrollmentQuestionTab[]>()

  useEffect(() => {
    if (data?.getPacketEnrollmentQuestions.length > 0) {
      // @ts-ignore
      const jsonTabData = data?.getPacketEnrollmentQuestions.map((t) => {
        if (t.groups.length > 0) {
          const jsonGroups = t.groups
            // @ts-ignore
            .map((g) => {
              if (g.questions.length > 0) {
                const jsonQuestions = g.questions
                  // @ts-ignore
                  .map((q) => {
                    return {
                      ...q,
                      options: JSON.parse(q.options) || [],
                    }
                  })
                  // @ts-ignore
                  .sort((a, b) => a.order - b.order)
                return { ...g, questions: jsonQuestions }
              }
              return g
            })
            // @ts-ignore
            .sort((a, b) => a.order - b.order)
          return { ...t, groups: jsonGroups }
        }
        return t
      })
      setQuestionsData(jsonTabData)
    } else {
      setQuestionsData([])
    }
  }, [data])

  const [dynamicValues, setDynamicValues] = useState()

  useEffect(() => {
    const temp = {
      immunizations: [],
      parent: { ...packet?.student?.parent?.person },
      notes: packet?.admin_notes || '',
      status: packet?.status || '',
      preSaveStatus: packet?.status || '',
      packetStatuses: [],
      showSaveWarnModal: false,
      missingInfoAlert: false,
      showMissingInfoModal: false,
      showAgeIssueModal: false,
      showValidationErrors: false,
      age_issue: false,
      saveAlert: '',
      medicalExempt: packet?.medical_exemption === 1,
      exemptionDate: packet?.exemption_form_date
        ? formatDateByPatternUtc(new Date(packet?.exemption_form_date), datePatterns.ZMONTH_ZDAY_4DYEAR)
        : EMPTY_STRING,
      enableExemptionDate: false,
      signature_file_id: packet?.signature_file_id || 0,
      missing_files: packet?.missing_files || [],
      school_year_id,
    }
    if (questionsData && (questionsData?.length || 0) > 0 && packetLoading === false && packet) {
      questionsData.map((tab) => {
        tab?.groups?.map((group) => {
          group?.questions?.map((q) => {
            // if(q.display_admin) {
            if (q.default_question) {
              if (q.slug.includes('packet_')) {
                const fieldName = q.slug.split('packet_')[1]
                // @ts-ignore
                temp[q.slug] = packet[fieldName]
                if (q.type === QUESTION_TYPE.CALENDAR) {
                  // @ts-ignore
                  temp[q.slug] = formatDateForInput(packet[fieldName])
                }
              } else if (q.slug.includes('student_')) {
                const fieldName = q.slug.split('student_')[1]
                // @ts-ignore
                temp[q.slug] = packet.student.person[fieldName]
                // @ts-ignore
                temp[QuestionTypeSlug.STUDENT_GRADE_LEVEL] = gradeLevel
                // @ts-ignore
                temp[QuestionTypeSlug.STUDENT_EMAILCONFIRM] = packet.student.person.email
                if (q.type === QUESTION_TYPE.CALENDAR) {
                  // @ts-ignore
                  temp[q.slug] = formatDateForInput(packet.student.person[fieldName])
                }
              } else if (q.slug.includes('address_')) {
                const fieldName = q.slug.split('address_')[1]
                // @ts-ignore
                temp[q.slug] = packet.student.parent.person.address[fieldName]
              } else if (q.slug.includes('parent_')) {
                const fieldName = q.slug.split('parent_')[1]
                // @ts-ignore
                temp[q.slug] = packet.student.parent.person[fieldName]
                // @ts-ignore
                temp[QuestionTypeSlug.PARENT_PHONE_NUMBER] = phoneFormat(packet.student.parent.phone.number)
                // @ts-ignore
                temp[QuestionTypeSlug.PARENT_RECIEVE_TEXT] = packet.student.parent.phone.recieve_text
                // @ts-ignore
                temp[QuestionTypeSlug.PARENT_EMAILCONFIRM] = packet.student.parent.person.email
                if (q.type === QUESTION_TYPE.CALENDAR) {
                  // @ts-ignore
                  temp[q.slug] = formatDateForInput(packet.student.parent.person[fieldName])
                }
              } else if (q.slug.includes('meta_')) {
                const fieldName = q.slug
                // @ts-ignore
                const metaJSON = JSON.parse(packet.meta)
                // @ts-ignore
                temp[q.slug] = (metaJSON && metaJSON[fieldName]) || ''
                if (q.type === QUESTION_TYPE.CALENDAR) {
                  // @ts-ignore
                  temp[q.slug] = formatDateForInput((metaJSON && metaJSON[fieldName]) || null)
                }
              }
            } else {
              const fieldName = q.slug
              // @ts-ignore
              const metaJSON = JSON.parse(packet.meta)
              // @ts-ignore
              temp[q.slug] = (metaJSON && metaJSON[fieldName]) || ''
              if (q.type === QUESTION_TYPE.CALENDAR) {
                // @ts-ignore
                temp[q.slug] = formatDateForInput((metaJSON && metaJSON[fieldName]) || null)
              }
            }
            // }
          })
        })
      })
    }
    // @ts-ignore
    setDynamicValues(temp)
  }, [packet, packetLoading, questionsData])

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: dynamicValues,
  })

  useEffect(() => {
    methods.reset(dynamicValues)
    // if (!packetLoading) setDynamicValueStatus(true)
  }, [dynamicValues, methods])
  const { onSubmit, loading: submitLoading } = useSubmitEnrollmentPacket(
    packet,
    refetch,
    methods,
    questionsData,
    scheduleEnabled,
  )

  if (isNil(packet) || isNil(school_year_id)) return <></>

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal
          open={true}
          onClose={() => handleModem()}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          {/* @ts-ignore */}
          <studentContext.Provider value={packet.student}>
            <Box sx={classes.modalCard}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <EnrollmentPacketDropDownButton />
                <CloseIcon onClick={() => handleModem()} style={classes.close} />
              </Box>
              {/* @ts-ignore */}
              <PacketModalQuestionsContext.Provider value={questionsData}>
                <Box sx={classes.content}>
                  <Grid container sx={{ padding: '10px 0px' }}>
                    <Grid item md={6} sm={6} xs={12}>
                      {/* @ts-ignore */}
                      <EnrollmentJobsInfo packet={packet} handleModem={handleModem} refetch={refetch} />
                      <EnrollmentPacketDocument packetData={packet} />
                      <EnrollmentPacketNotes />
                      {/* @ts-ignore */}
                      <PacketSaveButtons submitForm={methods.handleSubmit(onSubmit)} loading={submitLoading} />
                    </Grid>
                    <Grid item md={5} sm={5} xs={5}>
                      {/* @ts-ignore */}
                      {enableImmunization && <EnrollmentPacketVaccineView packet={packet} />}
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} sx={{ padding: '20px 0px' }}>
                    <hr style={{ borderTop: `solid 1px ${MthColor.SYSTEM_11}`, width: '97%', borderBottom: '0' }} />
                  </Grid>
                  <EnrollmentPacketInfo />
                </Box>
              </PacketModalQuestionsContext.Provider>
              {/* @ts-ignore */}
              <PacketConfirmModals packet={packet} refetch={refetch} submitForm={methods.handleSubmit(onSubmit)} />
            </Box>
          </studentContext.Provider>
        </Modal>
      </form>
    </FormProvider>
  )
}
