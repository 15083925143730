import { gql } from '@apollo/client'

export const getReimbursementRequestsForStudentsQuery = gql`
  query ReimbursementRequestsForStudents($param: ReimbursementRequestSearchInput!) {
    reimbursementRequestsForStudents(param: $param) {
      reimbursement_request_id
      SchoolYearId
      SchoolYear {
        school_year_id
      }
      StudentId
      Student {
        student_id
        person {
          person_id
          first_name
          preferred_first_name
          last_name
          email
        }
      }
      periods {
        period
      }
      periods_names
      periods_ids
      meta
      reimbursement_type
      form_type
      date_submitted
      date_paid
      date_ordered
      ParentId
      signature_file_id
      signature_name
      status
      total_amount
      periods_number
      additional_information
      ReimbursementReceipts {
        reimbursement_receipt_id
        ReimbursementRequestId
        file_id
        file_name
        amount
        applicable
        updated
        created_at
        File {
          item1
        }
      }
    }
  }
`
/*
// Variable Structure //
{
  "param": {
    "filter": {
      "SchoolYearId": number,
      "StudentIds": number[]
    }
  }
}
*/

export const getReimbursementRequestIdsQuery = gql`
  query ReimbursementRequestIds(
    $schoolYearId: Int!
    $filter: ReimbursementRequestFilters
    $take: Int
    $sort: String
    $search: String
  ) {
    reimbursementRequests(schoolYearId: $schoolYearId, filter: $filter, take: $take, sort: $sort, search: $search) {
      total
      results {
        reimbursement_request_id
      }
    }
  }
`

export const getReimbursementRequestsQuery = gql`
  query ReimbursementRequests(
    $schoolYearId: Int!
    $skip: Int
    $take: Int
    $filter: ReimbursementRequestFilters
    $sort: String
    $search: String
  ) {
    reimbursementRequests(
      schoolYearId: $schoolYearId
      skip: $skip
      take: $take
      filter: $filter
      sort: $sort
      search: $search
    ) {
      total
      results {
        reimbursement_request_id
        form_type
        periods_ids
        reimbursement_type
        date_submitted
        date_paid
        date_ordered
        total_amount
        status
        Student {
          student_id
          person {
            first_name
            last_name
            email
            date_of_birth
          }
          parent {
            parent_id
            person {
              first_name
              last_name
              email
            }
          }
          grade_levels {
            school_year_id
            grade_level
          }
        }
        ReimbursementRequestEmails {
          created_at
        }
        ReimbursementPeriod {
          SchedulePeriod {
            Period {
              period
            }
          }
        }
      }
    }
  }
`

export const getReimbursementRequestQuery = gql`
  query ReimbursementRequest($reimbursementRequestId: Int!) {
    reimbursementRequest(reimbursementRequestId: $reimbursementRequestId) {
      reimbursement_request_id
      SchoolYearId
      StudentId
      form_type
      periods {
        period
      }
      periods_ids
      periods_names
      periods_number
      periods_titles
      reimbursement_type
      meta
      date_submitted
      date_paid
      date_ordered
      total_amount
      status
      order_confirmation
      reimbursementTransactionData
      reimbursementTransaction {
        id
        hwResponse
        status
        createdOn
      }
      Student {
        student_id
        person {
          first_name
          preferred_first_name
          last_name
          email
          date_of_birth
        }
        parent {
          parent_id
          notes
          person {
            user {
              email
              hyperwalletUserToken
              hyperwalletUserStatus
              hyperwalletUserVerificationStatus
              hyperwalletTreatmentToken
            }
            first_name
            last_name
            email
          }
          students {
            student_id
            person {
              first_name
              last_name
            }
            status {
              status
              school_year_id
              student_id
            }
          }
        }
        grade_levels {
          school_year_id
          grade_level
        }
        applications {
          application_id
          school_year_id
          midyear_application
        }
        current_school_year_status {
          school_year_id
        }
        Schedules {
          status
          is_second_semester
          SchoolYearId
        }
      }
      SchoolYear {
        reimbursements
        direct_orders
        school_year_id
        reimbursements
        require_software
        direct_orders
        direct_order_open
        direct_order_close
        reimbursement_open
        reimbursement_close
        custom_built_open
        custom_built_close
        reimbursement_custom_built_open
        reimbursement_custom_built_close
        require_software_open
        require_software_close
        third_party_open
        third_party_close
        mid_direct_order_open
        mid_direct_order_close
        mid_reimbursement_open
        mid_reimbursement_close
        mid_custom_built_open
        mid_custom_built_close
        mid_reimbursement_custom_built_open
        mid_reimbursement_custom_built_close
        mid_require_software_open
        mid_require_software_close
        mid_third_party_open
        mid_third_party_close
        schedule
        second_semester_starts
        ReimbursementSetting {
          max_receipts
          id
          school_year_id
          information
          supplemental_reimbursements_forms
          supplemental_direct_order_forms
          technology_reimbursements_forms
          technology_direct_order_forms
          custom_reimbursements_forms
          custom_direct_order_forms
          is_merged_periods
          merged_periods
          merged_periods_reimbursements_forms
          merged_periods_direct_order_forms
          third_party_reimbursements_forms
          require_software_reimbursements_forms
          max_receipts
          require_passing_grade
          min_grade_percentage
          allow_delete
          allow_submit_with_updates_required
          auto_delete_updates_required
          num_days_delete_updates_required
          display_remaining_funds
          remaining_funds
          notification_grades
        }
        direct_orders_technology_instructions
        direct_orders_supplement_instructions
        direct_orders_custom_built_instructions
        reimbursements_technology_instructions
        reimbursements_supplement_instructions
        reimbursements_custom_built_instructions
        reimbursements_third_party_instructions
        reimbursements_required_software_instructions
      }
      ReimbursementReceipts {
        reimbursement_receipt_id
        ReimbursementRequestId
        file_id
        file_name
        amount
        created_at
        applicable
        File {
          item1
        }
        updated
      }
      SameTypeRequests {
        reimbursement_request_id
        form_type
        reimbursement_type
        total_amount
        status
      }
    }
  }
`

export const getManyStudentHomeroomInfoQuery = gql`
  query ManyStudentHomeroomInfo($schoolYearId: Int!, $studentIds: [Int!]!) {
    manyStudentHomeroomInfo(school_year_id: $schoolYearId, student_ids: $studentIds) {
      student_id
    }
  }
`

export const getEmailsByReimbursementIdQuery = gql`
  query GetEmailsByReimbursementId($reimbursementRequestId: Int!, $templateName: String!) {
    getEmailsByReimbursementId(reimbursementRequestId: $reimbursementRequestId, templateName: $templateName) {
      body
      created_at
      from_email
      subject
    }
  }
`

export const getEmailHistoryByReimbursementId = gql`
  query getEmailHistoryByReimbursementId($reimbursementRequestId: Int!) {
    getEmailHistoryByReimbursementId(reimbursementRequestId: $reimbursementRequestId) {
      body
      created_at
      from_email
      subject
    }
  }
`

export const getReimbursementTotalAmountByStateAndProgramQuery = gql`
  query GetReimbursementTotalAmountByStateAndProgram($region: String!, $state: String!) {
    getReimbursementTotalAmountByStateAndProgram(region: $region, state: $state) {
      programAmount
      stateAmount
    }
  }
`

export const getTreatmentTokenDetailQuery = gql`
  query treatmentTokenDetail($email: String!, $treatmentToken: String!) {
    getTreatmentTokenDetail(email: $email, treatmentToken: $treatmentToken) {
      type
      accountId
      status
      token
      email
    }
  }
`
