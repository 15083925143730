import { datadogLogs } from '@datadog/browser-logs'
import axios from 'axios'
import { SNOWPACK_PUBLIC_API_USERS_URL } from '@mth/envs'

export const loginUser = async (username: string, password: string) => {
  try {
    const url = `${SNOWPACK_PUBLIC_API_USERS_URL}/login`
    return await axios.post(url, { username, password }, { withCredentials: true })
  } catch (error) {
    const message = `Error logging in the user: ${username}`
    handleAxiosError(error, message)
    throw error
  }
}

export const logoutUser = async () => {
  try {
    const url = `${SNOWPACK_PUBLIC_API_USERS_URL}/logout`
    return await axios.post(url, {}, { withCredentials: true })
  } catch (error) {
    const message = 'Error logging out'
    handleAxiosError(error, message)
    throw new Error(message)
  }
}

export const refreshToken = async (setCredentials: (param: string) => void, logout: () => void) => {
  const url = `${SNOWPACK_PUBLIC_API_USERS_URL}/refresh-token`
  try {
    const { data } = await axios.post(url, {}, { withCredentials: true })
    if (data && data.jwt) {
      console.warn('Refreshing token')
      localStorage.setItem('JWT', data.jwt)
      setCredentials(data.jwt)
      return data.jwt
    } else {
      console.error('JWT not found in response')
      return null
    }
  } catch (error) {
    datadogLogs.logger.error('Error during token refresh', { error })
    logout()
    return null
  }
}

const handleAxiosError = (error: unknown, message: string) => {
  if (axios.isAxiosError(error) && error.code === '401') {
    datadogLogs.logger.warn(message, {
      responseStatus: error.response?.status,
    })
  } else {
    datadogLogs.logger.error(message, { error })
  }
  throw error
}
