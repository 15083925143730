export enum MthRoute {
  ADMIN_APPLICATIONS = '/enrollment/applications',
  ANNOUNCEMENTS = '/communication/announcements',
  APPLICATIONS = '/applications',
  CALENDAR = '/calendar',
  COMMUNICATION = '/communication',
  GRADEBOOK = '/gradebook',
  CONFIRM_EMAIL = '/confirm',
  DASHBOARD = '/',
  EMAIL_RECORDS = '/communication/email-records',
  ANNOUNCEMENT_POPUPS = '/communication/announcement-popups',
  EMAIL_VERIFICATION = '/email-verification',
  EMAILTEMPLATES = '/email-templates',

  ENROLLMENT = '/enrollment',
  ENROLLMENT_PACKETS = '/enrollment/enrollment-packets',
  RESOURCE_REQUESTS = '/enrollment/resource-requests',

  FORGOT_PASSWORD = '/forgot-password',
  HOMEROOM = '/homeroom',
  PARTICIPATION = '/participation',
  PARENT_LINK = '/parent-link',
  RECORDS = '/records',

  REIMBURSEMENTS = '/reimbursements',
  REIMBURSEMENTS_REQUESTS = '/reimbursements/requests',
  REIMBURSEMENTS_SETTINGS = '/reimbursements/settings',
  REIMBURSEMENTS_REIMBURSEMENT_FORM = '/reimbursements/reimbursement_form',
  REIMBURSEMENTS_DIRECT_ORDER_FORM = '/reimbursements/direct_order_form',
  REIMBURSEMENTS_REIMBURSEMENT_VIEW = '/reimbursements/reimbursement_view',
  REIMBURSEMENTS_DIRECT_ORDER_VIEW = '/reimbursements/direct_order_view',

  CURRICULUM = '/curriculum',
  CURRICULUM_COURSE_CATALOG = '/curriculum/course-catalog',
  CURRICULUM_COURSE_CATALOG_SETTINGS = '/curriculum/course-catalog/settings',
  CURRICULUM_COURSE_CATALOG_PERIODS = '/curriculum/course-catalog/periods',
  CURRICULUM_COURSE_CATALOG_SUBJECTS = '/curriculum/course-catalog/subjects',
  CURRICULUM_COURSE_CATALOG_PROVIDERS = '/curriculum/course-catalog/providers',
  CURRICULUM_COURSE_CATALOG_STATE_CODES = '/curriculum/course-catalog/state-codes',
  CURRICULUM_HOMEROOM_RESOURCES = '/curriculum/homeroom-resources',

  HOMEROOM_LEARNING_LOGS = '/homeroom/learning-log',
  LEARNING_LOG = '/learning-log',
  STUDENT_LL = '/student-ll',
  GRADE_LL = '/grade',
  HOMEROOM_ASSIGNMENTS = '/homeroom/assignments',
  HOMEROOM_CHECKLIST = '/homeroom/checklist',
  HOMEROOM_GRADEBOOK = '/homeroom/gradebook',
  HOMEROOM_SETTINGS = '/homeroom/settings',

  HOMEROOM_STUDENT_BASE = '/homeroom/student-id/',
  HOMERROM_STUDENT_VIEW = '/homeroom/student-id/:studentId/student-view',
  HOMERROM_HOMEROOM_VIEW = '/homeroom/student-id/:studentId/homeroom-view',
  HOMERROM_RESOURCE_VIEW = '/homeroom/student-id/:studentId/resources-view',
  HOMERROM_RESOURCE_CONFIRMATION = '/homeroom/student-id/:studentId/resources-confirmation',

  HOMEROOM_VIEW = '/homeroom-view',
  STUDENT_VIEW = '/student-view',
  RESOURCES_VIEW = '/resources-view',
  RESOURCES_CONFIRMATION = '/resources-confirmation',

  HOMEROOM_LEARNING_LOG_EDIT_PATH = '/homeroom/student-id/:studentId/learninglog-edit/:learningLogId',
  HOMEROOM_LEARNING_LOG_VIEW_PATH = '/homeroom/student-id/:studentId/learninglog-view/:learningLogId',
  HOMEROOM_LEARNING_LOG_BLOCK_PATH = '/homeroom/student-id/:studentId/learninglog-block/:learningLogId',

  HOMEROOM_LEARNING_LOG_EDIT = '/learninglog-edit/',
  HOMEROOM_LEARNING_LOG_VIEW = '/learninglog-view/',
  HOMEROOM_LEARNING_LOG_BLOCK = '/learninglog-block/',

  REPORTS = '/reports',
  SCHOOL_PARTNER_REPORTS = '/reports/school-partner',
  MASTER_REPORTS = '/reports/master',
  APPLICATIONS_REPORTS = '/reports/applications',
  SCHEDULE_REPORTS = '/reports/schedule',

  RESET_PASSWORD = '/reset-password',
  SETTINGS = '/settings',
  SITE_MANAGEMENT = '/site-management',
  SCHOOL_ENROLLMENT = '/enrollment/school-enrollment',
  SUBMIT_WITHDRAWAL = '/withdrawal',
  SUBMIT_SCHEDULE = '/schedule',
  VIEW = '/view',
  USERS = '/users',
  WITHDRAWAL = '/enrollment/withdrawal',
  TESTING_PREFERENCE = '/enrollment/testing-preference',
  TESTING_PREFERENCE_PATH = '/site-management/enrollment/testing-preference',
  ENROLLMENT_SCHEDULE = '/enrollment/enrollment-schedule',
  ENROLLMENT_SCHEDULE_NOT_SUBMITTED = '/enrollment/enrollment-not-submitted-schedule',
}
