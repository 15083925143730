import { ValidationType } from '@mth/components/QuestionItem/QuestionItemProps'
import { QuestionTypeSlug, QuestionTypeLabel, MthTitle } from '@mth/enums'
import { EMPTY_STRING } from './empty-string.constant'

export type DefaultQuestionType = {
  label: string
  description: string
  type: QuestionTypeLabel
  slug: QuestionTypeSlug
  validation: ValidationType
}

export const defaultQuestions: DefaultQuestionType[] = [
  {
    label: 'Parent Preferred First Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.PARENT_PREFERRED_FIRST_NAME,
    validation: ValidationType.NONE,
  },
  {
    label: 'Parent Preferred Last Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.PARENT_PREFERRED_LAST_NAME,
    validation: ValidationType.NONE,
  },
  {
    label: 'Parent Legal First Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.PARENT_FIRST_NAME,
    validation: ValidationType.NONE,
  },
  {
    label: 'Parent Legal Last Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.PARENT_LAST_NAME,
    validation: ValidationType.NONE,
  },
  {
    label: 'Secondary Parent First Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.PACKET_SECONDARY_CONTACT_FIRST,
    validation: ValidationType.NONE,
  },
  {
    label: 'Secondary Parent Last Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.PACKET_SECONDARY_CONTACT_LAST,
    validation: ValidationType.NONE,
  },
  {
    label: 'Phone Number',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.PARENT_PHONE_NUMBER,
    validation: ValidationType.PHONE,
  },
  {
    label: 'Parent Email',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.PARENT_EMAIL,
    validation: ValidationType.EMAIL,
  },
  {
    label: 'Student Legal First Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.STUDENT_FIRST_NAME,
    validation: ValidationType.NONE,
  },
  {
    label: 'Student Legal Last Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.STUDENT_LAST_NAME,
    validation: ValidationType.NONE,
  },
  {
    label: 'Student Legal Middle Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.STUDENT_MIDDLE_NAME,
    validation: ValidationType.NONE,
  },
  {
    label: 'Student Preferred First Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.STUDENT_PREFERRED_FIRST_NAME,
    validation: ValidationType.NONE,
  },
  {
    label: 'Student Preferred Last Name',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.STUDENT_PREFERRED_LAST_NAME,
    validation: ValidationType.NONE,
  },
  {
    label: 'Student Grade Level',
    description: '',
    type: QuestionTypeLabel.DROP_DOWN,
    slug: QuestionTypeSlug.STUDENT_GRADE_LEVEL,
    validation: ValidationType.NONE,
  },
  {
    label: 'Street',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.ADDRESS_STREET,
    validation: ValidationType.NONE,
  },
  {
    label: 'Street 2',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.ADDRESS_STREET2,
    validation: ValidationType.NONE,
  },
  {
    label: 'City',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.ADDRESS_CITY,
    validation: ValidationType.NONE,
  },
  {
    label: 'State',
    description: '',
    type: QuestionTypeLabel.DROP_DOWN,
    slug: QuestionTypeSlug.ADDRESS_STATE,
    validation: ValidationType.NONE,
  },
  {
    label: 'Zip Code',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.ADDRESS_ZIP,
    validation: ValidationType.NUMBER,
  },
  {
    label: 'Student Date of Birth',
    description: '',
    type: QuestionTypeLabel.CALENDAR,
    slug: QuestionTypeSlug.STUDENT_DATE_OF_BIRTH,
    validation: ValidationType.NONE,
  },
  {
    label: 'Student Gender',
    description: '',
    type: QuestionTypeLabel.MULTIPLE_CHOICES,
    slug: QuestionTypeSlug.STUDENT_GENDER,
    validation: ValidationType.NONE,
  },
  {
    label: 'School District',
    description: '',
    type: QuestionTypeLabel.DROP_DOWN,
    slug: QuestionTypeSlug.ADDRESS_SCHOOL_DISTRICT,
    validation: ValidationType.NONE,
  },
  {
    label: 'School of Enrollment Preference',
    description:
      'If you have a preference, please indicate your choice of School of Enrollment. Based on availability, we will do our best to accommodate this request.',
    type: QuestionTypeLabel.DROP_DOWN,
    slug: QuestionTypeSlug.SCHOOL_OF_ENROLLMENT_PREFERENCE,
    validation: ValidationType.NONE,
  },
  {
    label: MthTitle.SECONDARY_EMAIL,
    description: EMPTY_STRING,
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.SECONDARY_EMAIL,
    validation: ValidationType.EMAIL,
  },
  {
    label: 'County',
    description: '',
    type: QuestionTypeLabel.DROP_DOWN,
    slug: QuestionTypeSlug.ADDRESS_COUNTY_ID,
    validation: ValidationType.NONE,
  },
  {
    label: 'Country',
    description: '',
    type: QuestionTypeLabel.DROP_DOWN,
    slug: QuestionTypeSlug.ADDRESS_COUNTRY_ID,
    validation: ValidationType.NONE,
  },
  {
    label: 'Parent Email Confirmation',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.PARENT_EMAILCONFIRM,
    validation: ValidationType.EMAIL,
  },
  {
    label: 'Student Email',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.STUDENT_EMAIL,
    validation: ValidationType.EMAIL,
  },
  {
    label: 'Student Email Confirmation',
    description: '',
    type: QuestionTypeLabel.TEXT_FIELD,
    slug: QuestionTypeSlug.STUDENT_EMAILCONFIRM,
    validation: ValidationType.EMAIL,
  },
  {
    label: 'Special Education', //  not good to use this label field to compare
    description:
      'Has this student ever been diagnosed with a learning disability or ever qualified for Special Education Services (including Speech Therapy)?',
    type: QuestionTypeLabel.MULTIPLE_CHOICES,
    slug: QuestionTypeSlug.META_SPECIAL_EDUCATION,
    validation: ValidationType.NONE,
  },
]

export const validationTypes = [
  {
    label: 'Email',
    value: ValidationType.EMAIL,
  },
  {
    label: 'Numbers',
    value: ValidationType.NUMBER,
  },
  {
    label: 'Phone',
    value: ValidationType.PHONE,
  },
  {
    label: 'Phone and Opt-In',
    value: ValidationType.PHONEOPTIN,
  },
]
