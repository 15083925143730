import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { getCurrentAnnouncementPopupByRegion } from '@mth/graphql/queries/announcement-popups'
import { AnnouncementPopup } from '@mth/types'

export const useAnnouncementsPopup = (
  shouldRequest = false,
  rol: number | null | undefined,
  regionId: number | undefined,
): AnnouncementPopup[] => {
  const shouldSkip = !shouldRequest || !regionId || !rol
  const { data } = useQuery(getCurrentAnnouncementPopupByRegion, {
    variables: {
      regionId,
      rol,
    },
    skip: shouldSkip,
    fetchPolicy: 'cache-first',
  })

  const annnouncementPopupData = useMemo(
    () => data?.getCurrentAnnouncementPopupByRegion || [],
    [data?.getCurrentAnnouncementPopupByRegion],
  )
  if (!shouldRequest) {
    return []
  } else {
    return annnouncementPopupData
  }
}
