import { ApolloError, useQuery } from '@apollo/client'
import { GetNextSchoolYearByRegionId } from '@mth/announcements/services'

export const useNextSchoolYearByRegionId = (
  regionId: number,
): {
  loading: boolean
  data: {
    grades: string
    RegionId: number
    date_begin: string
    date_end: string
    schoolEnrollmentYear: {
      id: number
      active: boolean
      schoolPartner: {
        abbreviation: string
        name: string
        id: number
      }
    }
    SchoolPartners: {
      abbreviation: string
      active: number
      school_partner_id: string
    }[]
    enrollment_packet: boolean
    midyear_application: boolean
    midyear_application_open: string
    midyear_application_close: string
    schedule: boolean
    diploma_seeking: boolean
    testing_preference: boolean
    school_year_id: number
    ScheduleBuilder: {
      split_enrollment: boolean
      always_unlock: boolean
      max_num_periods: number
    }
    special_ed: boolean
    special_ed_options: string
  }
  error: ApolloError | undefined
  refetch: () => void // Refetch function provided by useQuery
} => {
  const { loading, data, error, refetch } = useQuery(GetNextSchoolYearByRegionId, {
    variables: {
      regionId: regionId,
    },
    skip: !regionId,
    fetchPolicy: 'cache-and-network',
  })

  return { loading: loading, data: data?.nextSchoolYear, error: error, refetch: refetch }
}
