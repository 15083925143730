import React, { useCallback, useEffect, useState } from 'react'
import { Theme } from '@emotion/react'
import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, OutlinedInput } from '@mui/material'
import { SxProps } from '@mui/system'
import { debounce } from 'lodash'
import { MthColor, MthTitle } from '@mth/enums'

interface SearchProps {
  setSearchField: (value: string) => void
  sx?: SxProps<Theme>
  placeholder?: string
  waitTime?: number
}

export const Search: React.FC<SearchProps> = ({ setSearchField, sx, placeholder, waitTime = 300 }) => {
  const [localSearchField, setLocalSearchField] = useState<string>('')
  const changeHandler = (event = '') => setSearchField(event)

  const debouncedChangeHandler = useCallback(debounce(changeHandler, waitTime), [])

  useEffect(() => {
    debouncedChangeHandler(localSearchField)
  }, [localSearchField])

  return (
    <OutlinedInput
      data-testid='search'
      sx={sx}
      onFocus={(e) => (e.target.placeholder = '')}
      onBlur={(e) => (e.target.placeholder = placeholder ?? `${MthTitle.SEARCH}...`)}
      size='small'
      fullWidth
      value={localSearchField}
      placeholder={placeholder ?? `${MthTitle.SEARCH}...`}
      onChange={(e) => setLocalSearchField(e.target.value)}
      startAdornment={
        <InputAdornment position='start'>
          <SearchIcon style={{ color: MthColor.BLACK, height: '20px', width: '20px' }} />
        </InputAdornment>
      }
    />
  )
}
