export enum WithdrawalStatus {
  NOTIFIED = 'Notified',
  WITHDRAWN = 'Withdrawn',
  REQUESTED = 'Requested',
}

export enum MidYear {
  MID = 'mid',
  NONMID = 'non-mid',
}
