export const RECEIVE_EMAIL_GIVING_LINK_TO_CREATE_PASSWORD =
  'This user will receive an email giving them a link to create a password.'
export const REMOVE_FAMILY_RESOURCE = 'Remove family resource to enable feature'

export const DEFAULT_TESTING_PREFERENCE_TITLE = 'Testing Preference'
export const DEFAULT_TESTING_PREFERENCE_DESCRIPTION =
  'One of the requirements of our personalized, distance education program is to participate in all mandatory State tests (hosted on a local computer proctored by a school representative). All students will be required to take the corresponding tests listed below, All State tests are administered by Tooele - Blue Peak Online, Nebo - ALC, Iron County - SEA or Gateway Preparatory Academy.'
export const DEFAULT_OPT_OUT_FORM_TITLE = 'Opt-Out Form'
export const DEFAULT_OPT_OUT_FORM_DESCRIPTION =
  'All State tests are administered by Tooele - Blue Peak Online, Nebo - ALC, Iron County - SEA or Gateway Preparatory Academy. Also, as required by State Law, an opt-out form for each School of Enrollment'
export const TESTING_PREFERENCE = 'testing_preference'
export const OPT_OUT = 'opt_out'
export const IF_SELECT_MARK_TESTING_PREFERENCE = 'If selected, mark testing preference as'
export const REQUIRE_REASON_TO_OPT_OUT = 'Require Reason to Opt-out'

export const DEFUALT_DIPLOMA_QUESTION_TITLE = 'Diploma-seeking Path'
export const DEFUALT_DIPLOMA_QUESTION_DESCRIPTION = 'Sample text'

export const DEFAULT_REASON =
  'Because the assessment does not allow for students to opt-out, please indicate your reason for refusing to test.'

export const DEFAULT_REIMBURSEMENT_INSTRUCTIONS =
  'Parents purchase approved educational resources for Custom-built courses and Technology Allowance, pay tuition for 3rd party Provider courses, and seek reimbursement for their purchases.'

export const SUBMIT_REQUEST = 'Submit Request'

export const SUBMIT = 'Submit'

export const ERROR = 'Error'

export const ERRORS = 'Errors'
export const OK = 'OK'
export const ERROR_DIRECT_DEDUCTION = 'Error generating Direct Deductions'
export const HIDE = 'Hide'
export const HIDE_MORE = 'Hide More'
export const READ_MORE = 'Read More'
export const SAVE = 'Save'
export const CANCEL = 'Cancel'
export const EDIT = 'Edit'
export const YES = 'Yes'
export const NO = 'No'
export const UNKNOWN = 'Unknown'
export const LOAD_MORE = 'Load More'
export const TEACHER_MESSAGES = 'Teacher Messages'
export const USER_ID = 'User ID'
export const MIDYEAR_SUFFIX = '-mid'
export const UNOFFICIAL = 'Unofficial Transcript'
export const SUBSCRIBE_MESSAGE =
  '  Also subscribe me to the OpenEd Daily, the #1 newsletter for tips, trends, and resources in education'
