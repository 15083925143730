import { MthColor } from '@mth/enums'

export const useStyles = {
  customReportModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '1120px',
    backgroundColor: MthColor.WHITE,
    boxShadow: 24,
    borderRadius: 2,
    px: 2,
    pt: 2,
    pb: 4.5,
    maxHeight: '750px',
    overflow: 'auto',
  },
  closeBtn: {
    background: MthColor.MTHBLACK,
    borderRadius: '4px',
    color: MthColor.WHITE,
    cursor: 'pointer',
  },
  actionButton: {
    color: MthColor.MTHBLACK,
    margin: '0 4px',
    width: '32px',
    height: '32px',
    '.MuiSvgIcon-root': {
      fontSize: '24px',
    },
  },
  tableStyle: {
    '.MuiTableHead-root': {
      '.MuiTableSortLabel-iconDirectionDesc, .MuiTableSortLabel-iconDirectionAsc': {
        marginLeft: '0',
        marginRight: '-14px',
      },
    },
    '.MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
      padding: '11px 8px',
    },
    '.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root': {
      fontSize: '12px',
      fontWeight: '500',
    },
  },
}
