import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useFormContext } from 'react-hook-form'
import { Subtitle } from '@mth/core/components/shared/Subtitle/Subtitle'
import { MthColor } from '@mth/enums'
import { getSignatureFile } from '@mth/graphql/queries/get-signature-file'
import { EnrollmentPacketFormType } from '../types'

export const SignatureComp: React.FC = () => {
  const { watch } = useFormContext<EnrollmentPacketFormType>()
  const signature_file_id = watch('signature_file_id')
  const [signedUrl, setSignedUrl] = useState('')
  const [getSignatureFileUrl, { loading: signatureFileUrlLoading, data: signatureFileData }] = useLazyQuery(
    getSignatureFile,
    {
      fetchPolicy: 'network-only',
    },
  )
  useEffect(() => {
    if (signature_file_id) {
      getSignatureFileUrl({
        variables: {
          fileId: signature_file_id,
        },
      })
    }
  }, [signature_file_id])

  useEffect(() => {
    if (!signatureFileUrlLoading && signatureFileData?.signatureFile?.signedUrl) {
      setSignedUrl(signatureFileData?.signatureFile?.signedUrl)
    }
  }, [signatureFileUrlLoading, signatureFileData])

  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center', marginTop: '150px' }}>
      <Grid item md={9} sm={9} xs={9} sx={{ textAlign: 'center' }}>
        {signedUrl && <img src={signedUrl} alt='signature' style={{ width: '50%' }} />}
      </Grid>
      <Grid item md={9} sm={9} xs={9}>
        <hr style={{ borderTop: `solid 1px ${MthColor.SYSTEM_01}`, borderBottom: '0' }} />
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box display='flex' flexDirection='column'>
          <Subtitle size='small' fontWeight='500'>
            Signature
          </Subtitle>
        </Box>
      </Grid>
    </Grid>
  )
}
