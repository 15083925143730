import { isNil } from 'lodash'
import {
  GRADES_FULL,
  HYPHEN,
  KINDERGARTEN_LABEL,
  KINDERGARTEN_NUM_VALUE,
  PRE_KINDERGARTEN_LABEL,
  PRE_KINDERGARTEN_NUM_VALUE,
} from '@mth/constants'
import { GRADES } from '@mth/constants'
import { GRADE_LEVEL_LABEL, MthTitle } from '@mth/enums'
import { DropDownItem, StudentGradeLevel } from '@mth/models'
import { gradeToNumber, gradeToAbbreviation } from '../grade-text/grade-text.util'
import { toOrdinalSuffix } from '../string/string.util'

/**
 * @param {(string | number)[]} GRADES
 * @param {string | undefined} gradesStr
 * @description convert 'Kindergarten' to 'K', set dash if the sequence value length > 3
 * @logic divide array into sub arrays with same sub lengths, and set the dash values
 * @example ['kindergarten',1,2,4,7,9,10,11,12] => ['kindergarten,1,2],[4],[7],[9,10,11,12] => k-2,4,7,9-12
 * @return converted string from array
 */
export const renderGrades = (gradesStr: string | undefined | null): string => {
  if (!gradesStr) return ''
  const decodedGrades: string[] = gradesStr
    .replace(KINDERGARTEN_NUM_VALUE.toString(), GRADE_LEVEL_LABEL.KINDERGARTEN)
    .replace(PRE_KINDERGARTEN_NUM_VALUE.toString(), PRE_KINDERGARTEN_LABEL)
    .split(',')
    .filter((item: string) => item !== 'all')

  const grades: number[] = GRADES.filter((item) => decodedGrades.includes(`${item}`)).map((item) => gradeToNumber(item))
  return formatGradeRanges(grades)
}

export const formatGradeRanges = (grades: number[]): string => {
  const result: string[] = []
  let start: number | null = null
  let end: number | null = null

  const normalizedGrades = grades.map((item) => (item <= 0 ? item + 1 : item))

  for (let i = 0; i < normalizedGrades.length; i++) {
    if (start === null && end === null) {
      start = normalizedGrades[i]
      end = normalizedGrades[i]
    } else if (normalizedGrades[i] - end! === 1) {
      end = normalizedGrades[i]
    } else {
      result.push(formatRange(start!, end!))
      start = normalizedGrades[i]
      end = normalizedGrades[i]
    }
  }

  // Push the last range
  if (start !== null && end !== null) {
    result.push(formatRange(start, end))
  }

  return result.join(',')
}

export const formatRange = (start: number, end: number): string => {
  const decodedStart = start <= 0 ? start - 1 : start
  const decodedEnd = end <= 0 ? end - 1 : end

  if (decodedStart === decodedEnd) {
    return gradeToAbbreviation(decodedStart)
  } else {
    return gradeToAbbreviation(decodedStart) + '-' + gradeToAbbreviation(decodedEnd)
  }
}

export const calculateGradeNumber = (
  grade: string | undefined,
  initialYear: string | undefined,
  endYear: string | undefined,
): number => {
  // offset based on grades array
  const OFFSET = 1

  // early return if missing data
  if (!grade || !initialYear || !endYear) return 0

  // getting steps
  const initialYearNumber = Number(initialYear.split(HYPHEN)?.[0])
  const endYearNumber = Number(endYear.split(HYPHEN)?.[0])
  const steps = endYearNumber - initialYearNumber

  //obtaining grade number
  const gradeNumber = gradeToNumber(grade)

  // Return the same gradeNumber if the steps are negative
  if (steps < 0) return gradeNumber

  // Calculate index based on full grades flag
  const index =
    gradeNumber === PRE_KINDERGARTEN_NUM_VALUE ? 0 : gradeNumber === KINDERGARTEN_NUM_VALUE ? 1 : gradeNumber + OFFSET

  // get new item based on the grades array
  const nextItem = GRADES[index + steps]

  // return 0 if the grade is outside the array length
  if (isNil(nextItem)) return 0

  // return the number of that grade
  return gradeToNumber(nextItem)
}

export const isInEligibleStudentStatus = (
  schoolYearGrades: string,
  studentGradeLevel?: StudentGradeLevel,
  schoolYearDateBegin?: string,
): boolean => {
  const grades = schoolYearGrades
    ?.replace(PRE_KINDERGARTEN_LABEL, PRE_KINDERGARTEN_NUM_VALUE.toString())
    ?.replace(KINDERGARTEN_LABEL, KINDERGARTEN_NUM_VALUE.toString())
    ?.split(',')

  const currentGrade = calculateGradeNumber(
    studentGradeLevel?.grade_level,
    studentGradeLevel?.school_year?.date_begin,
    schoolYearDateBegin,
  )

  if (grades) return !grades?.includes(currentGrade.toString())
  else return false
}

export const gradesToDropDownItems = (grades: string[], enableFullGrades = false): Array<DropDownItem> => {
  const dropDownItems = [] as Array<DropDownItem>
  const gradesArray = enableFullGrades ? GRADES_FULL : GRADES
  gradesArray.forEach((grade) => {
    if (grades?.includes(String(grade))) {
      if (typeof grade !== 'string') {
        dropDownItems.push({
          label: `${toOrdinalSuffix(grade)} ${MthTitle.GRADE}`,
          value: grade.toString(),
        })
      }
      if (typeof grade === 'string') {
        dropDownItems.push({
          label: grade,
          value: grade,
        })
      }
    }
  })
  return dropDownItems
}
