import { MthColor } from '@mth/enums'

export const useStyles = {
  customModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '580px',
    backgroundColor: MthColor.WHITE,
    boxShadow: 24,
    borderRadius: 2,
    px: 6.25,
    pt: 3.75,
    pb: 6.75,
    overflow: 'auto',
  },
  viewModalContainer: {
    pl: 7.5,
    pr: 2,
    pt: 2,
    pb: 7,
  },
  closeBtn: {
    background: MthColor.BUTTON_LINEAR_GRADIENT_DARK,
    borderRadius: '4px',
    color: MthColor.WHITE,
    cursor: 'pointer',
  },
  viewModeTextField: {
    '.Mui-disabled': {
      color: MthColor.BLACK,
      opacity: 'inherit',
      WebkitTextFillColor: `${MthColor.BLACK} !important`,
      fontWeight: 600,
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'black !important',
    },
  },
  multiLineView: {
    maxHeight: '360px',
    '.Mui-disabled': {
      color: MthColor.BLACK,
      opacity: 'inherit',
      WebkitTextFillColor: `${MthColor.BLACK} !important`,
      fontWeight: 400,
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'black !important',
    },
  },
}
