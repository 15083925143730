import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { useFlag } from '@unleash/proxy-client-react'
import axios from 'axios'
import { useInterval } from 'usehooks-ts'
import { EMPTY_STRING, OPERATIONAL_FORCE_REFRESH } from '@mth/constants'
import { MthTitle } from '@mth/enums'
import {
  SNOWPACK_PUBLIC_APP_VERSION,
  SNOWPACK_PUBLIC_APP_VERSION_POLLING_RATE,
  SNOWPACK_PUBLIC_WEB_URL,
} from '@mth/envs'
import { useIsMobile } from '@mth/hooks'
const axiosVersionCheckConfig = {
  url: `${SNOWPACK_PUBLIC_WEB_URL}/version.json`,
  method: 'GET',
}
import { getStyles } from './AutoUpdateBanner.styles'
export const BANNER_HEIGHT = 60
export interface AutoUpdateBannerProps {
  setVersionUpdateRequired: (val: boolean) => void
  versionUpdateRequired: boolean
}

export const AutoUpdateBanner: React.FC<AutoUpdateBannerProps> = ({
  setVersionUpdateRequired,
  versionUpdateRequired,
}) => {
  const operationalForceRefresh = useFlag(OPERATIONAL_FORCE_REFRESH)
  const isMobile = useIsMobile()

  const shouldPollForNewVersion =
    operationalForceRefresh && !versionUpdateRequired && SNOWPACK_PUBLIC_APP_VERSION !== EMPTY_STRING

  useInterval(
    async () => {
      const response = await axios(axiosVersionCheckConfig)
      if (response.status === 200) {
        const newAppVersion = response.data.version
        if (newAppVersion !== SNOWPACK_PUBLIC_APP_VERSION) {
          datadogLogs.logger.log(`New version is ready to be updated: ${newAppVersion}`, {
            currentVersion: SNOWPACK_PUBLIC_APP_VERSION,
            newVersion: newAppVersion,
          })
          setVersionUpdateRequired(true)
        }
      } else {
        datadogLogs.logger.error('Error Retrieving latest version...', { responseStatus: response.status })
      }
    },
    shouldPollForNewVersion ? SNOWPACK_PUBLIC_APP_VERSION_POLLING_RATE : null,
  )
  const styles = getStyles(isMobile)
  if (versionUpdateRequired)
    return (
      <Box sx={styles.container}>
        <Box sx={styles.textContainer}>{MthTitle.UPDATE_BANNER_TEXT}</Box>
        <Box sx={styles.buttonContainer}>
          <Button
            variant='contained'
            onClick={() => {
              datadogLogs.logger.log('Updating to the new version...')
              window.location.reload()
            }}
            sx={styles.button}
          >
            {MthTitle.UPDATE_BANNER_BTN}
          </Button>
        </Box>
      </Box>
    )

  // Return null in case not need to show the Banner
  return null
}
