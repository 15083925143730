import { gql } from '@apollo/client'

export const getAssigmentByLearningLogId = gql`
  query GetAssigmentByLearningLogId($learningLogId: Float!) {
    getAssigmentByLearningLogId(learningLogId: $learningLogId) {
      id
      master_id
      title
      due_date
      auto_grade_email
      auto_grade
      teacher_deadline
      reminder_date
      participation_tracking
      page_count
      StudentLearningLogs {
        status
        meta
        grade
        AssignmentId
        SchoolYearId
        StudentId
        created_at
        graded_at
        updated_at
        feedback
        id
        teacher_user_id
        instructional_hours
      }
      Master {
        master_id
        instructions
      }
    }
  }
`

export const getStudentLearningLogsQuery = gql`
  query LearningLogsForStudent(
    $filter: StudentLearningLogFilter
    $sort: String
    $skip: Int
    $search: String
    $take: Int
  ) {
    learningLogsForStudent(filter: $filter, sort: $sort, skip: $skip, search: $search, take: $take) {
      total
      results {
        id
        master_id
        title
        due_date
        auto_grade_email
        auto_grade
        teacher_deadline
        reminder_date
        participation_tracking
        page_count
        StudentLearningLogs {
          status
          meta
          grade
          AssignmentId
          SchoolYearId
          StudentId
          created_at
          graded_at
          updated_at
          feedback
          id
          teacher_user_id
          instructional_hours
        }
        Master {
          master_id
          instructions
        }
      }
    }
  }
`

export const getTeachersDataLearningLog = gql`
  query User($userId: ID!) {
    user(user_id: $userId) {
      avatar_url
      first_name
      last_name
    }
  }
`

export const getStudentLearningLogsQueryFeedback = gql`
  query LearningLogsForStudent(
    $filter: StudentLearningLogFilter
    $sort: String
    $skip: Int
    $search: String
    $take: Int
  ) {
    learningLogsForStudent(filter: $filter, sort: $sort, skip: $skip, search: $search, take: $take) {
      total
      results {
        title
        due_date
        StudentLearningLogs {
          id
          grade
          feedback
          graded_at
          updated_at
          teacher_user_id
          status
        }
      }
    }
  }
`

export const getSelectedChecklistOptions = gql`
  query getSelectedChecklistOptionsInput($getSelectedChecklistOptionsInput: GetSelectedChecklistOptionsInput!) {
    selectedChecklistOptions(getSelectedChecklistOptionsInput: $getSelectedChecklistOptionsInput)
  }
`

export const getStudentsStatistics = gql`
  query GetStudentsStatistics($studentsStatisticsRequest: StudentsStatisticsRequest!) {
    getStudentsStatistics(studentsStatisticsRequest: $studentsStatisticsRequest) {
      semestersEnabled
      avgGradeFirst
      avgGradeSecond
      avgGrade
      gradedZeroCount
      studentId
      resubmitRequiredLearningLogs
      excusedLearningLogs
    }
  }
`

export const getDaysToSubmitEarlyQuery = gql`
  query HomeroomSettingBySchoolYearId($schoolYearId: Float!) {
    homeroomSettingBySchoolYearId(school_year_id: $schoolYearId) {
      id
      days_to_submit_early
    }
  }
`

export const getStudentLearningLogsByStatuses = gql`
  query FindStudentLearningLogsByStatus($schoolYearId: Float!, $statuses: [String!]!, $studentId: Float!) {
    findStudentLearningLogsByStatus(schoolYearId: $schoolYearId, statuses: $statuses, studentId: $studentId) {
      id
      grade
      status
      Assignment {
        title
        master_id
        id
      }
    }
  }
`
