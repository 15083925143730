export enum StudentsGradeLevel {
  K = 'K',
  PRE_KINDERGARTEN = 'Pre-Kindergarten',
  KINDERGARTEN = 'Kindergarten',
  FIRST = '1',
  SECOND = '2',
  THIRD = '3',
  FOURTH = '4',
  FIFTH = '5',
  SIXTH = '6',
  SEVENTH = '7',
  EIGHTH = '8',
  NINTH = '9',
  TENTH = '10',
  ELEVENTH = '11',
  TWELFTH = '12',
  ALL = 'all',
  FIRST_THRU_EIGHTH = '1-8',
  NINTH_THRU_TWELFTH = '9-12',
}

export enum Kindergarten {
  K = 'K',
  KINDERGARTEN = 'Kindergarten',
}
