// @ts-ignore
import { diffWords, Change } from 'diff'
import { paragraphRegex, tagRegex, spaceRegex, spanRegex, isDupNewLines } from '@mth/constants'
import { MthColor } from '@mth/enums'

export const longestCommonSubsequence = (a: string, b: string) => {
  const aLen = a.length
  const bLen = b.length
  const dp = Array.from({ length: aLen + 1 }, () => new Array(bLen + 1).fill(0))

  for (let i = 1; i <= aLen; i++) {
    for (let j = 1; j <= bLen; j++) {
      if (a[i - 1] === b[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1
      } else {
        dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1])
      }
    }
  }

  let result = ''
  let i = aLen,
    j = bLen
  while (i > 0 && j > 0) {
    if (a[i - 1] === b[j - 1]) {
      result = a[i - 1] + result
      i--
      j--
    } else if (dp[i - 1][j] > dp[i][j - 1]) {
      i--
    } else {
      j--
    }
  }

  return result
}

export const htmlToText = (html: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

export const removeDuplicateNewLines = (str: string) => {
  return str.replace(isDupNewLines, '\n')
}

export const highlightModifiedWords = (originalText: string, modifiedText: string): string => {
  const startTag = `<span style="background-color:${MthColor.LIGHTGREEN};">`
  const endTag = '</span>'

  if (!modifiedText) {
    return ''
  }
  const originalTextwithoutSpace = originalText?.trim()
  const modifiedTextwithoutSpace = modifiedText?.trim()

  const matches1 = originalTextwithoutSpace.match(paragraphRegex) || []
  const matches2 = modifiedTextwithoutSpace.match(paragraphRegex) || []
  const result = matches2.map((match, i) => {
    const originalMatch = matches1[i] || ''
    const innerText1 = originalMatch.replace(tagRegex, '')
    const innerText2 = match.replace(tagRegex, '')
    const diff: Change[] = diffWords(innerText1, innerText2)
    let diffText = ''

    diff.forEach((part) => {
      const value = part.value.replace(spaceRegex, '')

      if (part.added) {
        if (!part.removed) {
          diffText += `${startTag}${value}${endTag}`
        }
      } else {
        if (!part.removed) {
          diffText += value
        }
      }
    })

    if (diffText !== '') {
      const modifiedMatch = match.replace(innerText2, diffText)
      return modifiedMatch
    } else {
      return match
    }
  })

  let finalResult = result.join('')
  finalResult = finalResult.replace(spanRegex, '')
  return finalResult
}
