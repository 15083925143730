import { gql } from '@apollo/client'

export const getCustomReportsByRegionAndSchoolYearIdQuery = gql`
  query GetCustomReportsByRegionAndSchoolYear($regionId: Float!, $schoolYearId: Float!) {
    getCustomReportsByRegionAndSchoolYear(regionId: $regionId, schoolYearId: $schoolYearId) {
      id
      region_id
      school_year_id
      title
    }
  }
`

export const getAllQuestions = gql`
  query Query($regionId: ID!, $schoolYearId: Int!) {
    getAllDefaultQuestions {
      id
      column_title
      slug
    }
    getApplicationQuestionsByRegionAndSchoolYear(regionId: $regionId, schoolYearId: $schoolYearId) {
      id
      question
      slug
    }
    getEnrollmentQuestionsByRegionAndSchoolYear(regionId: $regionId, schoolYearId: $schoolYearId) {
      id
      question
      slug
    }
    getAllProgramDetailColumns {
      id
      slug
      column_title
    }
  }
`
export const saveCustomReport = gql`
  mutation CreateCustomReport(
    $regionId: ID!
    $schoolYearId: ID!
    $title: String!
    $customReportColumns: [CustomReportQuestion]
  ) {
    createCustomReport(
      region_id: $regionId
      school_year_id: $schoolYearId
      title: $title
      customReportColumns: $customReportColumns
    ) {
      id
      region_id
      school_year_id
      title
    }
  }
`

export const updateCustomReport = gql`
  mutation UpdateCustomReport(
    $id: Int!
    $regionId: ID!
    $schoolYearId: ID!
    $title: String!
    $customReportColumns: [CustomReportQuestion]
  ) {
    updateCustomReport(
      id: $id
      region_id: $regionId
      school_year_id: $schoolYearId
      title: $title
      customReportColumns: $customReportColumns
    ) {
      id
      region_id
      school_year_id
      title
    }
  }
`
