import React from 'react'
import { StudentStatusLabel } from '@mth/enums'
import { Student } from '@mth/models'
import { SchoolYearProps } from '@mth/screens/Admin/UserProfile/components/Students/types'
import { gradeText } from '@mth/utils'

interface GradeComponentProps {
  item: Student
  currentYear: SchoolYearProps
  nextYear: SchoolYearProps
  isWithdrawn?: boolean
}

const GradeComponent: React.FC<GradeComponentProps> = ({ item, currentYear, nextYear, isWithdrawn }) => {
  return (
    <>
      {!isWithdrawn
        ? gradeText(item, [currentYear?.school_year_id], [nextYear?.school_year_id])
        : StudentStatusLabel.WITHDRAWN}
    </>
  )
}

export default GradeComponent
