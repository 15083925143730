export enum EnrollmentPacketFormProperty {
  LAST_CLICKED_ACTION = 'last_clicked_action',
  EXEMPTION_DATE = 'exemptionDate',
  SHOW_MISSING_INFO_MODAL = 'showMissingInfoModal',
  SHOW_AGE_ISSUE_MODAL = 'showAgeIssueModal',
  PRE_SAVE_STATUS = 'preSaveStatus',
  STATUS = 'status',
  SHOW_SAVE_WARN_MODAL = 'showSaveWarnModal',
  SAVE_ALERT = 'saveAlert',
  SAVE_ERROR = 'saveError',
  NOTES = 'notes',
  MISSING_INFO_ALERT = 'missingInfoAlert',
  AGE_ISSUE = 'age_issue',
  MISSING_FILES = 'missing_files',
  SHOW_VALIDATION_ERRORS = 'showValidationErrors',
}
