import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Grid, Modal, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { WarningModal } from '@mth/components/WarningModal/Warning'
import { RECEIVE_EMAIL_GIVING_LINK_TO_CREATE_PASSWORD } from '@mth/constants'
import { DropDown } from '@mth/core/components/shared/DropDown/DropDown'
import { DropDownItem } from '@mth/core/components/shared/DropDown/types'
import { Subtitle } from '@mth/core/components/shared/Subtitle/Subtitle'
import { MthColor, RoleLevel } from '@mth/enums'
import { checkEmailQuery } from '@mth/graphql/queries/email-template'
import { getAllRoles } from '@mth/graphql/queries/role'
import { StudentsModal } from './StudentsModal'
import { useStyles } from './styles'
import { ApolloError, NewModalProps } from './types'

export const NewUserModal: React.FC<NewModalProps> = ({
  handleModem,
  visible = false,
  students = [],
  parent_id,
  ParentEmailValue,
  regionId,
  schoolYearId,
  currentRegion,
}) => {
  const classes = useStyles
  const [apolloError, setApolloError] = useState<ApolloError>({
    title: '',
    severity: '',
    flag: false,
  })
  const [email, setEmail] = useState('')

  const [parentEmail] = useState(ParentEmailValue)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [showStudentModal, setShowStudentModal] = useState(false)
  const [payloadData, setPayloadData] = useState()
  const { loading: loadingRoles, data: rolesData } = useQuery(getAllRoles)
  const [rolesOption, setRolesOption] = useState<DropDownItem[]>([])
  const [showEmailError, setShowEmailError] = useState<boolean>(false)

  const regionOption: DropDownItem[] = [
    {
      label: currentRegion?.regionDetail?.name,
      value: currentRegion?.id || 0,
    },
  ]

  const handleSubmit = () => {
    if (!firstName) {
      setApolloError({
        title: 'First name is required',
        severity: 'Warning',
        flag: true,
      })
      return
    } else if (!email) {
      setApolloError({
        title: 'Email address is required',
        severity: 'Warning',
        flag: true,
      })
      return
    } else if (!parentEmail) {
      setApolloError({
        title: 'Parent Email address is required.',
        severity: 'Warning',
        flag: true,
      })
      return
    }
    setPayloadData({
      // @ts-ignore
      email: email,
      first_name: firstName,
      last_name: lastName,
      parent_id: parent_id,
      region: currentRegion?.region_id || 0,
      regions: [currentRegion?.region_id || 0],
    })
    setShowStudentModal(true)
  }

  // @ts-ignore
  const handleCloseStudentModal = (status) => {
    setShowStudentModal(false)
    if (status) handleModem()
  }

  useEffect(() => {
    if (!loadingRoles && rolesData !== undefined) {
      // @ts-ignore
      const updatedRoles = rolesData?.roles?.find((role) => role.level === RoleLevel.OBSERVER)
      setRolesOption([
        {
          label: updatedRoles?.name,
          value: updatedRoles?.id,
        },
      ])
    }
  }, [loadingRoles])

  const [checkEmail, { loading: emailLoading, data: emailData }] = useLazyQuery(checkEmailQuery, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!emailLoading && emailData !== undefined) {
      if (emailData.emailTaken === true) {
        setShowEmailError(true)
      } else {
        setShowEmailError(false)
      }
    }
  }, [emailLoading, emailData])

  return (
    <Modal
      open={visible}
      onClose={() => handleModem()}
      aria-labelledby='Create User'
      aria-describedby='Create New User'
    >
      <Box sx={classes.modalCard}>
        {apolloError.flag && (
          <WarningModal
            handleModem={() => setApolloError({ title: '', severity: '', flag: false })}
            title={apolloError.severity}
            subtitle={apolloError.title}
            btntitle='Close'
            handleSubmit={() => setApolloError({ title: '', severity: '', flag: false })}
          />
        )}
        {showStudentModal && (
          // @ts-ignore
          <StudentsModal
            visible={true}
            handleModem={handleCloseStudentModal}
            students={students}
            data={payloadData}
            regionId={regionId}
            schoolYearId={schoolYearId}
          />
        )}
        <Box sx={classes.header}>
          <Subtitle>{RECEIVE_EMAIL_GIVING_LINK_TO_CREATE_PASSWORD}</Subtitle>
          {/* @ts-ignore */}
          <CloseIcon style={classes.close} onClick={handleModem} />
        </Box>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Subtitle fontWeight='700' size='large'>
              Email
            </Subtitle>
            <TextField
              size='small'
              variant='outlined'
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={() => {
                checkEmail({
                  variables: {
                    email: email,
                  },
                })
              }}
            />
            {showEmailError && (
              // @ts-ignore
              <Subtitle size='medium' color={MthColor.RED}>
                This email is already being used.
              </Subtitle>
            )}
          </Grid>
          <Grid item xs={12}>
            <Subtitle fontWeight='700' size='large'>
              First Name
            </Subtitle>
            <TextField
              size='small'
              variant='outlined'
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Subtitle fontWeight='700' size='large'>
              Last Name
            </Subtitle>
            <TextField
              size='small'
              variant='outlined'
              fullWidth
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid container item xs={9}>
            <Grid item xs={12}>
              <DropDown
                disabled
                dropDownItems={rolesOption}
                placeholder='User Type'
                size='small'
                sx={{ width: '50%' }}
                defaultValue={rolesOption[0]?.value}
                setParentValue={() => {}}
              />
            </Grid>
          </Grid>
          <Grid container item xs={9}>
            <Grid item xs={12}>
              <DropDown
                disabled
                dropDownItems={regionOption}
                setParentValue={() => {}}
                defaultValue={currentRegion?.id || 0}
                size='small'
                sx={{ width: '50%' }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Subtitle fontWeight='700' size='large'>
              Parent Account Email
            </Subtitle>
            <TextField
              size='small'
              variant='outlined'
              fullWidth
              value={parentEmail}
              // onChange={(e) => setParentEmail(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'end',
                height: '100%',
                width: '100%',
              }}
            >
              <Button
                onClick={handleSubmit}
                sx={{
                  background: MthColor.BUTTON_LINEAR_GRADIENT,
                  color: 'white',
                  width: '92px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontWeight: 700,
                  height: '24px',
                }}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
