import { gql } from '@apollo/client'

export const getParticipationTrackingListQuery = gql`
  query getParticipationTrackingList($assignmentId: Float!, $studentId: Float!) {
    getParticipationTrackingList(assignmentId: $assignmentId, studentId: $studentId) {
      student_id
      assignment_id
      date
      status
      comment
      excuse
    }
  }
`

export const getStudentsParticipationById = gql`
  query getStudentsParticipationById($studentIds: [Int!]!, $participationDate: String!) {
    getStudentsParticipationById(studentIds: $studentIds, participationDate: $participationDate) {
      studentId
      assignmentId
      assignmentDueDate
      participationStatus
      comment
      firstName
      lastName
    }
  }
`
